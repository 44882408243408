import { Form, Input, Select, Switch } from 'antd'
import { renderFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'
import { renderChargerSettingText } from 'helpers/tenant-tab-render-text'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'
import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { chargerSettings } from 'models/chargerv2'
import { connectorServiceStatus } from 'models/connector'
import { Tenant } from 'models/tenant'
import { theme } from 'theme'

interface TenantServiceStatusInfoProps {
  tenant: Tenant
  disabled: boolean
}

const TenantServiceStatusInfo: React.FC<TenantServiceStatusInfoProps> = ({ tenant, disabled }) => {
  const {
    uniformServiceStatusWarningText,
    connectorServiceStatusText,
    uniformServiceStatusText,
    omOptInText,
    swtchCareText,
    caasText,
  } = useTenantTranslation()
  const { omOptinNoText, noteText } = useGeneralTranslation()

  return (
    <>
      <Form.Item name="omOptin" label={omOptInText} rules={[{ required: false }]} className="align-center">
        <Select disabled={disabled}>
          <Select.Option value="swtch_care">{swtchCareText}</Select.Option>
          <Select.Option value="caas">{caasText}</Select.Option>
          <Select.Option value="no">{omOptinNoText}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="chargerSetting"
        label={'Charger Settings'}
        rules={[{ required: false }]}
        className="align-center"
      >
        <Select disabled={disabled} placeholder="Charger Settings">
          {chargerSettings.map((setting) => (
            <Select.Option value={setting} label={renderChargerSettingText(setting)}>
              {renderChargerSettingText(setting)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="notes" label={noteText}>
        <Input.TextArea disabled={disabled} showCount maxLength={255} style={{ height: theme.space[6] }} />
      </Form.Item>
      <Form.Item name="allowUniformServiceStatus" label={uniformServiceStatusText}>
        <Switch defaultChecked={tenant.allowUniformServiceStatus} disabled={disabled} />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.allowUniformServiceStatus !== currentValues.allowUniformServiceStatus
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('allowUniformServiceStatus') === true ? (
            <Form.Item
              name="uniformServiceStatus"
              label={connectorServiceStatusText}
              rules={[{ required: false }]}
              validateStatus="warning"
              help={uniformServiceStatusWarningText}
              className="align-center-label"
            >
              <Select disabled={disabled} allowClear>
                {connectorServiceStatus.map((status) => (
                  <Select.Option key={status} value={status}>
                    {renderFormatMessage(renderConnectionMessage(status), status)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null
        }
      </Form.Item>
    </>
  )
}

export default TenantServiceStatusInfo
