import { ListingStatusUpdate } from '../../../models/listing'
import { useListingBasicTrans } from '../../../hooks/translation/useListingBasicTrans'
import { useEffect, useRef, useState } from 'react'
import { Heading } from '../../../atom/user-edit'
import { Button, Form, Input, Select, Modal } from 'antd'
import { useSelectorTranslation } from '../../../hooks/translation/useSelectorTranslation'
import { useAppState } from '../../../state'
import { MustBeRequired } from '../../../components/rules/rules'
import { layout } from '../../../atom/form/modal-layout'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { MultiOptionSelectorWrapper } from '../../../atom/select'
import { ListingServiceStatus, listingServiceStatus } from '../../../models/connector'
import { renderFormatMessage } from '../../../helpers/intl'
import { DropdownServiceStatusOptions } from '../../../models/connector'
import { useServiceStatusReasonsTranslation } from '../../../hooks/translation/useServiceStatusReasonsTranslation'
import { DropdownOption } from '../../../models/option'
import { DecommisionedModal } from './DecommisionedModal'
interface ListingStatusModalProps {
  onCancel: () => void
  onServiceStatus: (newServiceStatus: ListingServiceStatus, reason: string[] | undefined) => void
  serviceStatus: string
  reason?: string[]
}

const ListingStatusModal: React.FC<ListingStatusModalProps> = ({
  onCancel,
  onServiceStatus,
  serviceStatus,
  reason,
}) => {
  const { updateServiceStatusText } = useListingBasicTrans()
  const modalTitle = updateServiceStatusText

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [showOtherReasonField, setShowOtherReasonField] = useState(false)
  const selectRef = useRef<any>(null)
  const [openDecommisionedModal, setOpenDecommisionedModal] = useState(false)

  const [secondDropdownOptions, setSecondDropdownOptions] = useState<DropdownOption[]>([])
  const [dependentSelectorValue, setDependentSelectorValue] = useState(reason)

  const closeDecommisionedModal = () => setOpenDecommisionedModal(false)

  const onOk = (reason: string[]) => {
    setLoading(true)
    form.validateFields().then((values) => {
      if (values.new_status) {
        onServiceStatus(values.new_status, reason)
      }
    })
    setLoading(false)
    onCancel()
  }

  const { language } = useAppState()
  const { selectText, confirmText } = useGeneralTranslation()
  const { statusIsRequired } = useListingBasicTrans()
  const {
    startTypingText,
    serviceStatusText,
    selectAReasonText,
    selectReasonForStatusChangeText,
    specificANewReasonText,
    typeAReasonText,
  } = useSelectorTranslation()

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }

  const {
    breakerResetText,
    cableReplacementText,
    initialActivationText,
    chargerIsOfflineText,
    chargerIsDamagedText,
    chargerIsFaultedText,
    chargerIsVandalisedText,
    hardwarePartsReplacementDCFCText,
    lackOfFeatureText,
    networkResetText,
    networkHardwareReplacementText,
    otherPleaseSpecifyText,
    poorCustomerServiceText,
    poorHardwareQualityText,
    productBugText,
    priceIsNotCompetitiveText,
    stationIsMalfunctioningText,
    unitReplacementText,
  } = useServiceStatusReasonsTranslation()

  // Options for the second dropdown based on the selection of the first dropdown
  const optionsForSecondDropdown: DropdownServiceStatusOptions = {
    under_repair: [
      { key: 'initial_activation', label: initialActivationText },
      { key: 'charger_is_offline', label: chargerIsOfflineText },
      { key: 'charger_is_faulted', label: chargerIsFaultedText },
      { key: 'charger_is_damaged', label: chargerIsDamagedText },
      { key: 'charger_is_vandalised', label: chargerIsVandalisedText },
      { key: 'station_is_malfunctioning', label: stationIsMalfunctioningText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
    decommissioned: [
      { key: 'initial_activation', label: initialActivationText },
      { key: 'price_is_not_competitive', label: priceIsNotCompetitiveText },
      { key: 'lack_of_feature', label: lackOfFeatureText },
      { key: 'product_bug', label: productBugText },
      { key: 'poor_customer_service', label: poorCustomerServiceText },
      { key: 'poor_hardware_quality', label: poorHardwareQualityText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
    active: [
      { key: 'initial_activation', label: initialActivationText },
      { key: 'unit_replacement', label: unitReplacementText },
      { key: 'cable_replacement', label: cableReplacementText },
      { key: 'hardware_parts_replacement', label: hardwarePartsReplacementDCFCText },
      { key: 'breaker_reset', label: breakerResetText },
      { key: 'network_reset', label: networkResetText },
      { key: 'network_hardware_replacement', label: networkHardwareReplacementText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
  }

  useEffect(() => {
    optionsSecondDropdown(serviceStatus)
  }, [])

  const handleFocus = () => selectRef.current.focus()

  const optionsSecondDropdown = (event: string) => {
    // Set options for the second dropdown based on the selection of the first dropdown
    switch (event) {
      case 'under_repair':
        setSecondDropdownOptions(optionsForSecondDropdown['under_repair'])
        handleFocus()
        break
      case 'decommissioned':
        setSecondDropdownOptions(optionsForSecondDropdown['decommissioned'])
        handleFocus()
        break
      case 'active':
        setSecondDropdownOptions(optionsForSecondDropdown['active'])
        handleFocus()
        break
      default:
        setSecondDropdownOptions([])
        break
    }
  }

  const onServiceStatusChange = (event: string) => {
    optionsSecondDropdown(event)
    setDependentSelectorValue(undefined)
    form.setFieldsValue({ reason: undefined })
    form.setFields([
      {
        name: 'reason',
        value: null,
      },
    ])
  }

  const handleSelect = (value: string) => {
    if (value === 'other') {
      // Close the dropdown when 'other' is selected
      if (selectRef) {
        selectRef.current.blur()
      }
    }
  }

  const onReasonChange = (event: any) => {
    // Set options for the second dropdown based on the selection of the first dropdown
    setDependentSelectorValue(event)

    if (event.includes('other')) {
      setShowOtherReasonField(true)
    } else {
      setShowOtherReasonField(false)
    }
  }

  const onFinish = (values: ListingStatusUpdate) => {
    let otherReason: any = []
    if (dependentSelectorValue) {
      otherReason = [...dependentSelectorValue]
    }
    if (values['other_reason']) {
      otherReason = [...otherReason, values['other_reason']]
    }
    if (values['new_status']) {
      onOk(otherReason)
    } else {
      onCancel()
    }
  }

  const showAlertIfDecommissioned = (values: ListingStatusUpdate) => {
    if (values['new_status'] === 'decommissioned') {
      setOpenDecommisionedModal(true)
    } else {
      onFinish(values)
    }
  }

  const decommisionedModalOnFinish = () => {
    closeDecommisionedModal()
    onFinish(form.getFieldsValue())
  }

  const initialValues = {
    new_status: serviceStatus,
  }

  return (
    <Modal
      title={<Heading>{modalTitle}</Heading>}
      visible={true}
      onCancel={onCancel}
      footer={null}
      width={540}
      bodyStyle={{ padding: '10px 24px 24px 24px' }}
    >
      <DecommisionedModal
        onOk={decommisionedModalOnFinish}
        visible={openDecommisionedModal}
        onCancel={closeDecommisionedModal}
      />
      <Form
        size="large"
        {...chooseLayout()}
        onFinish={showAlertIfDecommissioned}
        form={form}
        initialValues={initialValues}
      >
        <Form.Item name="new_status" label={serviceStatusText} rules={[MustBeRequired(statusIsRequired)]}>
          <Select
            placeholder={`${selectText} ${serviceStatusText}`}
            onChange={onServiceStatusChange}
            disabled={loading}
            loading={loading}
            defaultValue={serviceStatus}
            value={serviceStatus}
          >
            {listingServiceStatus.map((status, index) => (
              <Select.Option key={`${status}-${index}`} value={status} label={status}>
                {renderFormatMessage(`dashboard.serviceStatus.${status}`, status)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="reason" label={selectAReasonText}>
          <MultiOptionSelectorWrapper removeSVG={true} longerWidth={true}>
            <Select
              ref={selectRef}
              onSelect={handleSelect}
              allowClear={true}
              placeholder={selectReasonForStatusChangeText}
              mode="multiple"
              onChange={onReasonChange}
              maxTagCount={2}
              value={dependentSelectorValue}
              notFoundContent={startTypingText}
              disabled={loading}
              loading={loading}
            >
              {secondDropdownOptions.map((option) => (
                <Select.Option value={option.key}>{option.label}</Select.Option>
              ))}
            </Select>
          </MultiOptionSelectorWrapper>
        </Form.Item>

        {showOtherReasonField && (
          <Form.Item name="other_reason" rules={[{ required: true }]} label={typeAReasonText}>
            <Input placeholder={specificANewReasonText} type="text" />
          </Form.Item>
        )}
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            {confirmText}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ListingStatusModal
