import { theme } from '../../theme'

export const clipboardSVG = (
  <svg className="svg-clipboard" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none">
      <path
        className="clipboardSVG-no-style"
        d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"
      />
      <path
        className="clipboardSVG"
        fill={theme.colors.white}
        d="M7 3v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V3h1a2 2 0 0 1 2 2v11a6 6 0 0 1-6 6H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1Zm5 11H9a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2Zm3-4H9a1 1 0 0 0-.117 1.993L9 12h6a1 1 0 1 0 0-2Zm-1-8a1 1 0 0 1 .117 1.993L14 4h-4a1 1 0 0 1-.117-1.993L10 2h4Z"
      />
    </g>
  </svg>
)

export const clipboardLargeSVG = (
  <svg width="167" height="167" viewBox="0 0 167 167" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8358_85413)">
      <path
        d="M48.7083 20.875V27.8334C48.7083 31.5243 50.1746 35.064 52.7844 37.6739C55.3943 40.2838 58.9341 41.75 62.625 41.75H104.375C108.066 41.75 111.606 40.2838 114.216 37.6739C116.825 35.064 118.292 31.5243 118.292 27.8334V20.875H125.25C128.941 20.875 132.481 22.3412 135.091 24.9511C137.7 27.561 139.167 31.1008 139.167 34.7917V111.333C139.167 122.406 134.768 133.025 126.938 140.855C119.109 148.685 108.489 153.083 97.4167 153.083H41.75C38.0591 153.083 34.5193 151.617 31.9094 149.007C29.2996 146.397 27.8333 142.858 27.8333 139.167V34.7917C27.8333 31.1008 29.2996 27.561 31.9094 24.9511C34.5193 22.3412 38.0591 20.875 41.75 20.875H48.7083ZM83.5 97.4167H62.625C60.7795 97.4167 59.0097 98.1498 57.7047 99.4547C56.3998 100.76 55.6667 102.53 55.6667 104.375C55.6667 106.22 56.3998 107.99 57.7047 109.295C59.0097 110.6 60.7795 111.333 62.625 111.333H83.5C85.3455 111.333 87.1153 110.6 88.4203 109.295C89.7252 107.99 90.4583 106.22 90.4583 104.375C90.4583 102.53 89.7252 100.76 88.4203 99.4547C87.1153 98.1498 85.3455 97.4167 83.5 97.4167ZM104.375 69.5834H62.625C60.8515 69.5853 59.1456 70.2644 57.856 71.4819C56.5664 72.6994 55.7903 74.3634 55.6863 76.1339C55.5824 77.9044 56.1584 79.6477 57.2968 81.0078C58.4351 82.3678 60.0498 83.2418 61.8109 83.4513L62.625 83.5H104.375C106.22 83.5 107.99 82.7669 109.295 81.462C110.6 80.157 111.333 78.3872 111.333 76.5417C111.333 74.6962 110.6 72.9263 109.295 71.6214C107.99 70.3165 106.22 69.5834 104.375 69.5834ZM97.4167 13.9167C99.1902 13.9187 100.896 14.5978 102.186 15.8153C103.475 17.0328 104.251 18.6967 104.355 20.4672C104.459 22.2377 103.883 23.9811 102.745 25.3411C101.607 26.7011 99.9919 27.5752 98.2308 27.7846L97.4167 27.8334H69.5833C67.8098 27.8314 66.1039 27.1523 64.8143 25.9348C63.5247 24.7173 62.7486 23.0533 62.6447 21.2828C62.5407 19.5123 63.1168 17.769 64.2551 16.4089C65.3934 15.0489 67.0081 14.1749 68.7692 13.9654L69.5833 13.9167H97.4167Z"
        fill={theme.colors.primary}
      />
    </g>
    <defs>
      <clipPath id="clip0_8358_85413">
        <rect width="167" height="167" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const infoSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5556 0L17 4.5V17.1072C16.9998 17.3441 16.9067 17.5712 16.7412 17.7386C16.5757 17.906 16.3513 18 16.1173 18H1.88267C1.64923 17.9984 1.42579 17.9038 1.26064 17.7367C1.09548 17.5697 1.00186 17.3436 1 17.1072V0.8928C1 0.3996 1.39556 0 1.88267 0H12.5556ZM8.11111 4.5V6.3H9.88889V4.5H8.11111ZM8.11111 8.1V13.5H9.88889V8.1H8.11111Z"
      fill={theme.colors.white}
    />
  </svg>
)
