import { useReducer, useCallback, useEffect } from 'react'

import { GetChargersPerTenant } from 'services/data-provider/charger'

import { NewListing } from 'models/listing'

import { FilterKey, FilterValueMap } from 'types/ChargersPerTenantType'
import { chargersReducer, initialState } from 'reducers/useChargersReducer'

export const useChargers = () => {
  const [state, dispatch] = useReducer(chargersReducer, initialState)

  useEffect(() => {
    fetchChargersPerTenant()
  }, [])

  const fetchChargersPerTenant = useCallback(
    (page = 1) => {
      dispatch({ type: 'SET_LOADING', payload: true })
      dispatch({ type: 'SET_ERROR', payload: undefined })
      GetChargersPerTenant({ ...state.filters, page })
        .then((response) => {
          dispatch({ type: 'SET_CHARGERS', payload: response.data })
          dispatch({ type: 'SET_PAGINATION', payload: response.pagination })
          dispatch({ type: 'SET_VENDORS', payload: response.filters.vendors })
          dispatch({ type: 'SET_FILTERS', payload: { ...state.filters, page } })
        })
        .catch((error) => dispatch({ type: 'SET_ERROR', payload: error }))
        .finally(() => dispatch({ type: 'SET_LOADING', payload: false }))
    },
    [state.filters],
  )

  const selectAllChargers = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    dispatch({ type: 'SET_ERROR', payload: undefined })
    GetChargersPerTenant({ ...state.filters, allListings: true })
      .then((response) => {
        dispatch({
          type: 'SET_SELECTED_ROW_KEYS',
          payload: response.data.flatMap((charger) => charger.listings.map((listing) => listing.id)),
        })
        dispatch({ type: 'SET_SELECTED_LISTINGS', payload: response.data.flatMap((charger) => charger.listings) })
      })
      .catch((err) => dispatch({ type: 'SET_ERROR', payload: err }))
      .finally(() => dispatch({ type: 'SET_LOADING', payload: false }))
  }

  const fetchChargersPerTenantWithQuery = useCallback(() => {
    dispatch({ type: 'SET_SHOW_SEARCH_QUERY', payload: true })
    fetchChargersPerTenant()
    dispatch({ type: 'SET_SELECTED_ROW_KEYS', payload: [] })
    dispatch({ type: 'SET_SELECTED_LISTINGS', payload: [] })
  }, [fetchChargersPerTenant])

  const handlePaginationChange = useCallback(
    (page: number) => {
      fetchChargersPerTenant(page)
    },
    [fetchChargersPerTenant],
  )

  const handleTenantSelect = useCallback(
    (tenantId: string, listings: NewListing[], checked: boolean) => {
      const tenantData = state.chargersPerTenant.find((charger) => charger.tenant.id === tenantId)
      const listingIds = listings.map((listing) => listing.id)
      const listingRows = listings.map((listing) => ({ ...listing, tenant: tenantData?.tenant }))

      updateSelection(listingIds, listingRows, checked)
    },
    [state.chargersPerTenant, state.selectedRowKeys, state.selectedListings],
  )

  const updateSelection = (listingIds: number[], listingRows: NewListing[], add: boolean) => {
    const currentSelectedRowKeys = new Set(state.selectedRowKeys)
    const currentSelectedListings = new Map(state.selectedListings.map((listing) => [listing.id, listing]))

    if (add) {
      listingIds.forEach((id) => currentSelectedRowKeys.add(id))
      listingRows.forEach((row) => currentSelectedListings.set(row.id, row))
    } else {
      listingIds.forEach((id) => currentSelectedRowKeys.delete(id))
      listingIds.forEach((id) => currentSelectedListings.delete(id))
    }

    dispatch({
      type: 'SET_SELECTED_ROW_KEYS',
      payload: Array.from(currentSelectedRowKeys),
    })

    dispatch({
      type: 'SET_SELECTED_LISTINGS',
      payload: Array.from(currentSelectedListings.values()),
    })
  }

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        const allListingIds = state.chargersPerTenant.flatMap((charger) =>
          charger.listings.map((listing) => listing.id),
        )
        const allListingRows = state.chargersPerTenant.flatMap((charger) =>
          charger.listings.map((listing) => ({ ...listing, tenant: charger.tenant })),
        )
        updateSelection(allListingIds, allListingRows, true)
      } else {
        dispatch({ type: 'SET_SELECTED_ROW_KEYS', payload: [] })
        dispatch({ type: 'SET_SELECTED_LISTINGS', payload: [] })
      }
    },
    [state.chargersPerTenant],
  )

  const handleSelect = useCallback(
    (record: NewListing, selected: boolean) => {
      const tenantData = state.chargersPerTenant.find((charger) =>
        charger.listings.some((listing) => listing.id === record.id),
      )
      const listingWithTenant = { ...record, tenant: tenantData?.tenant }

      updateSelection([record.id], [listingWithTenant], selected)
    },
    [state.chargersPerTenant, state.selectedRowKeys, state.selectedListings],
  )

  const handleFilterChange = useCallback(
    <K extends FilterKey>(key: K, value: FilterValueMap[K] | undefined) => {
      if (value === undefined) return

      // Check if `value` is an array
      if (Array.isArray(value)) {
        // Use type assertion to inform TypeScript about the type of `obj`
        const typedValue = value as { id: number }[] // Replace `{ id: number }` with the correct type if different

        const uniqueValues = typedValue.filter((obj, index) => index === typedValue.findIndex((o) => obj.id === o.id))

        dispatch({
          type: 'SET_FILTERS',
          payload: {
            ...state.filters,
            [key]: uniqueValues,
          },
        })
      } else {
        // For non-array types, set them directly
        dispatch({
          type: 'SET_FILTERS',
          payload: {
            ...state.filters,
            [key]: value,
          },
        })
      }
    },
    [state.filters, dispatch],
  )

  const handleClearFilter = useCallback((filterKey: FilterKey) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: { ...state.filters, [filterKey]: [] },
    })
  }, [])

  const selectedChargersConnectors = state.selectedListings
    .filter((data) => data.charger?.id !== undefined && data.connector?.connectorId !== undefined)
    .map((data) => ({
      charger_id: data.charger!.id,
      connector_id: data.connector!.connectorId,
    }))

  const selectedExistingChargers = state.selectedListings
    .filter((data) => data.charger !== undefined)
    .map((data) => data.charger!)

  const handleListingsChargersCancelButton = () => {
    dispatch({ type: 'SET_SHOW_CHARGER_MODAL', payload: false })
    dispatch({ type: 'SET_SHOW_LISTING_MODAL', payload: false })
    dispatch({ type: 'SET_SELECTED_ROW_KEYS', payload: [] })
    dispatch({ type: 'SET_SELECTED_LISTINGS', payload: [] })
  }

  const handleShowModal = useCallback((modalType: 'listing' | 'charger', value: boolean) => {
    dispatch({
      type: modalType === 'listing' ? 'SET_SHOW_LISTING_MODAL' : 'SET_SHOW_CHARGER_MODAL',
      payload: value,
    })
  }, [])

  return {
    ...state,
    handleShowModal,
    selectAllChargers,
    selectedChargersConnectors,
    selectedExistingChargers,
    fetchChargersPerTenant,
    fetchChargersPerTenantWithQuery,
    handlePaginationChange,
    handleTenantSelect,
    handleSelectAll,
    handleSelect,
    handleListingsChargersCancelButton,
    handleFilterChange,
    handleClearFilter,
  }
}
