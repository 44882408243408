import { Dropdown, Space, Table, TableColumnsType } from 'antd'
import { archiveSVG } from 'assets/svg/archive'
import { editGreySVG } from 'assets/svg/edit'
import { subscribeUserSVG } from 'assets/svg/subscribeUser'
import { MoreDropdownWrapper } from 'atom/plans'
import { DiscountSchemaMoreButtonWrapper, DiscountSchemaTableWrapper } from 'atom/price'
import React, { useEffect, useState } from 'react'
import { theme } from 'theme'
import { useDiscountSchemaTranslation } from 'hooks/translation/useDiscountSchemaTranslation'
import { ListingInfo, PricingSchema } from 'models/price'
import { ManageUsersModal } from '../modal/discount-schema/manage-users-modal'
import { ArchiveDiscountSchemaModal } from '../modal/discount-schema/archive-discount-schema-modal'
import { DiscountSchema } from 'models/discount'
import ServiceStatusBadge from 'components/connectors/ServiceStatusBadge'

interface ExpandedDataType {
  key: React.Key
  date: string
  name: string
  upgradeNum: string
}

interface props {
  discounts: DiscountSchema[]
  expanded: boolean
  index: number
  pricingSchema: PricingSchema
  setSelectedPricingSchema: (selectedPricingSchema: PricingSchema) => void
  setSelectedDiscountSchema: (selectedDiscount: DiscountSchema) => void
  archiveDiscountSchema: (discountId: number) => void
  setDiscountId: (discountId: number) => void
  loadDiscounts: () => void
}

export const ExpandedRowRender: React.FC<props> = ({
  discounts,
  expanded,
  index: tableIndex,
  pricingSchema,
  loadDiscounts,
  setSelectedPricingSchema,
  setSelectedDiscountSchema,
  archiveDiscountSchema,
  setDiscountId,
}) => {
  const [titleRowHeight, setTitleRowHeight] = useState<number>(0)
  const [dataRowHeight, setDataRowHeight] = useState<number>(0)
  const [openArchiveDiscountSchemaModal, setOpenArchiveDiscountSchemaModal] = useState(false)
  const [discount, setDiscount] = useState<any>()
  const { archiveText } = useDiscountSchemaTranslation()

  const [selectedDiscountPlan, setSelectedDiscountPlan] = useState<DiscountSchema | undefined>()

  const [showManageUsersModal, setShowManageUsersModal] = useState(false)

  const openManageUsersModal = (discount: DiscountSchema) => () => {
    setSelectedDiscountPlan(discount)
    setShowManageUsersModal(true)
  }
  const handleEditDiscount = (discount: DiscountSchema) => {
    setDiscountId(discount.id)
    setSelectedPricingSchema(pricingSchema)
    setSelectedDiscountSchema(discount)
  }

  const moreDropDown = (discount: DiscountSchema) => (
    <MoreDropdownWrapper>
      <div
        className="last-item"
        onClick={() => {
          setDiscount(discount)
          setOpenArchiveDiscountSchemaModal(true)
        }}
      >
        {archiveSVG} {archiveText}
      </div>
    </MoreDropdownWrapper>
  )

  const columns: TableColumnsType<ExpandedDataType> = [
    { title: 'Code', dataIndex: 'promoCode', key: 'promoCode' },
    { title: 'Discount Plan Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Number of Users',
      dataIndex: 'users',
      key: 'users',
      render: (users) => users.length,
    },
    { title: 'Created By', dataIndex: 'creatorName', key: 'creatorName' },
    {
      title: 'Status',
      dataIndex: 'usageStatus',
      key: 'usageStatus',
      render: (status) => <ServiceStatusBadge state={status} />,
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: '',
      render: (discount) => (
        <Space size="middle">
          <div onClick={() => handleEditDiscount(discount)} style={{ cursor: 'pointer' }}>
            {editGreySVG}
          </div>
          <div onClick={openManageUsersModal(discount)} className="pointer">
            {subscribeUserSVG}
          </div>
          <DiscountSchemaMoreButtonWrapper>
            <Dropdown.Button className="opened" trigger={['click']} overlay={moreDropDown(discount)}></Dropdown.Button>
          </DiscountSchemaMoreButtonWrapper>
        </Space>
      ),
    },
  ]

  // Function to get the row heights
  const getRowHeights = () => {
    // Get the container where the table is rendered
    const container = document.querySelector(`.expanded-table-${tableIndex} .ant-table`)

    // If the container exists
    if (container) {
      // Get the height of the title row
      const titleRow = container.querySelector(`.ant-table-thead`)
      if (titleRow) {
        const titleRowRect = titleRow.getBoundingClientRect()
        setTitleRowHeight(titleRowRect.height) // Set the height of the title row
      }

      // Get the height of the first data row
      const firstDataRow = container.querySelector('.ant-table-tbody .ant-table-expanded-row .ant-table-row')
      if (firstDataRow) {
        const firstDataRowRect = firstDataRow.getBoundingClientRect()
        setDataRowHeight(firstDataRowRect.height) // Set the height of the data rows
      }
    }
  }

  // Call getRowHeights after the component is mounted
  useEffect(() => {
    if (expanded) {
      getRowHeights()
    }
  }, [expanded]) // Empty dependency array means it will only run once after mounting

  const handleDiscountsData = () => {
    const discountArr: any[] = []

    discounts?.forEach((discount: DiscountSchema) => {
      discount.appliedListings?.forEach((listing: ListingInfo) => {
        pricingSchema?.appliedListings?.forEach((pricingSchemaListing) => {
          if (listing.listingId === pricingSchemaListing.listingId) {
            discountArr.push(discount)
          }
        })
      })
    })

    // Remove duplicates from discountArr
    const uniqueDiscountArr = discountArr.filter(
      (discount, index, self) =>
        index ===
        self.findIndex(
          (d) => d.id === discount.id, // Assuming discount has a unique 'id' field
        ),
    )

    return uniqueDiscountArr
  }

  useEffect(() => {
    //To update table when the user add/remove users
    if (selectedDiscountPlan) {
      const updatedSelectedDiscountPlan = discounts.find((discount) => discount.id === selectedDiscountPlan.id)
      if (updatedSelectedDiscountPlan) {
        setSelectedDiscountPlan(updatedSelectedDiscountPlan)
      }
    }
  }, [discounts])

  return (
    <>
      <ArchiveDiscountSchemaModal
        visible={openArchiveDiscountSchemaModal}
        discount={discount}
        onOk={archiveDiscountSchema}
        onCancel={() => setOpenArchiveDiscountSchemaModal(false)}
      />
      {selectedDiscountPlan && (
        <ManageUsersModal
          pricingSchema={pricingSchema}
          visible={showManageUsersModal}
          discountPlan={selectedDiscountPlan}
          onCancel={() => {
            setShowManageUsersModal(false)
            setSelectedDiscountPlan(undefined)
          }}
          loadDiscounts={loadDiscounts}
        />
      )}
      <DiscountSchemaTableWrapper
        className="discount-schema-table-style"
        dataRowHeight={dataRowHeight}
        titleRowHeight={titleRowHeight}
        howManyDataRows={discounts?.length}
      >
        {/* Render a horizontal line for the title row */}
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: titleRowHeight / 2,
            width: '100%',
            borderTop: `1px solid ${theme.colors.lightGray}`,
            marginLeft: '24px',
          }}
        />

        {/* Render a horizontal line for each data row */}
        {!!handleDiscountsData().length &&
          handleDiscountsData()?.map((_: any, index: any) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                left: 0,
                top: titleRowHeight + (index + 0.5) * dataRowHeight,
                width: '100%',
                borderTop: `1px solid ${theme.colors.lightGray}`,
                marginLeft: '24px',
              }}
            />
          ))}

        <Table
          columns={columns}
          dataSource={handleDiscountsData()}
          pagination={false}
          className={`expanded-table-${tableIndex}`}
          rowClassName={(record, index) => `row-${index}`}
          rowKey={(record) => record.key} // Assigning index as key
        />
      </DiscountSchemaTableWrapper>
    </>
  )
}
