import { PageHeader, Table } from 'antd'
import { useEffect, useState } from 'react'
import { AlertError } from '../../components/error'
import { Charger, ChargerRef } from '../../models/charger'
import { RemoteMultiChargerTriggerMessageParams } from '../../models/charger-remote'
import { Connector, ConnectorServiceStatus, connectorServiceStatus, ConnectorStatus } from '../../models/connector'
import { SwtchError } from '../../models/error'
import { ListingRef } from '../../models/listing'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import { canSelectChargers } from '../../models/user'
import { GetConnectorsRedesign } from '../../services/data-provider/connector'
import { useAppState } from '../../state'
import { chargersColumns } from './table/chargers-columns'
import { BackgroundContainer } from '../../atom/layout/backgroundContainer'
import { TableStyling } from '../../atom/users-page-redesign-styles'
import { ChargerModalRedesign } from './modal/charger-modal-redesign'
import ChargersSelectors from '../../features/chargers/chargers-selectors'
import { ChargersCollapseViewRedesign } from './views/chargers-collapse-view-redesign'
import InfoMessage from 'components/message/InfoMessage'
import { MasterAccountRef } from 'models/master-account'

const initialPagination = { currentPage: 1, perPage: 1, totalEntries: 2 }

export interface nameProps {
  key: string
  label: string
  value: string
  disabled: boolean | undefined
}

export const ChargersBasePageRedesign: React.FC<{
  defaultOCPPStatus?: ConnectorStatus[]
  defaultServiceStatus?: ConnectorServiceStatus[]
  defaultTenantRef?: TenantRef
}> = ({ defaultOCPPStatus, defaultServiceStatus, defaultTenantRef }) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const { selectedTenant, currentUser, IsMobile, IsTablet, IsLaptop, IsDesktop } = useAppState()
  const [pagination, setPagination] = useState<PaginationMeta>(initialPagination)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showChargerModal, setShowChargerModal] = useState(false)
  const [showFirmware, setShowFirmware] = useState(false)
  const [vendors, setVendors] = useState<string[]>([])
  const [selectedVendors, setSelectedVendors] = useState<string[]>([])
  const [connectors, setConnectors] = useState<Connector[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [selectedTenants, setSelectedTenants] = useState<TenantRef[]>([])
  const [listings, setListings] = useState<ListingRef[]>([])
  const [newListings, setNewListings] = useState<ListingRef[]>([])
  const [charger, setCharger] = useState<ChargerRef>()
  const [selectedCharger, setSelectedCharger] = useState<ChargerRef[]>([])
  const [error, setError] = useState<SwtchError>()
  const [OCPPstatuses, setOCPPStatuses] = useState<ConnectorStatus[] | undefined>(defaultOCPPStatus)
  const [serviceStatuses, setServiceStatuses] = useState<ConnectorServiceStatus[] | undefined>(defaultServiceStatus)
  const [selectedChargersandConnectors, setSelectedChargersandConnectors] = useState<
    RemoteMultiChargerTriggerMessageParams[]
  >([])
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [clear, setClear] = useState<boolean>(false)
  const [showSearchQuery, setShowSearchQuery] = useState(false)
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef | undefined>()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const filteredTenant = selectedTenant || defaultTenantRef

  useEffect(() => {
    getConnectors() //initial call
  }, [])

  useEffect(() => {
    if (clear && newListings.length === 0) {
      setListings([])
      setClear(false)
    } else {
      const tempListings = listings
      newListings.map((listing) => {
        if (!tempListings.includes(listing)) {
          return tempListings.push(listing)
        }
        return tempListings
      })
      setListings(tempListings)
    }
  }, [newListings])

  const getConnectors = (page = 1) => {
    setError(undefined)
    setLoading(true)
    let pageNumber = 1
    //To handle event getting passed from Button onClick
    if (typeof page === 'number') {
      pageNumber = page
    }
    GetConnectorsRedesign(
      pageNumber,
      listings,
      filteredTenant,
      selectedTenants,
      charger,
      selectedVendors,
      serviceStatuses,
      OCPPstatuses,
      selectedCountries,
      selectedProvinces,
      masterAccount,
    )
      .then((resp) => {
        const modifiedData = resp.data.map((d) => ({
          ...d,
          key: `${d.id}`,
        }))
        setCurrentPage(pageNumber)
        setConnectors(modifiedData)
        setPagination(resp.pagination)
        setVendors(resp.filters.vendors)
        canShowFirmare()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const canShowFirmare = () => {
    selectedVendors && selectedVendors.length !== 0 && typeof selectedVendors.map((v) => v === 'string')
      ? setShowFirmware(true)
      : setShowFirmware(false)
  }

  const fetchDataWithQuery = (page?: number) => {
    setShowSearchQuery(true)
    getConnectors(page)
  }

  const handlePaginationChange = (page: number, pageSize?: number) => fetchDataWithQuery(page)

  const renderLocationSelector = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
  }

  const renderTitles = () => (
    <ChargersSelectors
      loading={loading}
      vendors={vendors}
      defaultTenantRef={defaultTenantRef}
      tenants={tenants}
      filteredTenant={filteredTenant}
      connectorServiceStatus={connectorServiceStatus}
      defaultServiceStatus={defaultServiceStatus}
      defaultOCPPStatus={defaultOCPPStatus}
      selectedCharger={selectedCharger}
      allListings={listings}
      setAllListings={setListings}
      setListings={setNewListings}
      setTenants={setTenants}
      setCharger={setCharger}
      setSelectedVendors={setSelectedVendors}
      setServiceStatuses={setServiceStatuses}
      setOCPPStatuses={setOCPPStatuses}
      renderLocationSelector={renderLocationSelector}
      getConnectors={fetchDataWithQuery}
      fetchDataWithQuery={fetchDataWithQuery}
      setShowChargerModal={setShowChargerModal}
      setClear={setClear}
      dropdownClassName={'not-in-modal-selector'}
      setMasterAccount={setMasterAccount}
      onTenantSelection={setSelectedTenants}
    />
  )

  const canManageTenant = currentUser?.role === 'admin'

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    //TODO: type for selectedRow
    onSelect: (selectedRow: any, selected: boolean) => {
      if (!selected) {
        const updateSelectedCharger = selectedCharger.filter((c: ChargerRef) => c.id !== selectedRow.charger.id)
        setSelectedCharger(updateSelectedCharger)
        let connectorId: number = -1
        let chargerId: number = -1
        Object.entries(selectedRow).forEach(([key, value]) => {
          if (key === 'connectorId' && typeof value == 'number') {
            connectorId = value
          } else if (key === 'charger' && typeof value === 'object') {
            if (value && value.hasOwnProperty('id')) {
              const obj: any = value
              chargerId = obj['id']
            }
          }
        })

        const selectRowChargerConnector: RemoteMultiChargerTriggerMessageParams[] = [
          { charger_id: chargerId, connector_id: connectorId },
        ]

        const updatedResult = selectedChargersandConnectors.filter(
          ({ charger_id, connector_id }) =>
            !selectRowChargerConnector.some((x) => x.charger_id === charger_id && x.connector_id === connector_id),
        )
        setSelectedChargersandConnectors(updatedResult)
      } else {
        setSelectedChargersandConnectors([
          ...selectedChargersandConnectors,
          { charger_id: selectedRow.charger.id, connector_id: selectedRow.connectorId },
        ])
        setSelectedCharger([...selectedCharger, selectedRow.charger])
        //setRowSelected(true)
      }
    },
    onSelectAll: (selected: boolean, selectedRows: Connector[], remainingCharger: any) => {
      //TODO: type for remainingCharger
      if (!selected) {
        setSelectedCharger([])
        setSelectedChargersandConnectors([])
      } else {
        let restOfChargers: Charger[] = []
        for (var i = 0; i < remainingCharger.length; i++) {
          restOfChargers = [...restOfChargers, remainingCharger[i].charger]
        }
        setSelectedCharger([...selectedCharger, ...restOfChargers])
        const allRows: RemoteMultiChargerTriggerMessageParams[] = []
        selectedRows.map((r) => {
          return allRows.push({ charger_id: r.charger?.id!, connector_id: r.connectorId })
        })
        setSelectedChargersandConnectors(allRows)
      }
    },
    getCheckboxProps: (connector: Connector) => {
      const canManageChargers = currentUser?.role === 'user' && canSelectChargers(currentUser.accesses, connector)
      return { disabled: canManageChargers }
    },
  }

  const chargerColumnsFilter = chargersColumns.filter((el) =>
    showFirmware && currentUser?.role === 'admin' ? el : el.key !== 'firmwareVersion',
  )

  return (
    <>
      <BackgroundContainer>
        <AlertError error={error} />
        <PageHeader title={renderTitles()} />
        <InfoMessage
          loading={loading}
          text="charger"
          totalEntries={pagination.totalEntries}
          showSearchQuery={showSearchQuery}
        />
        <TableStyling usersPage customPadding="21px 10px !important">
          {isDesktop && (
            <Table
              loading={loading}
              size="small"
              pagination={{
                position: ['bottomCenter'],
                total: pagination?.totalEntries,
                current: pagination?.currentPage,
                pageSize: pagination?.perPage,
                showSizeChanger: false,
                onChange: handlePaginationChange,
              }}
              rowSelection={canManageTenant ? { ...rowSelection } : undefined}
              dataSource={connectors}
              key="id"
              columns={chargerColumnsFilter}
            />
          )}
          {isLaptop && (
            <Table
              loading={loading}
              size="small"
              pagination={{
                position: ['bottomCenter'],
                total: pagination?.totalEntries,
                current: pagination?.currentPage,
                pageSize: pagination?.perPage,
                showSizeChanger: false,
                onChange: handlePaginationChange,
              }}
              rowSelection={canManageTenant ? { ...rowSelection } : undefined}
              dataSource={connectors}
              key="id"
              columns={chargerColumnsFilter}
            />
          )}
        </TableStyling>
        {(isMobile || isTablet) && (
          <ChargersCollapseViewRedesign
            connectors={connectors}
            loading={loading}
            pagination={pagination}
            currentPage={currentPage}
            handlePaginationChange={handlePaginationChange}
          />
        )}
        {showChargerModal && (
          <ChargerModalRedesign
            loading={loading}
            selectedCharger={selectedCharger}
            selectedChargersConns={selectedChargersandConnectors}
            onOk={() => setShowChargerModal(false)}
            onCancel={() => setShowChargerModal(false)}
          />
        )}
      </BackgroundContainer>
    </>
  )
}
