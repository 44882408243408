import { AutoComplete, message, Spin } from 'antd'
import { SelectProps } from 'antd/es/select'
import React, { useState } from 'react'
import { GetChargers } from '../../services/data-provider/charger'
import { ChargerRef } from '../../models/charger'
import { SwtchError } from '../../models/error'
import { TenantRef } from '../../models/tenant'
import { SingleOptionContainer } from '../../atom/select'
import { useSelectorTranslation } from '../../hooks/translation/useSelectorTranslation'
import { useChargerTranslation } from '../../hooks/translation/useChargerTranslation'
import { useAppState } from '../../state'

interface props {
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  isCombineLeft?: boolean
  isCombineRightAndLeft?: boolean
  longerWidth?: boolean
  customHeight?: string
  removeSVG?: boolean
  onChargerSelected: (charger: ChargerRef) => void
  onClear: () => void
  onSearch: (charger: ChargerRef) => void
  dropdownClassName?: string
}

const renderCharger = (charger: ChargerRef): any => {
  return {
    value: charger.chargePointSerialNumber,
    charger,
    label: <div>{charger.chargePointSerialNumber}</div>,
  }
}

export const ChargerSelectorRedesign: React.FC<props> = ({
  defaultTenant,
  tenants,
  isCombineRightAndLeft,
  customHeight,
  removeSVG,
  onChargerSelected,
  onClear,
  dropdownClassName,
}) => {
  const [options, setOptions] = useState<SelectProps<object>['options']>([])
  const [fetching, setFetching] = useState(false)
  const { serialNumberText } = useChargerTranslation()
  const { startTypingText } = useSelectorTranslation()
  const { IsDesktop, IsLaptop } = useAppState()
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()

  const searchResult = (value: string) => {
    setFetching(true)
    GetChargers(value, defaultTenant, tenants)
      .then((chargers) => {
        const opts = chargers.map((charger) => {
          return renderCharger(charger)
        })
        setOptions(opts)
        setFetching(false)
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
  }

  const onSelect = (value: string) => {
    options?.forEach((opt) => {
      if (opt.value === value) {
        onChargerSelected(opt.charger)
      }
    })
  }

  const canCombineBorderRightAndLeft = (isDesktop || isLaptop) && isCombineRightAndLeft

  return (
    <>
      <SingleOptionContainer
        customHeight={customHeight}
        isCombineRightAndLeft={canCombineBorderRightAndLeft}
        removeSVG={removeSVG}
      >
        {/* removing svg but might change later */}
        {/* {magnifySVG} */}
        <AutoComplete
          allowClear
          dropdownMatchSelectWidth={false}
          dropdownClassName={dropdownClassName}
          options={options}
          onSelect={onSelect}
          onSearch={searchResult}
          onClear={onClear}
          style={{ minWidth: 162, width: '100%' }}
          placeholder={serialNumberText}
          notFoundContent={fetching ? <Spin size="small" /> : startTypingText}
        />
      </SingleOptionContainer>
    </>
  )
}
