import { Button, Col, PageHeader, Pagination, Row, Space, Table } from 'antd'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { Box } from '../../atom/box'
import { AlertError } from '../../components/error'
import { ChargerSelector } from '../../components/selector/charger-selector'

import { LocationSelector } from '../../components/selector/location-selector'
import MultiListingSelector from '../../components/selector/multi-listing-selector'
import { MultiOptionSelector } from '../../components/selector/multi-option-selector'
import { MultiTenantSelector } from '../../components/selector/multiple-tenant-selector'

import { useFormatMessage } from '../../helpers/intl'
// import { useKeyPress } from '../../hooks/useKeyPress'

import { Charger, ChargerRef } from '../../models/charger'
import { RemoteMultiChargerTriggerMessageParams } from '../../models/charger-remote'
import {
  Connector,
  ConnectorServiceStatus,
  connectorServiceStatus,
  connectorStatus,
  ConnectorStatus,
} from '../../models/connector'
import { SwtchError } from '../../models/error'
import { ListingOption } from '../../models/listing'
import { PaginationMeta } from '../../models/pagination'
import { TenantRef } from '../../models/tenant'
import { canSelectChargers } from '../../models/user'
import { GetConnectors } from '../../services/data-provider/connector'
import { useAppState } from '../../state'
import { ChargerModal } from './modal/charger-modal'
import { chargersColumns } from './table/chargers-columns'
import { ChargersCollapseView } from './views/chargers-collapse-view'

export interface nameProps {
  key: string
  label: string
  value: string
  disabled: boolean | undefined
}

export const ChargersBasePage: React.FC<{
  defaultOCPPStatus?: ConnectorStatus[]
  defaultServiceStatus?: ConnectorServiceStatus[]
  defaultTenantRef?: TenantRef
}> = ({ defaultOCPPStatus, defaultServiceStatus, defaultTenantRef }) => {
  document.querySelector('body')?.classList.remove('redesignActive')
  const { selectedTenant, currentUser, IsMobile, IsTablet, IsLaptop, IsDesktop } = useAppState()
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [currentPage, setCurrentPage] = useState(1)

  const [loading, setLoading] = useState(false)
  const [showChargerModal, setShowChargerModal] = useState(false)
  const [showAdvance, setShowAdvance] = useState(false)
  const [showFirmware, setShowFirmware] = useState(false)

  const [vendors, setVendors] = useState<string[]>([])
  const [selectedVendors, setSelectedVendors] = useState<string[]>([])
  const [connectors, setConnectors] = useState<Connector[]>([])
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [listings, setListings] = useState<ListingOption[]>([])
  const [charger, setCharger] = useState<ChargerRef>()
  const [selectedCharger, setSelectedCharger] = useState<ChargerRef[]>([])

  const [error, setError] = useState<SwtchError>()

  const [OCPPstatuses, setOCPPStatuses] = useState<ConnectorStatus[] | undefined>(defaultOCPPStatus)
  const [serviceStatuses, setServiceStatuses] = useState<ConnectorServiceStatus[] | undefined>(defaultServiceStatus)
  const [selectedChargersandConnectors, setSelectedChargersandConnectors] = useState<
    RemoteMultiChargerTriggerMessageParams[]
  >([])

  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const searchText = useFormatMessage('dashboard.text.search', 'Search')
  const modifyText = useFormatMessage('dashboard.text.modify', 'Modify')
  const advancedSearchBtn = useFormatMessage('dashboard.transactionsPage.advancedSearch', 'Advanced Search')

  const filteredTenant = selectedTenant || defaultTenantRef

  useEffect(() => {
    getConnectors()() //initial call
  }, [])

  // useKeyPress(() => getConnectors(), ['Enter'] || ['NumpadEnter'])

  const getConnectors = (page = 1) => () => {
    setError(undefined)
    setLoading(true)
    GetConnectors(
      page,
      listings,
      filteredTenant,
      tenants,
      charger,
      selectedVendors,
      serviceStatuses,
      OCPPstatuses,
      selectedCountries,
      selectedProvinces,
    )
      .then((resp) => {
        const modifiedData = resp.data.map((d) => ({
          ...d,
          key: `${d.id}`,
        }))
        setCurrentPage(page)
        setConnectors(modifiedData)
        setPagination(resp.pagination)
        setVendors(resp.filters.vendors)
        canShowFirmare()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const canShowFirmare = () => {
    selectedVendors && selectedVendors.length !== 0 && typeof selectedVendors.map((v) => v === 'string')
      ? setShowFirmware(true)
      : setShowFirmware(false)
  }

  const handlePaginationChange = (page: number, pageSize?: number) => getConnectors(page)()

  const renderMultiListingSelector = () => (
    <MultiListingSelector
      onOptionChange={(listings: ListingOption[]) => setListings(listings)}
      defaultTenant={filteredTenant}
      tenants={tenants}
    />
  )

  const renderTenantSelector = () => (
    <>
      {!selectedTenant && (
        <MultiTenantSelector
          onOptionsChange={(tenants) => setTenants(tenants)}
          onClear={() => setTenants([])}
          defaultTenant={defaultTenantRef}
        />
      )}
    </>
  )

  const renderChargerSelector = () => (
    <ChargerSelector
      defaultTenant={filteredTenant}
      tenants={tenants}
      onChargerSelected={(c) => setCharger(c)}
      onClear={() => setCharger(undefined)}
      onSearch={(c) => setCharger(c)}
    />
  )

  const renderMultiVendorSelector = () => (
    <MultiOptionSelector
      options={vendors.map((vendor) => vendor)}
      onOptionsChange={(opts) => setSelectedVendors(opts)}
      onClear={() => setSelectedVendors([])}
      onSearch={(opts) => setSelectedVendors(opts)}
      placeholder="Select Vendor"
    />
  )

  const renderOCPPStatusSelector = () => (
    <MultiOptionSelector
      options={connectorStatus.map((v) => v)}
      defaultValues={defaultOCPPStatus}
      onOptionsChange={(opts) => setOCPPStatuses(opts)}
      onClear={() => setOCPPStatuses(undefined)}
      onSearch={(opts) => setOCPPStatuses(opts)}
      placeholder="Select OCPP Status"
    />
  )

  const renderServiceStatusSelector = () => (
    <MultiOptionSelector
      options={connectorServiceStatus.map((v) => v)}
      defaultValues={defaultServiceStatus}
      onOptionsChange={(opts) => setServiceStatuses(opts)}
      onClear={() => setServiceStatuses(undefined)}
      onSearch={(opts) => setServiceStatuses(opts)}
      placeholder="Select Service Status"
    />
  )

  const renderLocationSelector = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
  }

  const renderTitle = () => {
    return (
      <>
        {isDesktop && (
          <Space wrap>
            {renderMultiListingSelector()}
            {renderTenantSelector()}
            {renderChargerSelector()}
            {renderMultiVendorSelector()}
            {renderServiceStatusSelector()}
            {renderOCPPStatusSelector()}
            {<LocationSelector onSearch={renderLocationSelector} />}
            <Button type="primary" onClick={getConnectors()}>
              {searchText}
            </Button>
            {selectedCharger && selectedCharger.length >= 1 ? (
              <Button type="primary" onClick={() => setShowChargerModal(true)}>
                {modifyText}
              </Button>
            ) : null}
          </Space>
        )}
        {isLaptop && (
          <>
            <Row justify="end">
              <Col span={19}>
                <Space wrap>
                  {renderMultiListingSelector()}
                  {renderTenantSelector()}
                  {renderChargerSelector()}
                  {renderServiceStatusSelector()}
                </Space>
              </Col>

              <Col span={5}>
                <Space>
                  <Button type="primary" onClick={getConnectors()}>
                    {searchText}
                  </Button>
                  <Link onClick={() => setShowAdvance(!showAdvance)} to="#">
                    {advancedSearchBtn}
                  </Link>
                </Space>
              </Col>
            </Row>
            {showAdvance && isLaptop && (
              <Space wrap style={{ marginTop: '8px' }}>
                {renderOCPPStatusSelector()}
                {renderMultiVendorSelector()}
                <LocationSelector onSearch={renderLocationSelector} />
              </Space>
            )}
          </>
        )}
        {(isMobile || isTablet) && (
          <Row justify={isMobile ? 'start' : 'space-between'} align="middle" gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24}>
              {renderMultiListingSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {renderTenantSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {renderChargerSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {renderMultiVendorSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {renderServiceStatusSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {renderOCPPStatusSelector()}
            </Col>
            <Col xs={24} sm={24} md={24}>
              {<LocationSelector onSearch={renderLocationSelector} />}
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Button type="primary" onClick={getConnectors()} block={true}>
                {searchText}
              </Button>
            </Col>
            <Col xs={24} sm={24} md={24}>
              {selectedCharger && selectedCharger.length >= 1 ? (
                <Button type="primary" onClick={() => setShowChargerModal(true)} block={true}>
                  {modifyText}
                </Button>
              ) : null}
            </Col>
          </Row>
        )}
      </>
    )
  }

  const canManageTenant = currentUser?.role === 'admin' //Matched with chargers-base-page-redesign condition

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    //TODO: type for selectedRow
    onSelect: (selectedRow: any, selected: boolean) => {
      if (!selected) {
        const updateSelectedCharger = selectedCharger.filter((c: ChargerRef) => c.id !== selectedRow.charger.id)
        setSelectedCharger(updateSelectedCharger)
        let connectorId: number = -1
        let chargerId: number = -1
        Object.entries(selectedRow).forEach(([key, value]) => {
          if (key === 'connectorId' && typeof value == 'number') {
            connectorId = value
          } else if (key === 'charger' && typeof value === 'object') {
            if (value && value.hasOwnProperty('id')) {
              const obj: any = value
              chargerId = obj['id']
            }
          }
        })

        const selectRowChargerConnector: RemoteMultiChargerTriggerMessageParams[] = [
          { charger_id: chargerId, connector_id: connectorId },
        ]

        const updatedResult = selectedChargersandConnectors.filter(
          ({ charger_id, connector_id }) =>
            !selectRowChargerConnector.some((x) => x.charger_id === charger_id && x.connector_id === connector_id),
        )
        setSelectedChargersandConnectors(updatedResult)
      } else {
        setSelectedChargersandConnectors([
          ...selectedChargersandConnectors,
          { charger_id: selectedRow.charger.id, connector_id: selectedRow.connectorId },
        ])
        setSelectedCharger([...selectedCharger, selectedRow.charger])
        //setRowSelected(true)
      }
    },
    onSelectAll: (selected: boolean, selectedRows: Connector[], remainingCharger: any) => {
      //TODO: type for remainingCharger
      if (!selected) {
        setSelectedCharger([])
        setSelectedChargersandConnectors([])
      } else {
        let restOfChargers: Charger[] = []
        for (var i = 0; i < remainingCharger.length; i++) {
          restOfChargers = [...restOfChargers, remainingCharger[i].charger]
        }
        setSelectedCharger([...selectedCharger, ...restOfChargers])
        const allRows: RemoteMultiChargerTriggerMessageParams[] = []
        selectedRows.map((r) => {
          return allRows.push({ charger_id: r.charger?.id!, connector_id: r.connectorId })
        })
        setSelectedChargersandConnectors(allRows)
      }
    },
    getCheckboxProps: (connector: Connector) => {
      const canManageChargers = currentUser?.role === 'user' && canSelectChargers(currentUser.accesses, connector)
      return { disabled: canManageChargers }
    },
  }

  const chargerColumnsFilter = chargersColumns.filter((el) =>
    showFirmware && currentUser?.role === 'admin' ? el : el.key !== 'firmwareVersion',
  )

  return (
    <>
      <PageHeader title={useFormatMessage('dashboard.chargersPage.heading', 'Chargers')} />
      <Box>
        <Row>
          <Col span={24}>
            <AlertError error={error} />
            {isDesktop && (
              <Table
                title={() => renderTitle()}
                loading={loading}
                size="small"
                pagination={{
                  position: ['bottomCenter'],
                  total: pagination?.totalEntries,
                  current: pagination?.currentPage,
                  pageSize: pagination?.perPage,
                  showSizeChanger: false,
                  onChange: handlePaginationChange,
                }}
                rowSelection={canManageTenant ? { ...rowSelection } : undefined}
                dataSource={connectors}
                key="id"
                columns={chargerColumnsFilter}
              />
            )}
            {isLaptop && (
              <Table
                title={() => renderTitle()}
                loading={loading}
                size="small"
                pagination={{
                  position: ['bottomCenter'],
                  total: pagination?.totalEntries,
                  current: pagination?.currentPage,
                  pageSize: pagination?.perPage,
                  showSizeChanger: false,
                  onChange: handlePaginationChange,
                }}
                rowSelection={canManageTenant ? { ...rowSelection } : undefined}
                dataSource={connectors}
                key="id"
                columns={chargerColumnsFilter}
              />
            )}
            {(isMobile || isTablet) && (
              <>
                {renderTitle()}
                <ChargersCollapseView connectors={connectors} loading={loading} />
                <br />
                <Pagination
                  current={currentPage}
                  onChange={handlePaginationChange}
                  total={pagination?.totalEntries}
                  showQuickJumper={false}
                  showSizeChanger={false}
                  showTitle={true}
                  style={{ textAlign: 'center' }}
                />
              </>
            )}
          </Col>
          {showChargerModal && (
            <ChargerModal
              loading={loading}
              selectedCharger={selectedCharger}
              selectedChargersConns={selectedChargersandConnectors}
              onOk={() => setShowChargerModal(false)}
              onCancel={() => setShowChargerModal(false)}
            />
          )}
        </Row>
      </Box>
    </>
  )
}
