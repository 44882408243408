import React from 'react'
import moment from 'moment'
import momentTz from 'moment-timezone'
import { AlignedContentRight } from './wrapper'
import { theme } from 'theme'

interface props {
  date: moment.Moment | string
  timezone: string
}

interface DateTimeV2Props extends props {
  isSidePanel?: boolean
}

export const DateTime: React.FC<props> = ({ date, timezone }) => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const format = 'YYYY-MM-DD, HH:mm:ss'
  return (
    <span>
      {date.format(format)}
      <small> {momentTz.tz(date, timezone).format('(Z)')}</small>
    </span>
  )
}

export const DateTimeV2: React.FC<DateTimeV2Props> = ({ date, timezone, isSidePanel }) => {
  if (typeof date === 'string') {
    date = moment(date)
  }

  const formatDate = 'YYYY/MM/DD'
  const formatTime = 'HH:mm:ss (Z)'

  return (
    <AlignedContentRight
      children={
        <>
          <span>{date.format(formatDate)}</span>
          <span className="paragraph-04-light opacity-06" style={{ color: isSidePanel ? theme.colors.softBlack : '' }}>
            {' '}
            {momentTz.tz(date, timezone).format(formatTime)}
          </span>
        </>
      }
    />
  )
}

export const TenantTimezone: React.FC<{ timezone: string | null }> = ({ timezone }) => {
  if (!timezone) {
    return null
  }
  const tenantTimezone = moment.tz(timezone).format('z')
  return <span>({tenantTimezone})</span>
}
