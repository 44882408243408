import { Col, Divider, Form, FormInstance, InputNumber, Row, Select, Switch } from 'antd'
import { useState } from 'react'

import { LoiteringStatusType } from '../../models/loitering'
import { useListingLoiteringTrans } from '../../hooks/translation/useListingLoiteringTrans'
import { useTenantTabPricingTranslation } from '../../hooks/translation/useTenantTabPricingTranslation'
import { MustBePositiveIntegers } from 'components/rules/rules'

interface props {
  form: FormInstance<any>
}

export const ListingLoitering: React.FC<props> = ({ form }) => {
  const [loiteringPenalty, setLoiteringPenalty] = useState(form.getFieldValue('loiteringPenalty'))
  const [loiteringTimeLimit, setLoiteringTimeLimit] = useState(form.getFieldValue('loiteringTimeLimit'))
  const [loiteringGracePeriod, setLoiteringGracePeriod] = useState(form.getFieldValue('loiteringGracePeriod'))
  const [loiteringStatusType, setLoiteringStatusType] = useState<LoiteringStatusType>(
    form.getFieldValue('loiteringStatus'),
  )

  const handleLoiteringStatus = (l: LoiteringStatusType) => {
    setLoiteringStatusType(l)
    form.setFieldsValue({ loiteringStatus: l })
  }

  const handleLoiteringGracePeriod = (input: number) => {
    setLoiteringGracePeriod(input)
    form.setFieldsValue({ loiteringGracePeriod: loiteringGracePeriod })
  }

  const handleLoiteringTimeLimit = (input: number) => {
    setLoiteringTimeLimit(input)
    form.setFieldsValue({ loiteringTimeLimit: loiteringTimeLimit })
  }

  const {
    loiteringStatusText,
    loiteringStatusNoPenaltyText,
    loiteringStatusTimeBasedText,
    loiteringStatusBasedText,
    loiteringTimeBasedMinutesText,
    loiteringGracePeriodMinutesText,
    loiteringPenaltyHourText,
  } = useListingLoiteringTrans()

  const { disableLoiteringTxt } = useTenantTabPricingTranslation()

  return (
    <>
      <Divider>{loiteringStatusText}</Divider>
      <Form.Item name="disableLoiteringForTenantMember" label={disableLoiteringTxt} valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item name="loiteringStatus" label={loiteringStatusText}>
        <Select
          placeholder="Loitering Status"
          onChange={(l: LoiteringStatusType) => handleLoiteringStatus(l)}
          value={loiteringStatusType}
        >
          <Select.Option value="no_penalty">{loiteringStatusNoPenaltyText}</Select.Option>
          <Select.Option value="time_based">{loiteringStatusTimeBasedText}</Select.Option>
          <Select.Option value="status_based">{loiteringStatusBasedText}</Select.Option>
        </Select>
      </Form.Item>
      {form.getFieldValue('loiteringStatus') !== 'no_penalty' && (
        <Form.Item name="loiteringPenalty" label={loiteringPenaltyHourText} rules={[{ required: true }]}>
          <Row gutter={8} align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <InputNumber
                step="0.01"
                precision={2}
                value={loiteringPenalty}
                onChange={(input) => setLoiteringPenalty(input)}
                controls={false}
              />
            </Col>
          </Row>
        </Form.Item>
      )}
      {loiteringStatusType === 'time_based' && (
        <>
          <Form.Item
            name="loiteringTimeLimit"
            label={loiteringTimeBasedMinutesText}
            rules={[{ required: true }, MustBePositiveIntegers]}
          >
            <Row gutter={8} align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <InputNumber
                  step="1"
                  value={loiteringTimeLimit}
                  onChange={(input) => handleLoiteringTimeLimit(input)}
                  controls={false}
                />
              </Col>
            </Row>
          </Form.Item>
        </>
      )}
      {loiteringStatusType !== 'no_penalty' && (
        <>
          <Form.Item name="loiteringGracePeriod" label={loiteringGracePeriodMinutesText} rules={[{ required: true }]}>
            <Row gutter={8} align="middle">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <InputNumber
                  step="1"
                  value={loiteringGracePeriod}
                  onChange={(input) => handleLoiteringGracePeriod(input)}
                  controls={false}
                />
              </Col>
            </Row>
          </Form.Item>
        </>
      )}
    </>
  )
}
