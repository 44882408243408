import { Button, Form, Skeleton } from 'antd'
import { layout, oneButtonLayout } from '../../atom/form/page-layout'
import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { ListingAccessVisibility } from '../../components/listings/listing-access-visibility'
import { ListingAuthorV2 } from '../../components/listings/listing-author-v2'
import { ListingBasic } from '../../components/listings/listing-basic'
import { ListingChargerV2 } from '../../components/listings/listing-charger-v2'
import { ListingCommissionFee } from '../../components/listings/listing-commission-fee'
import { ListingLoitering } from '../../components/listings/listing-loitering'
import { ListingPrice } from '../../components/listings/listing-price'
import { renderFormatMessage } from '../../helpers/intl'
import { useCreateListing } from '../../hooks/useCreateListing'
import { useAppState } from '../../state'
import { ListingPeakShaving } from '../../components/listings/listing-peak-shaving'
import { useState } from 'react'
import ListingStatusModal from './modal/ListingStatusModal'
import { ListingServiceStatus } from 'models/connector'
import { Box } from 'atom/box'
import { CreateListingFormWrapper } from 'atom/listings'

const ListingCreateBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const { loading, error, initialValues, onFinish, form } = useCreateListing()
  const [currentServiceStatus, setCurrentServiceStatus] = useState<ListingServiceStatus>('awaiting_commissioning')
  const [currentReason, setCurrentReason] = useState<string[]>()
  const [editListingStatusModal, setEditListingStatusModal] = useState(false)
  const { currentUser } = useAppState()

  return (
    <>
      <AlertError error={error} />
      <Skeleton loading={loading}>
        <Box padding>
          <Form
            {...layout}
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}
            scrollToFirstError={true}
            size="large"
          >
            <CreateListingFormWrapper>
              <ListingBasic
                form={form}
                onEditListingStatusModal={() => setEditListingStatusModal(!editListingStatusModal)}
                serviceStatus={currentServiceStatus}
              />
              <ListingAccessVisibility form={form} />
              <ListingAuthorV2 form={form} />
              <ListingChargerV2 form={form} />
              <ListingPrice form={form} />
              <ListingCommissionFee form={form} />
              <ListingLoitering form={form} />
              {currentUser &&
                (currentUser?.email === 'suleman.rai@swtchenergy.com' ||
                  currentUser?.email === 'jing.guo@swtchenergy.com' ||
                  currentUser?.email === 'alton.yeung@swtchenergy.com' ||
                  currentUser?.email === 'nicole.politis@swtchenergy.com' ||
                  currentUser?.email === 'michael.vu@swtchenergy.com') && <ListingPeakShaving form={form} />}
              <Form.Item {...oneButtonLayout}>
                <Button type="primary" block htmlType="submit">
                  {renderFormatMessage('dashboard.createNewListing.submitBtn', 'Submit')}
                </Button>
              </Form.Item>
            </CreateListingFormWrapper>
          </Form>
        </Box>
        {editListingStatusModal && (
          <ListingStatusModal
            onServiceStatus={(newServiceStatus, reason) => {
              setCurrentServiceStatus(newServiceStatus)
              setCurrentReason(reason)
              form.setFieldsValue({
                serviceStatus: newServiceStatus,
                reason: reason,
              })
            }}
            onCancel={() => setEditListingStatusModal(false)}
            serviceStatus={currentServiceStatus}
            reason={currentReason}
          />
        )}
      </Skeleton>
    </>
  )
}

export const ListingCreatePageV2 = withAuthenticatedLayout(ListingCreateBasePage)
