import { useEffect, useRef, useState } from 'react'
import { Card, Col, Row } from 'antd'

import { CardWrapper } from 'atom/card'
import {
  ChargersAvailabilityMetaContainer,
  MicroChartContainer,
  StationOverviewMetaContainer,
  StationOverviewMetaCountContainer,
} from 'pages/dashboard/ContainerStyles/homepageStyles'

interface props {
  title: string
  infoIcon?: JSX.Element
  wsLoading: boolean
  infoText?: string | number
  infoTextFormatter?: (infoText: JSX.Element, infoScale?: number) => JSX.Element
  chargerInfoChart?: JSX.Element
  overviewIcon?: JSX.Element
  infoDetails?: {
    label?: string | number
    subLabel?: string | number
    icon?: JSX.Element
    labelFormat?: {
      label: (value: string | number) => JSX.Element | string
      subLabel: (value: string | number) => JSX.Element | string
    }
  }[]
}
const defaultInfoTextFormatter = (infoTextElement: JSX.Element) => infoTextElement
const defaultLabelFormat = {
  label: (value: string | number) => <span className="default-label">{value}</span>,
  subLabel: (value: string | number) => <b>({value})</b>,
}

export const CoreInfoCard: React.FC<props> = ({
  title,
  wsLoading,
  infoIcon,
  infoTextFormatter = defaultInfoTextFormatter,
  infoText,
  chargerInfoChart,
  overviewIcon,
  infoDetails,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)
  const rowRef = useRef<HTMLDivElement>(null)

  const [infoScale, setInfoScale] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (rowRef.current && containerRef.current && infoText !== undefined && !wsLoading) {
        const scale =
          (containerRef.current.getBoundingClientRect().width -
            (iconRef?.current ? iconRef?.current.getBoundingClientRect().width : 0) -
            12 * 2) /
          (rowRef.current.getBoundingClientRect().width - 12 * 2)
        if (scale < 1) {
          //To scale longer text
          setInfoScale(scale)
        } else {
          setInfoScale(1)
        }
      }
      //Wait till recalculation
    }, 10)
    return () => clearTimeout(timer)
  }, [rowRef, iconRef, containerRef, infoText, wsLoading, overviewIcon])

  return (
    <CardWrapper>
      <Card
        title={
          <Row>
            <span className="heading-06-bold title">{title}</span>
            {infoIcon}
          </Row>
        }
        loading={wsLoading}
      >
        <Row align="middle" justify="space-between">
          <Col xl={chargerInfoChart ? 18 : 24} xxl={chargerInfoChart ? 18 : 24}>
            <StationOverviewMetaContainer ref={containerRef}>
              {overviewIcon && <Row ref={iconRef}>{overviewIcon}</Row>}
              <Row ref={rowRef} className="stretch">
                {infoText !== undefined &&
                  infoTextFormatter &&
                  infoTextFormatter(
                    <h1
                      className={`active-count heading-02-black ${!overviewIcon ? 'no-icon-card' : ''}`}
                      style={{
                        transform: `scale(${infoScale})`,
                      }}
                    >
                      {`${infoText}`}
                    </h1>,
                    infoScale,
                  )}
              </Row>
            </StationOverviewMetaContainer>
          </Col>
          {chargerInfoChart && (
            <Col sm={24} xl={6} xxl={6}>
              <MicroChartContainer>{chargerInfoChart}</MicroChartContainer>
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={24} xl={24} xxl={24}>
            <ChargersAvailabilityMetaContainer>
              <StationOverviewMetaCountContainer>
                {infoDetails?.map((info) => (
                  <div className="paragraph-01-regular info-area">
                    {info.label !== undefined && (
                      <span className="label-area">
                        {info?.labelFormat ? info?.labelFormat.label(info.label) : defaultLabelFormat.label(info.label)}
                      </span>
                    )}
                    {info?.subLabel !== undefined && (
                      <div className="sublabel-area">
                        {info?.labelFormat
                          ? info?.labelFormat.subLabel(info.subLabel)
                          : defaultLabelFormat.subLabel(info.subLabel)}
                      </div>
                    )}
                    {info?.icon}
                  </div>
                ))}
              </StationOverviewMetaCountContainer>
            </ChargersAvailabilityMetaContainer>
          </Col>
        </Row>
      </Card>
    </CardWrapper>
  )
}

export default CoreInfoCard
