import { theme } from '../../theme'

export const arrowLeftSVG = (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.86561 3.503L2.8797 7.48888M2.8797 7.48888L6.8648 11.474M2.8797 7.48888L11.4797 7.48808C13.6888 7.48788 15.4795 9.27858 15.4793 11.4877L15.4789 15.2022"
      stroke={theme.colors.darkGray}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const multiArrow = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill={theme.colors.white} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1722 12L12.2222 7.05L13.6362 5.636L20.0002 12L13.6362 18.364L12.2222 16.95L17.1722 12Z"
      fill={theme.colors.white}
    />
    <path
      d="M10.1722 12L5.22217 7.05L6.63617 5.636L13.0002 12L6.63617 18.364L5.22217 16.95L10.1722 12Z"
      fill={theme.colors.white}
    />
  </svg>
)

export const rightArrowSVG = (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5 5L1 9" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export const downArrowSVG = (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1L5 5L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export const upArrowSVG = (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5L5 1L9 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)
