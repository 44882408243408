import pluralize from 'pluralize'
import { apiClient, apiMetabaseClient } from './client'
import { addQueryString } from '../../helpers/url'
import { TenantRef } from '../../models/tenant'
import configProvider from '../../config'
import { returnMock } from './mock'
import { log } from '../../logger'
import { ReportsResponse } from '../../models/http'
import { MetabaseReportsResponse, Report, ReportType, ReportTypeFrame } from '../../models/reports'
import { mockReports, mockReportsResp } from '../../mock/report-mock'
import { reportsQuery } from '../../helpers/filter/reports'
import { ReportFilter } from '../../models/filter'

export async function GetReports(params: {
  page: number
  reportType: ReportType
  startDate?: moment.Moment
  endDate?: moment.Moment
  defaultTenant?: TenantRef
  tenants?: TenantRef[]
  timeframe?: ReportTypeFrame
  selectedCountries?: string[]
  selectedProvinces?: string[]
}): Promise<ReportsResponse> {
  let url = '/reports'

  let queryParams: { [key: string]: string } = {}

  const {
    page,
    reportType,
    startDate,
    endDate,
    defaultTenant,
    tenants,
    timeframe,
    selectedCountries,
    selectedProvinces,
  } = params

  if (defaultTenant) {
    queryParams['tenant_id'] = `${defaultTenant.id}`
  }

  if (tenants && tenants.length > 0) {
    // since hitting mongo we request tenant.name.... maybe we should resolve
    // the id on rails and be consistent
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  if (startDate) {
    queryParams['start_date'] = `${startDate.format('DD-MM-YYYY')}`
  }

  if (endDate) {
    queryParams['end_date'] = `${endDate.format('DD-MM-YYYY')}`
  }

  if (timeframe) {
    queryParams['timeframe'] = `${timeframe}`
  }

  if (reportType) {
    queryParams['report_type'] = `${reportType}`
  }

  if (selectedCountries && selectedCountries.length > 0) {
    queryParams['country'] = `${selectedCountries}`
  }

  if (selectedProvinces && selectedProvinces.length > 0) {
    queryParams['province'] = `${selectedProvinces}`
  }

  queryParams['page'] = page.toString()

  url = addQueryString(url, queryParams)
  log('getting reports', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockReportsResp(reportType, defaultTenant, page))
  }

  return apiClient(url, {})
}

export async function EmailReport(report: Report, emails: string): Promise<Report> {
  const url = `/reports/${report.id}/email`

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockReports[0])
  }

  log('emailing reports', { url: url })
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      emails: emails,
    }),
  })
}

export async function GenerateReports(): Promise<string> {
  let url = '/reports/generate'

  log('generating reports', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock('generating report')
  }

  return apiClient<{ response: string }>(url, {}).then((resp) => resp.response)
}

export async function GenerateErroredReports(params: {
  startDate: moment.Moment
  endDate: moment.Moment
}): Promise<string> {
  let url = '/reports/generate_errored_transactions'

  log('generating reports', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock('Generating errored transactions reports')
  }

  let queryParams: { [key: string]: string } = {}

  const { startDate, endDate } = params

  if (startDate) {
    queryParams['start_date'] = `${startDate.format('DD-MM-YYYY')}`
  }

  if (endDate) {
    queryParams['end_date'] = `${endDate.format('DD-MM-YYYY')}`
  }

  url = addQueryString(url, queryParams)

  return apiClient<{ response: string }>(url, {}).then((resp) => resp.response)
}

export async function DeleteReports(reportIds: number[]): Promise<string> {
  const url = '/reports/delete'

  log(`deleting ${reportIds.length} ${pluralize('reports', reportIds.length)}`)
  if (configProvider.config.mockEnabled) {
    return returnMock('deleting reports')
  }

  return apiClient<{ response: string }>(url, {
    method: 'POST',
    body: JSON.stringify({ reports: { report_ids: reportIds } }),
  }).then((resp) => resp.response)
}

export async function GetMetabaseReport(params: ReportFilter): Promise<MetabaseReportsResponse> {
  let url = '/report/get-metabase-report-url'

  const queryParams = reportsQuery(params)

  url = addQueryString(url, queryParams)

  return apiMetabaseClient(url, {})
}
