import { useState } from 'react'

import { NewListing } from 'models/listing'
import { Price } from 'models/price'

import { useAppState } from 'state'
import { convertStringifyNumberToNumberType, formatCentsPrice, formatPrice } from 'helpers/number-format'
import { formatListingCategory } from 'helpers/listing'

import TimeOfDayModal from '../modal/TimeOfDayModal'
import { usePricingSchemasTranslation } from 'hooks/translation/usePricingSchemaTranslation'

interface props {
  listing: NewListing
  term?: string
}

const HandlePrice: React.FC<props> = ({ listing, term }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile } = useAppState()
  const [selectedListing, setSelectedListing] = useState<NewListing | undefined>()
  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  const showTimeOfDayModal = (listing: NewListing) => setSelectedListing(listing)

  const handleModal = () => setSelectedListing(undefined)

  const isSmallScreen = isMobile || isTablet ? 'flex-right' : ''
  const isLargeScreen = isLaptop || isDesktop ? 'flex-and-gap-below' : ''

  const { feeText, timeOfDayText } = usePricingSchemasTranslation()

  const justPrice = (price: Price, parseListingKind: JSX.Element) => {
    const { priceType, pricePerUnitCents } = price

    return (
      <div className={isLargeScreen}>
        <div className={isSmallScreen}>{`${formatCentsPrice(pricePerUnitCents)}/${isItHourOrkWh(priceType)}`}</div>
        {parseListingKind}
      </div>
    )
  }

  const parsePrice = (listing: NewListing, term?: string) => {
    if (term === 'preferredPrice') {
      return handlePreferredPrice(listing.price)
    }

    return parseGeneralPrice(listing)
  }

  const freelisting = (parseListingKind: JSX.Element) => (
    <div className={isLargeScreen}>
      <div className={isSmallScreen}>Free</div>
      {parseListingKind}
    </div>
  )

  const flatFeeOnly = (parseListingKind: JSX.Element, flatFee: number | string) => {
    return (
      <div className={isLargeScreen}>
        <div className={`${isSmallScreen}`}>{formatPrice(Number(flatFee))} Fee</div>
        {parseListingKind}
      </div>
    )
  }

  const flatFeeAndPrice = (
    parseListingKind: JSX.Element,
    pricePerUnitCents: number,
    priceType: string,
    flatFee: number,
  ) => {
    return (
      <div className={isLargeScreen}>
        <div className={isSmallScreen}>
          {formatCentsPrice(pricePerUnitCents)}/{isItHourOrkWh(priceType)} + {formatPrice(flatFee)} Fee
        </div>
        {parseListingKind}
      </div>
    )
  }

  const renderTimeOfDay = (listing: NewListing, flatFee: number, parseListingKind: JSX.Element) => {
    const timeOfDayPriceWeekday = listing.price.timeOfDayPriceWeekday
    const timeOfDayPriceWeekend = listing.price.timeOfDayPriceWeekend

    const isTODPriceWeekdaySame = timeOfDayPriceWeekday.every((value) => value === timeOfDayPriceWeekday[0])
    const isTODPriceWeekendSame = listing.price.timeOfDayPriceWeekend.every(
      (value) => value === timeOfDayPriceWeekend[0],
    )

    const isTODPriceWeekdayAllZero = timeOfDayPriceWeekday.every((value) => value === 0)
    const isTODPriceWeekendAllZero = timeOfDayPriceWeekday.every((value) => value === 0)

    const sameTODValueAndNotZero =
      isTODPriceWeekdaySame && isTODPriceWeekendSame && !isTODPriceWeekdayAllZero && !isTODPriceWeekendAllZero

    const renderTODValue = () => {
      // Free listing when all value is zero
      if (isTODPriceWeekdayAllZero && isTODPriceWeekendAllZero) {
        return freelisting(parseListingKind)
      } else if (sameTODValueAndNotZero) {
        // Display the fixed TOD price / unit + flat fee
        return (
          <>
            {formatCentsPrice(timeOfDayPriceWeekday[0])}/{isItHourOrkWh(listing.price.priceType)}
            {flatFee > 0 ? `+ ${formatPrice(Number(flatFee))} ${feeText}` : ''}
            {parseListingKind}
          </>
        )
      } else {
        // Display variable TOD Price / unit + flat fee
        return (
          <div className={isLargeScreen}>
            {(!isTODPriceWeekdaySame || !isTODPriceWeekendSame) &&
              !isTODPriceWeekdayAllZero &&
              !isTODPriceWeekendAllZero && (
                <>
                  <span onClick={() => showTimeOfDayModal(listing)} className={`${isSmallScreen} pointer underline`}>
                    {flatFee > 0 ? `${timeOfDayText} + ${formatPrice(Number(flatFee))} ${feeText}` : timeOfDayText}
                  </span>
                  {parseListingKind}
                </>
              )}
          </div>
        )
      }
    }

    return renderTODValue()
  }

  const parseGeneralPrice = (listing: NewListing) => {
    const { price, kind } = listing
    const { enableTimeOfDayPricing, flatFee, priceType, pricePerUnitCents } = price

    const numberFlatFee = convertStringifyNumberToNumberType(flatFee)

    const parseListingKind = (
      <div className={`${isSmallScreen} opacity-06  paragraph-04-light`}>{formatListingCategory(kind)}</div>
    )

    if (enableTimeOfDayPricing) return renderTimeOfDay(listing, numberFlatFee, parseListingKind)

    if (numberFlatFee === 0 && pricePerUnitCents === 0) return freelisting(parseListingKind)

    if (numberFlatFee === 0 && pricePerUnitCents > 0) return justPrice(price, parseListingKind)

    if (numberFlatFee > 0 && pricePerUnitCents === 0) return flatFeeOnly(parseListingKind, numberFlatFee)

    if (numberFlatFee > 0 && pricePerUnitCents > 0)
      return flatFeeAndPrice(parseListingKind, pricePerUnitCents, priceType, numberFlatFee)
  }

  return (
    <>
      {parsePrice(listing, term)}
      {selectedListing && (
        <TimeOfDayModal
          title={selectedListing.title}
          priceType={isItHourOrkWh(selectedListing.price.priceType)}
          isModalOpen={!!selectedListing}
          flatFee={Number(selectedListing.price.flatFee)}
          onModalOpenClose={handleModal}
          timeOfDayPriceWeekend={selectedListing.price.timeOfDayPriceWeekend}
          timeOfDayPriceWeekday={selectedListing.price.timeOfDayPriceWeekday}
        />
      )}
    </>
  )
}

export default HandlePrice

const iskWhOrHour = (priceType: string, price: string) => (priceType === 'kWh' ? `${price}/kWh` : `${price}/hr`)

const handlePreferredPrice = (price: Price) => {
  if (price.preferedPricePerUnitCents && price.preferedPricePerUnitCents > 0) {
    const formattedPrice = formatCentsPrice(price.preferedPricePerUnitCents)
    return iskWhOrHour(price.preferedPricingPriceType, formattedPrice)
  }
  return '---'
}

const isItHourOrkWh = (priceType: string) => (priceType === 'Hour' ? 'hr' : 'kWh')
