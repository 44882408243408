import { Button, Form, Input, Select } from 'antd'

import { NewListing } from '../../models/listing'
import { useListingBasicTrans } from '../../hooks/translation/useListingBasicTrans'
import { useSelectorTranslation } from '../../hooks/translation/useSelectorTranslation'
import { useListingServiceStatus } from '../../hooks/useListingServiceStatus'
import { useAppState } from '../../state'
import { MustBeRequired } from '../rules/rules'

import { layout } from '../../atom/form/modal-layout'
import { AlertError } from '../error'
import { useGeneralTranslation } from '../../hooks/translation/useGeneralTranslation'
import { MultiOptionSelectorWrapper } from '../../atom/select'
import { listingServiceStatus } from '../../models/connector'
import { renderFormatMessage } from '../../helpers/intl'
import { DecommisionedModal } from 'pages/listing/modal/DecommisionedModal'

interface props {
  listings: NewListing[]
  onOk: () => void
}

export const UpdateListingServiceStatus: React.FC<props> = ({ listings, onOk }) => {
  const { language } = useAppState()

  const {
    error,
    loading,
    secondDropdownOptions,
    dependentSelectorValue,
    showOtherReasonField,
    selectRef,
    openDecommisionedModal,
    handleSelect,
    onReasonChange,
    onServiceStatusChange,
    decommisionedModalOnFinish,
    showAlertIfDecommissioned,
    closeDecommisionedModal,
    form,
  } = useListingServiceStatus(listings, onOk)

  const { statusIsRequired } = useListingBasicTrans()
  const { selectText, applyText } = useGeneralTranslation()
  const {
    startTypingText,
    serviceStatusText,
    selectAReasonText,
    selectReasonForStatusChangeText,
    specificANewReasonText,
    typeAReasonText,
  } = useSelectorTranslation()

  const chooseLayout = () => {
    let frLayout = {
      labelCol: { span: 7 },
      wrapperCol: { span: 24 },
    }
    return language === 'fr' ? frLayout : layout
  }

  return (
    <>
      <AlertError error={error} />
      <DecommisionedModal
        onOk={decommisionedModalOnFinish}
        visible={openDecommisionedModal}
        onCancel={closeDecommisionedModal}
      />
      <Form form={form} {...chooseLayout()} onFinish={showAlertIfDecommissioned}>
        <>
          <Form.Item
            name="new_status"
            label={serviceStatusText}
            rules={[MustBeRequired(statusIsRequired)]}
            className="service-status"
          >
            <Select
              placeholder={`${selectText} ${serviceStatusText}`}
              onChange={onServiceStatusChange}
              disabled={loading}
              loading={loading}
            >
              {listingServiceStatus.map((status, index) => (
                <Select.Option key={`${status}-${index}`} value={status} label={status}>
                  {renderFormatMessage(`dashboard.serviceStatus.${status}`, status)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="reason" label={selectAReasonText}>
            <MultiOptionSelectorWrapper removeSVG={true} longerWidth={true}>
              <Select
                ref={selectRef}
                onSelect={handleSelect}
                allowClear={true}
                placeholder={selectReasonForStatusChangeText}
                mode="multiple"
                onChange={onReasonChange}
                maxTagCount={2}
                value={dependentSelectorValue}
                notFoundContent={startTypingText}
                disabled={loading}
                loading={loading}
              >
                {secondDropdownOptions.map((option) => (
                  <Select.Option value={option.key}>{option.label}</Select.Option>
                ))}
              </Select>
            </MultiOptionSelectorWrapper>
          </Form.Item>

          {showOtherReasonField && (
            <Form.Item name="other_reason" rules={[{ required: true }]} label={typeAReasonText}>
              <Input placeholder={specificANewReasonText} type="text" />
            </Form.Item>
          )}
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {applyText}
            </Button>
          </Form.Item>
        </>
      </Form>
    </>
  )
}
