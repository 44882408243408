import { useEffect, useState } from 'react'
import { Tenant, TenantContact, TenantOverview, TenantPayload } from 'models/tenant'
import { MasterAccountRef } from 'models/master-account'
import { Form, message } from 'antd'
import { useTenantTranslation } from './translation/useTenantTranslation'
import { useUserTranslation } from './translation/useUserTranslation'
import { useFormatMessage } from 'helpers/intl'
import { UpdateMasterAccount } from 'services/data-provider/master-account'
import { getChangedValues } from 'helpers/form'

export const useTenantTabOverview = (tenant: Tenant, onSave: (tenant: TenantPayload) => void) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef | undefined>(tenant.masterAccount)
  const [onsiteContact, setOnsiteContact] = useState<TenantContact | undefined>()
  const [disabled, setDisabled] = useState(true)
  const [editAccountDetailModal, setEditAccountDetailModal] = useState(false)
  const [editTenantStatusModal, setEditTenantStatusModal] = useState(false)
  const [remittanceEmails, setRemittanceEmails] = useState(
    tenant.masterAccount ? tenant.masterAccount.remittanceEmails : [],
  )
  const [remittanceFrequency, setRemittanceFrequency] = useState(tenant.masterAccount?.remittanceFrequency)
  const [status, setStatus] = useState(tenant.status)
  const { noneText } = useUserTranslation()
  const tenantName = tenant.displayName || tenant.name
  const tenantUpdatedText = useFormatMessage(
    'dashboard.tenantPage.overviewTab.tenantUpdatedMessage',
    '-1 has been updated!',
  ).replace('-1', tenantName)
  const {
    monthlyText,
    quarterlyText,
    annuallyText,
    arenaText,
    campgroundText,
    fleetText,
    gasStationText,
    governmentText,
    hospitalText,
    hotelText,
    multiUniResidentialText,
    onStreetText,
    parkingLotText,
    parkingGarageText,
    publicText,
    retailText,
    schoolText,
    singleFamilyResidentialText,
    workplaceText,
  } = useTenantTranslation()

  useEffect(() => {
    tenant.tenantContacts.map((contact) => {
      if (contact.typeOfContact === 'onsite') {
        setOnsiteContact({
          namePrimary: contact.namePrimary,
          email: contact.email,
          phoneNumber: contact.phoneNumber,
        })
        form.setFieldsValue({
          namePrimary: contact.namePrimary,
          email: contact.email,
          phoneNumber: contact.phoneNumber,
        })
      }
      return contact
    })
  }, [])

  useEffect(() => {
    setRemittanceEmails(masterAccount?.remittanceEmails)
    setRemittanceFrequency(masterAccount?.remittanceFrequency)
    form.setFieldsValue({
      remittanceEmails: masterAccount?.remittanceEmails?.join(','),
      remittanceFrequency: masterAccount?.remittanceFrequency ? renderText(masterAccount?.remittanceFrequency) : null,
    })
  }, [masterAccount])

  useEffect(() => {
    form.setFieldsValue({
      remittanceEmails: remittanceEmails?.join(','),
      remittanceFrequency: remittanceFrequency ? renderText(remittanceFrequency) : null,
    })
  }, [remittanceEmails, remittanceFrequency])

  useEffect(() => {
    form.setFieldsValue({
      status: status,
    })
  }, [status])

  const renderText = (text: string) => {
    switch (text) {
      case 'not_applicable':
        return noneText
      case 'monthly':
        return monthlyText
      case 'quarterly':
        return quarterlyText
      case 'annually':
        return annuallyText
      case 'arena_or_stadium':
        return arenaText
      case 'campground':
        return campgroundText
      case 'fleet':
        return fleetText
      case 'gas_station':
        return gasStationText
      case 'government':
        return governmentText
      case 'hospital':
        return hospitalText
      case 'hotel':
        return hotelText
      case 'multi_unit_residential':
        return multiUniResidentialText
      case 'on_street':
        return onStreetText
      case 'parking_lot':
        return parkingLotText
      case 'parking_garage':
        return parkingGarageText
      case 'public':
        return publicText
      case 'retail':
        return retailText
      case 'school_university':
        return schoolText
      case 'single_family_residential':
        return singleFamilyResidentialText
      case 'workplace':
        return workplaceText
      default:
        return text
    }
  }

  const initialValues = {
    displayName: tenant.displayName,
    status: tenant.status,
    serviceLevel: tenant.serviceLevel,
    omOptin: String(tenant.omOptin),
    uniformServiceStatus: tenant.uniformServiceStatus,
    allowUniformServiceStatus: tenant.allowUniformServiceStatus,
    masterAccount: tenant.masterAccount,
    taxRegistration: tenant.taxRegistration,
    taxNumber: tenant.taxNumber,
    chargerSetting: tenant.chargerSetting ? tenant.chargerSetting : null,
    remittanceEmails: remittanceEmails?.join(','),
    remittanceFrequency: remittanceFrequency ? renderText(remittanceFrequency) : null,
    namePrimary: onsiteContact?.namePrimary,
    email: onsiteContact?.email,
    phoneNumber: onsiteContact?.phoneNumber,
    notes: tenant.notes,
    partnerIds: tenant.tenantPartners?.map((partner) => partner.partnerId),
    revSharePartner: tenant?.revSharePartner,
  }

  const updateTenantOverview = async (values: TenantOverview) => {
    const changedValues: any = { ...getChangedValues(initialValues, values as any), id: tenant.id }
    if (initialValues?.revSharePartner?.id === changedValues?.revSharePartner?.id) {
      changedValues.revSharePartner = undefined
    }
    if (masterAccount) {
      changedValues.masterAccountId = masterAccount.id
      UpdateMasterAccount(masterAccount)
        .then(() => {})
        .catch((error) => console.log(error))
        .finally(() => setLoading(false))
    }

    if (values['uniformServiceStatus'] !== undefined) {
      changedValues['uniformServiceStatus'] = values['uniformServiceStatus']
    }

    if (onsiteContact) {
      changedValues.tenantContact = {
        ...onsiteContact,
        typeOfContact: 'onsite',
      }
    }

    if (changedValues.taxRegistration) {
      const { taxRegistration, taxNumber } = values
      changedValues.taxNumber = ['joint_elected', 'registered'].includes(taxRegistration) ? taxNumber : null
    }

    if (changedValues.chargerSetting) {
      changedValues.chargerSetting = values.chargerSetting ?? 'multi_unit_residential'
    }
    setLoading(true)
    onSave({ ...changedValues, status: status })
    message.success(tenantUpdatedText)
    setLoading(false)
    setDisabled(true)
  }

  return {
    loading,
    initialValues,
    form,
    disabled,
    editTenantStatusModal,
    onsiteContact,
    editAccountDetailModal,
    masterAccount,
    remittanceEmails,
    remittanceFrequency,
    status,
    setOnsiteContact,
    setEditTenantStatusModal,
    updateTenantOverview,
    setMasterAccount,
    setEditAccountDetailModal,
    setDisabled,
    setStatus,
    setRemittanceEmails,
    setRemittanceFrequency,
  }
}
