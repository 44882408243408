import _ from 'lodash'
import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockBilling } from '../../mock/billing'
import {
  mockTenantById,
  mockTenantContact,
  mockTenants,
  mockTenantsPermission,
  mockTenantsResponse,
  updateTenantBasedOnPayload,
} from '../../mock/tenant-mock'
import { Billing } from '../../models/billing'
import { ActivityTrackingFilter, TenantFilter } from '../../models/filter'
import { ActivityTrackingResponse, TenantResponse } from '../../models/http'
import {
  ChargerTroubleShootPermission,
  Tenant,
  TenantContactInfo,
  TenantDeployment,
  TenantPayload,
  TenantRef,
  TenantUniformPricing,
} from '../../models/tenant'
import { AugmentedUser, toAugmentedUser, User, UserRef } from '../../models/user'
import CaseConverter from './case-converter'
import { apiClient } from './client'
import { mockTenantsRef, mockUserResponse, returnMock } from './mock'
import { tenantQuery } from 'helpers/filter/TenantFilter'
import { mockActivityTrackingResponse } from 'mock/activity-tracking-mock'
import { activityTrackingQuery } from 'helpers/query/ActivityTrackingQuery'

export async function GetTenants(filter: TenantFilter): Promise<TenantResponse> {
  let url = '/tenants'

  const queryParams = tenantQuery(filter)

  url = addQueryString(url, queryParams)

  log('fetching tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockTenantsResponse(filter))
  }
  return apiClient(url, {})
}

export async function GetTenant(tenantId: number): Promise<Tenant> {
  let url = `/tenants/${tenantId}`

  log('fetching tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantById[tenantId])
  }
  return apiClient(url, {})
}

export async function CreateTenant(tenant: Tenant): Promise<Tenant> {
  let url = `/tenants`

  log('creating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  const snakeCasedTenantModel = CaseConverter.camelToSnakeCase(tenant)
  const newKeys = { location: 'location_attributes', weekly_schedules: 'weekly_schedules_attributes' }
  const updatedTenantModel = CaseConverter.renameKeys(snakeCasedTenantModel, newKeys)
  delete updatedTenantModel.id

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify(updatedTenantModel),
  })
}

export async function UpdateTenant(tenant: TenantPayload): Promise<Tenant> {
  let url = `/tenants/${tenant.id}`
  log('updating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(updateTenantBasedOnPayload(tenant))
  }

  let updatedTenant: any = { ...tenant }

  updatedTenant = {
    ...updatedTenant,
    revSharePartnerTaggingId: tenant?.revSharePartner === null ? null : tenant?.revSharePartner?.id,
    revSharePartner: undefined,
  }

  const snakeCasedTenantModel = CaseConverter.camelToSnakeCase(updatedTenant)
  const newKeys = {
    location: 'location_attributes',
    weekly_schedules: 'weekly_schedules_attributes',
    tenant_contacts: 'tenant_contacts_attributes',
  }
  const updatedTenantModel = CaseConverter.renameKeys(snakeCasedTenantModel, newKeys)
  delete updatedTenantModel.id

  delete updatedTenantModel.updated_at
  //partner_ids need to be an array for backend to accept - didnt change camelToSnakeCase to not affect other values
  if (tenant.partnerIds) {
    updatedTenantModel.partner_ids = tenant.partnerIds
  }

  return apiClient<Tenant>(url, {
    method: 'PUT',
    body: JSON.stringify(updatedTenantModel),
  })
}

export async function AuthorizeUser(tenantIds: string[], user: UserRef): Promise<AugmentedUser[]> {
  let url = `/tenants/authorize`

  log('fetching tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    if (configProvider.config.mockEnabled) {
      return Promise.resolve(mockUserResponse['data'].map((u) => toAugmentedUser(u)))
    }
  }

  return apiClient<User[]>(url, {
    method: 'POST',
    body: JSON.stringify({
      tenant_id: `${tenantIds.join(',')}`,
      user_id: user.id,
    }),
  }).then((users) => users.map((u) => toAugmentedUser(u)))
}

export async function GetTenantContacts(tenantId: string): Promise<TenantContactInfo[]> {
  let url = `/tenants/${tenantId}/tenant_contacts`

  log('updating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantContact)
  }

  return apiClient(url, {})
}

export async function UpdateTenantContacts(tenantId: string, tenantContactInfo: TenantContactInfo[]): Promise<Tenant> {
  const url = `/tenants/${tenantId}/tenant_contacts`

  const snakeCasedTenantModel = tenantContactInfo.map((contact) => {
    return CaseConverter.camelToSnakeCase(contact)
  })

  log('updating tenant contacts', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify({
      tenant_contact: [...snakeCasedTenantModel],
    }),
  })
}

export async function UpdateTenantDeployment(tenantId: string, tenantDeployment: TenantDeployment): Promise<Tenant> {
  let url = `/tenants/${tenantId}/tenant_deployment`

  log('updating tenant', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  const snakeCasedTenantModel = CaseConverter.camelToSnakeCase(tenantDeployment)

  if (tenantDeployment.parkingStalls) {
    snakeCasedTenantModel.parking_stalls = [...Object.values(tenantDeployment.parkingStalls)]
  }
  if (tenantDeployment.loadManagementConfiguration) {
    snakeCasedTenantModel.load_management_configuration = [
      ...Object.values(tenantDeployment.loadManagementConfiguration!),
    ]
  }
  if (tenantDeployment.remoteDesktopIds) {
    snakeCasedTenantModel.remote_desktop_ids = [...Object.values(tenantDeployment.remoteDesktopIds)]
  }

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify({ tenant_deployment: { ...snakeCasedTenantModel } }),
  })
}

export async function UpdateTenantUniformPricing(
  tenantId: string,
  tenantUniformPricing: TenantUniformPricing,
): Promise<Tenant> {
  let url = `/tenants/${tenantId}/tenant_uniform_pricing`

  log('updating tenant uniform pricing', { url: url })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenants[0])
  }

  // remove any null or undefined properties
  const cleandTenantUniformPricingObj = _.omitBy(tenantUniformPricing, _.isNil)

  const snakeCasedUniformPricingModel = CaseConverter.camelToSnakeCase(cleandTenantUniformPricingObj)
  if (snakeCasedUniformPricingModel.hasOwnProperty('tenant_listing_authors')) {
    delete snakeCasedUniformPricingModel.tenant_listing_authors
  }
  if (snakeCasedUniformPricingModel.hasOwnProperty('tenant_listing_stripe_accounts')) {
    delete snakeCasedUniformPricingModel.tenant_listing_stripe_accounts
  }

  snakeCasedUniformPricingModel.time_of_day_price_weekday = [
    ...Object.values(tenantUniformPricing.timeOfDayPriceWeekday),
  ].map((num) => {
    return Number((num * 100).toFixed(2))
  })
  snakeCasedUniformPricingModel.time_of_day_price_weekend = [
    ...Object.values(tenantUniformPricing.timeOfDayPriceWeekend),
  ].map((num) => {
    return Number((num * 100).toFixed(2))
  })

  return apiClient<Tenant>(url, {
    method: 'POST',
    body: JSON.stringify({ tenant_uniform_pricing: { ...snakeCasedUniformPricingModel } }),
  })
}

export async function FindTenants(term?: string): Promise<TenantRef[]> {
  let url = '/tenants/find'

  if (term) {
    let queryParams: { [key: string]: string } = {
      term: `${term}`,
    }
    url = addQueryString(url, queryParams)
  }

  log('fetching tenants', { url: url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantsRef)
  }
  return apiClient(url, {})
}

export async function GetTenantBilling(tenantId: string): Promise<Billing> {
  const url = `/tenants/${tenantId}/billing`
  log('Fetching tenant billing', { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockBilling)
  }
  return apiClient(url, {})
}

export async function UpdateTenantBilling(tenantId: string, billing: Billing): Promise<Billing> {
  const url = `/tenants/${tenantId}/update_billing`
  log('update tenant billing info', { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockBilling)
  }
  return apiClient(url, {
    method: 'PUT',
    body: JSON.stringify({
      billing: {
        tenant_id: tenantId,
        contact_name: billing.contactName,
        email: billing.email,
        phone: billing.phone,
        notes: billing.notes,
        remittance_type_id: billing.remittanceType?.id || null,
      },
    }),
  })
}

export async function GetChargerTroubleShootPermission(tenantId: string): Promise<ChargerTroubleShootPermission> {
  const url = `/tenants/${tenantId}/permissions`
  log('Fetching tenant permissions', { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockTenantsPermission)
  }
  return apiClient(url, {})
}

export async function GetTenantActivityTracking(
  tenantId: string,
  startDate: moment.Moment,
  endDate: moment.Moment,
  filter: ActivityTrackingFilter,
): Promise<ActivityTrackingResponse> {
  let url = `/tenants/${tenantId}/activity_tracking`

  const queryParams = activityTrackingQuery(filter, startDate, endDate)

  url = addQueryString(url, queryParams)

  log('Fetching tenant activity tracking', { url })
  if (configProvider.config.mockEnabled) {
    return returnMock(mockActivityTrackingResponse(startDate, endDate, filter))
  }
  return apiClient(url, {})
}
