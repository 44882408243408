import { PeakShavingEventFilter, PeakShavingEventLogsFilter, PeakShavingProgramFilter } from '../../models/filter'

export const peakShavingEventQuery = (f: PeakShavingEventFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}
  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.programId) {
    queryParams['program_id'] = `${f.programId}`
  }

  if (f.programName) {
    queryParams['q'] = `${f.programName}`
  }

  if (f.state) {
    queryParams['state'] = `${f.state}`
  }

  if (f.frequency) {
    queryParams['frequency'] = `${f.frequency}`
  }

  return queryParams
}

export const peakShavingEventLogQuery = (f: PeakShavingEventLogsFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}

  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.eventDateOrder) {
    queryParams['event_date_order'] = `${f.eventDateOrder}`
  }

  return queryParams
}

export const peakShavingProgramQuery = (f: PeakShavingProgramFilter): { [key: string]: string } => {
  let queryParams: { [key: string]: string } = {}

  if (f.page) {
    queryParams['page'] = `${f.page}`
  }

  if (f.perPage) {
    queryParams['per_page'] = `${f.perPage}`
  }

  if (f.state) {
    queryParams['state'] = `${f.state}`
  }

  if (f.programId) {
    queryParams['program_id'] = `${f.programId}`
  }

  if (f.programName) {
    queryParams['q'] = `${f.programName}`
  }

  if (f.aggregatorName) {
    queryParams['aggregator_name'] = `${f.aggregatorName}`
  }

  return queryParams
}
