import { debounce } from 'lodash'
import { Checkbox, Select } from 'antd'
import { Dispatch, ReactElement, SetStateAction, useMemo, useRef } from 'react'

import { useTransactions } from './useTransactions'
import { useAppState } from 'state'
import { SearchUsers } from 'services/data-provider/users'
import { FindTenants } from 'services/data-provider/tenants'
import { FindListings, SearchListingIds } from 'services/data-provider/listing'
import { GetChargers } from 'services/data-provider/charger'
import { FindPartners } from 'services/data-provider/partners'

import { useSelectorTranslation } from './translation/useSelectorTranslation'

import { ActivityTrackingFilter, ChargersPerTenantFilter, TransactionFilter } from 'models/filter'
import { PartnerRef } from 'models/partner'
import { NewListing } from 'models/listing'
import { FindAggregator, FindParticipants } from 'services/data-provider/peak-shaving'
import { GetDiscounts } from 'services/data-provider/discount'
import { renderFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'
import { FindMasterAccount } from 'services/data-provider/master-account'
import { ChargerRef } from 'models/charger'

export const useSelector = () => {
  const { tenants } = useTransactions()
  const { selectedTenant } = useAppState()
  const fetchRef = useRef(0)
  const { serialNumberSelectedText, tenantSelectedText } = useSelectorTranslation()

  const aggregatorNameDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindAggregator(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues((prev: any) => [...prev, ...newOptions.data])
          setValueOptions((prev: any) => [...prev, ...newOptions.data])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindTenants, 800])

  const multiPartnerDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindPartners(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          //To match response from find partners with get tenant
          const modifiedOptions = newOptions.data.map((partner) => ({
            id: partner.partnerId,
            name: partner.partnerName,
          }))
          setValues((prev: any) => [...prev, ...modifiedOptions])
          setValueOptions((prev: any) => [...prev, ...modifiedOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindPartners, 800])

  const multiParticipantsDebounceFetcher = (programId: number) => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 0 && programId !== undefined) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindParticipants(value, programId).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          const filteredOptions = newOptions
          setValues(filteredOptions)
          setValueOptions((prev: any) => [...prev, ...filteredOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }

  const multiDiscountDebounceFetcher = (tenantId: number) => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 0 && tenantId) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        GetDiscounts(tenantId).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          const filteredOptions = newOptions.filter((val) => val.name.toLowerCase().includes(value))
          setValues(filteredOptions)
          setValueOptions((prev: any) => [...prev, ...filteredOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }

  const multiTenantDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindTenants(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindTenants, 800])

  const multiMasterAccountDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindMasterAccount(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindMasterAccount, 800])

  const multiListingTitleDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindListings(value, selectedTenant, tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const multiListingIdDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 1) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        SearchListingIds(value, selectedTenant, tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev: any) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }
    return debounce(loadOptions, 800)
  }, [SearchListingIds, 800])

  const multiSerialNumberSelectorDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        GetChargers(value, selectedTenant, tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [GetChargers, 800])

  const multiListingTitleAndIdDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
      defaultTenants?: any[],
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindListings(value, undefined, defaultTenants || tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const multiUsersDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        SearchUsers(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues(newOptions)
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [SearchUsers, 800])

  const multiListingDebounceFetcher = useMemo(() => {
    const loadOptions = (
      value: string,
      setValues: Dispatch<SetStateAction<any[]>>,
      setFetching: (fetching: boolean) => void,
      setValueOptions: Dispatch<SetStateAction<any[]>>,
    ) => {
      if (value.length >= 2) {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setValues([])
        setFetching(true)

        FindListings(value, selectedTenant, tenants).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return
          }
          setValues((prev: any) => [...prev, ...newOptions])
          setValueOptions((prev) => [...prev, ...newOptions])
          setFetching(false)
        })
      }
    }

    return debounce(loadOptions, 800)
  }, [FindListings, 800])

  const multiUsersHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectUsers: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedUsers = options.filter((t) => value.includes(t.name || t.email))
    const removedDuplicatedOfUsers = selectedUsers.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfUsers)
    onOptionsChange(selectedUsers)
  }

  const multiPartnerHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedPartner: any[]) => void,
    onOptionsChange: (selectedPartner: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedPartner = options.filter((t) => value.includes(t.id))
    const removedDuplicatedOfTenants = selectedPartner.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }

  const multiTaggingHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedPartner: any[]) => void,
    onOptionsChange: (selectedPartner: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedPartner = options.filter((t) => value.includes(t.id))
    const removedDuplicatedOfTenants = selectedPartner.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }

  const multiParticipantsHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedParticipants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedParticipants = options.filter((t) => value.includes(t.participantRef))
    const removedDuplicatedOfTenants = selectedParticipants.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }
  const multiDiscountHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedTenants = options.filter((t) => value.includes(t.name))
    const removedDuplicatedOfTenants = selectedTenants.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(removedDuplicatedOfTenants)
  }

  const multiTenantHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedTenants = options.filter((t) => value.includes(t.combineName || t.displayName || t.name))
    const removedDuplicatedOfTenants = selectedTenants.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfTenants)
    onOptionsChange(selectedTenants)
  }

  const multiMasterAccountHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedMasterAccounts: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedMasterAccounts = options.filter((t) => value.includes(t.name))

    const removedDuplicatedOfMasterAccounts = selectedMasterAccounts.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )

    setSelectedOptions(removedDuplicatedOfMasterAccounts)
    onOptionsChange(selectedMasterAccounts)
  }

  const multiListingTitleHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedListingsArr = options.filter((listing) => value.includes(listing.title))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    onOptionsChange(value)
  }

  const multiListingIdHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    setSelectedOptions(value)
    onOptionsChange(value)
  }

  const multiListingHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedListings: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedListings = options.filter((t) => value.includes(t.id || t.listingTitle))
    const removedDuplicatedOfListings = selectedListings.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    onOptionsChange(selectedListings)
  }

  const startMethodHandleChange = (
    value: any,
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    onOptionsChange(value)
  }

  const stateHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    onOptionsChange(value)
  }

  const multiSerialNumberHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    const selectedListingsArr = options.filter((option) => value.includes(option.chargePointSerialNumber))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    setDirty(true)
    onOptionsChange(selectedListingsArr)
  }
  const multiSerialNumberIdHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    const selectedListingsArr = options.filter((option) => value.includes(option.id))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    setDirty(true)
    onOptionsChange(selectedListingsArr)
  }

  const multiListingTitleAndIdHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setDirty(true)
    const selectedListingsArr = options.filter((listing) => value.includes(listing.id))
    const removedDuplicatedOfListings = selectedListingsArr.filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    )
    setSelectedOptions(removedDuplicatedOfListings)
    onOptionsChange(selectedListingsArr)
  }

  const multiServiceStatusHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    setDirty(true)
    onOptionsChange(value)
  }

  const multiVendorsHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    setDirty(true)
    onOptionsChange(value)
  }

  const multiOCPPStatusHandleChange = (
    value: any[],
    setDirty: (dirty: boolean) => void,
    setSelectedOptions: (selectedOption: any[]) => void,
    onOptionsChange: (selectedTenants: any[]) => void,
    options: any[],
  ) => {
    setSelectedOptions(value)
    setDirty(true)
    onOptionsChange(value)
  }

  const multiUsersHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].name : `${selected} Users Selected`
  }

  const multiPartnerHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].name : multiPartnerSelectedPlaceholder(selected)
  }

  const multiTaggingHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].tagName : multiTaggingSelectedPlaceholder(selected)
  }

  const multiPartnerSelectedPlaceholder = (selected: number) => `${selected} Partners Selected`

  const multiTaggingSelectedPlaceholder = (selected: number) => `${selected} Tags Selected`

  const multiListingTitleHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].title : multiListingTitleSelectedPlaceholder(selected)
  }
  const multiListingHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].title : multiListingTitleSelectedPlaceholder(selected)
  }
  const multiListingPreloadedHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].label : multiListingTitleSelectedPlaceholder(selected)
  }
  const multiListingIdHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0] : multiListingIdSelectedPlaceholder(selected)
  }
  const multiListingIdSelectedPlaceholder = (selected: any) => `${selected} Listing Ids Selected`
  const multiListingTitleSelectedPlaceholder = (selected: number) => `${selected} Listing Titles Selected`

  const multiParticipantsPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    const resultArray = selectedOptions.map((participant) => {
      return participant.participantRef
    })
    return selected === 1 ? resultArray : `-1 Participants Selected`.replace('-1', `${selected}`)
  }

  const multiDiscountPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    const resultArray = selectedOptions.map((discount) => {
      return discount.name
    })
    return selected === 1 ? resultArray : `-1 Discount Plans Selected`.replace('-1', `${selected}`)
  }

  const multiTenantHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    const resultArray = selectedOptions.map((tenant) => {
      // Use regular expression to capture text before open brackets or text inside brackets
      const match = tenant?.combineName?.match(/([^(]*)\s*\(([^)]*)\)/)

      if (match) {
        const [, textBeforeBrackets, textInsideBrackets] = match
        return textBeforeBrackets.trim() || textInsideBrackets.trim()
      }

      return tenant.combineName
    })

    return selected === 1 ? resultArray : tenantSelectedText.replace('-1', `${selected}`)
  }

  const multiMasterAccountHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].name : `${selected} Master Accounts Selected`
  }

  const multiSerialNumberHandlePlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0].chargePointSerialNumber : `${selected} ${serialNumberSelectedText}`
  }

  const multiServiceStatusPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1
      ? renderFormatMessage(renderConnectionMessage(selectedOptions[0]), selectedOptions[0])
      : `${selectedOptions.length} Statuses Selected`
  }

  const multiVendorPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1 ? selectedOptions[0] : `${selectedOptions.length} Vendors Selected`
  }

  const multiOCPPStatusPlaceholder = (selectedOptions: any[]) => {
    const selected = selectedOptions.length
    return selected === 1
      ? renderFormatMessage(renderConnectionMessage(selectedOptions[0]), selectedOptions[0])
      : `${selectedOptions.length} Select OCPP Statuses Selected`
  }

  const multiUsersHandleValue = (filter: ActivityTrackingFilter | undefined) => {
    return filter?.users?.map((t: any) => t.name)
  }

  const multiPartnerHandleValue = (selectedValues: PartnerRef[]) => {
    return selectedValues.map((value: PartnerRef) => value.id)
  }
  const multiTenantHandleValue = (filter: TransactionFilter | undefined) => {
    return filter?.tenants?.map((t) => t.combineName || t.displayName || t.name)
  }
  const multiMasterAccountHandleValue = (filter: any | undefined) => {
    return filter?.masterAccounts?.map((t: any) => t.name)
  }

  const multiListingTitleHandleValue = (filter: TransactionFilter | undefined) => {
    return filter?.listingTitle
  }

  const multiListingHandleValue = (filter: ActivityTrackingFilter | undefined) => {
    return filter?.listings.map((listing) => listing.id)
  }

  const startMethodHandleValue = (filter: TransactionFilter | undefined) => {
    return filter?.startMethod
  }

  const stateHandleValue = (filter: TransactionFilter | undefined) => {
    return filter?.state
  }

  const multiSerialNumberHandleValue = (filter: TransactionFilter | ChargersPerTenantFilter | undefined) => {
    return filter?.chargers
  }

  const renderMultiUsersOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={option.id} value={option.name} label={option.name}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((listing) => listing.name === option.name) > -1}
          className="ant-checkbox-position"
          style={{ top: 10 }}
        >
          <div className="flex-and-gap-below">
            <span>{option.name}</span>
            <span className="paragraph-04-light opacity-06">{option.email}</span>
          </div>
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiListingTitleOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={option.id} value={option.title} label={option.title}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
        >
          {option.title}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiListingOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const final = selectedOptions.findIndex((listing) => listing.id === option.id) > -1
    return (
      <Select.Option key={option.id} value={option.id} label={option.title}>
        <Checkbox onClick={handleCheckboxClick} checked={final}>
          {option.title}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiVendorOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option} value={option} label={option}>
      <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
        {option}
      </Checkbox>
    </Select.Option>
  )

  const renderChargerSerialNumberOption = (
    charger: ChargerRef,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={charger.id} value={charger.id} label={charger.chargePointSerialNumber}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.id === charger.id) > -1}
      >
        {charger.chargePointSerialNumber}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiListingIdOption = (
    option: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={option.id} value={option.id} label={option.title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((listing) => listing.title === option.title) > -1}
      >
        {option.title}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiTenantOption = (
    { id, displayName, name: ocppName, combineName }: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={combineName} label={displayName ? displayName : ocppName}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.combineName === combineName) > -1}
        >
          {displayName && (displayName !== '' || displayName !== null) ? displayName : ocppName}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderOCPPStatusOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiMasterAccountOption = (
    { id, name }: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={name} label={name}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedMasterAccounts) => selectedMasterAccounts.name === name) > -1}
        >
          {name}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderServiceStatusOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }

  const multiListingTitleAndIdHandleValue = (selectedValues: NewListing[]) => {
    return selectedValues.map((value: NewListing) => value.id)
  }

  return {
    multiTenantDebounceFetcher,
    multiListingTitleDebounceFetcher,
    multiSerialNumberSelectorDebounceFetcher,
    multiListingTitleAndIdDebounceFetcher,
    multiPartnerDebounceFetcher,
    multiUsersDebounceFetcher,
    multiListingDebounceFetcher,
    multiListingIdDebounceFetcher,
    multiMasterAccountDebounceFetcher,
    multiTenantHandleChange,
    multiListingHandleChange,
    multiListingTitleHandleChange,
    multiListingHandlePlaceholder,
    multiListingTitleHandlePlaceholder,
    multiTenantHandlePlaceholder,
    multiUsersHandlePlaceholder,
    multiMasterAccountHandlePlaceholder,
    multiPartnerHandleValue,
    multiTenantHandleValue,
    multiMasterAccountHandleValue,
    multiListingHandleValue,
    multiListingTitleHandleValue,
    multiUsersHandleValue,
    multiSerialNumberHandleChange,
    multiSerialNumberIdHandleChange,
    multiMasterAccountHandleChange,
    startMethodHandleValue,
    stateHandleValue,
    multiSerialNumberHandleValue,
    multiSerialNumberHandlePlaceholder,
    multiListingPreloadedHandlePlaceholder,
    startMethodHandleChange,
    stateHandleChange,
    multiListingTitleAndIdHandleChange,
    multiPartnerHandlePlaceholder,
    multiPartnerHandleChange,
    multiUsersHandleChange,
    renderMultiUsersOption,
    renderMultiListingTitleOption,
    renderMultiListingOption,
    renderMultiVendorOption,
    renderChargerSerialNumberOption,
    renderMultiListingIdOption,
    renderMultiTenantOption,
    renderOCPPStatusOption,
    renderMultiMasterAccountOption,
    renderServiceStatusOption,
    multiListingTitleAndIdHandleValue,
    aggregatorNameDebounceFetcher,
    multiDiscountDebounceFetcher,
    multiDiscountHandleChange,
    multiDiscountPlaceholder,
    multiParticipantsDebounceFetcher,
    multiParticipantsHandleChange,
    multiParticipantsPlaceholder,
    multiListingIdHandlePlaceholder,
    multiListingIdHandleChange,
    multiServiceStatusPlaceholder,
    multiServiceStatusHandleChange,
    multiVendorPlaceholder,
    multiOCPPStatusPlaceholder,
    multiVendorsHandleChange,
    multiOCPPStatusHandleChange,

    multiTaggingHandlePlaceholder,
    multiTaggingHandleChange,
  }
}
