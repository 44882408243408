import { ConfigProvider, Dropdown, Empty, Menu, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { cancelEventSVG } from '../../../assets/svg/cancel-event'
import { DotsSVG } from '../../../assets/svg/dots'
import { duplicateSVG } from '../../../assets/svg/duplicate'
import { peakShavingInfoSVG } from '../../../assets/svg/peak-shaving-info-icon'
import { SortDownIcon, SortUpIcon } from '../../../assets/svg/sort'
import { AlertError } from '../../../components/error'
import EventStatusBadge from '../../../components/peakShaving/peak-shaving-status'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { PeakShavingEventLogsFilter } from '../../../models/filter'
import { PaginationMeta } from '../../../models/pagination'
import {
  PeakShavingEvent,
  PeakShavingEventState,
  PeakShavingParticipant,
  PeakShavingProgram,
  PeakShavingScheduleFrequency,
} from '../../../models/peak-shaving'
import { GetAllPeakShavingEventLogs } from '../../../services/data-provider/peak-shaving'
import { styled, theme } from '../../../theme'

interface props {
  program: PeakShavingProgram
  onClickId: (id: number, frequency: PeakShavingScheduleFrequency) => void
  onClickDuplicateEvent: (id: number, frequency: PeakShavingScheduleFrequency) => void
  onClickCancelEvent: (id: number) => void
  term: string
}

const EventDetailsContainer = styled.div<{ isEmpty: boolean }>`
  .edit-event-icon {
    rotate: 0deg;
    transition: all 500ms ease;
    cursor: pointer;
  }

  .ant-dropdown-trigger.ant-dropdown-open .edit-event-icon {
    rotate: 90deg;
  }

  .ant-dropdown-trigger.ant-dropdown-open svg rect {
    stroke: ${(props) => props.theme.colors.buttonPrimary};
  }

  .ant-table-tbody .ant-table-cell:first-of-type p {
    color: ${(props) => props.theme.colors.buttonPrimary};
  }

  .ant-empty {
    /* min-height: 129px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }

  .ant-empty-image {
    height: auto;
    margin-bottom: 0;
  }

  .ant-table-tbody > tr > td {
    padding: 3.5px 16px;
    height: 68.75px;

    & > p {
      margin-bottom: 0;
    }
  }

  .ant-table-tbody > tr > td:last-of-type {
    display: ${(props) => (props.isEmpty ? '' : 'flex')};
    justify-content: center;
    align-items: center;
  }

  .paragraph-02-regular.date,
  .paragraph-02-regular.notes,
  .paragraph-02-regular.source {
    line-height: 19px;
    margin-bottom: 0;
  }

  .ant-table-empty {
    &.ant-table-cell {
      border-bottom: 0;
    }
  }

  .updatedby.flex-and-gap-below {
    & p {
      margin-bottom: 0;
    }
  }

  table {
    min-height: ${(props) => (props.isEmpty ? '179px' : 'auto')};
  }

  .adminUndefined {
    color: #c37500;
  }

  th[aria-sort='ascending'] {
    & svg.upArrow path {
      stroke: ${(props) => props.theme.colors.neonGreen};
    }
    & svg.downArrow path {
      stroke: ${(props) => props.theme.colors.softBlack};
    }
  }
  .eventDate {
    display: flex;
    justifycontent: space-between;
    alignitems: center;
    position: relative;
    & svg {
      cursor: pointer;
    }

    &.desc {
      & svg.downArrow path {
        stroke: ${(props) => props.theme.colors.neonGreen};
      }
      & svg.upArrow path {
        stroke: ${(props) => props.theme.colors.softBlack};
      }
    }
    &.asc {
      & svg.downArrow path {
        stroke: ${(props) => props.theme.colors.softBlack};
      }
      & svg.upArrow path {
        stroke: ${(props) => props.theme.colors.neonGreen};
      }
    }
  }
`

const SorterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
`

export const EventsTable: React.FC<props> = ({
  program,
  onClickId,
  onClickDuplicateEvent,
  onClickCancelEvent,
  term,
}) => {
  const [filter, setFilter] = useState<PeakShavingEventLogsFilter>({
    page: 1,
    perPage: 20,
    eventDateOrder: 'desc',
  })
  const [events, setEvents] = useState<PeakShavingEvent[]>([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>()
  const [error, setError] = useState()

  const {
    idText,
    allowOptOutText,
    updatedByText,
    notestText,
    eventDateText,
    eventStatusText,
    duplicateEventText,
    cancelEventText,
    eventsTableinfoText,
    yesText,
    noText,
    participantText,
  } = usePeakShavingTranslation()

  const eventsColumns = [
    {
      title: idText,
      width: '8%',
      render: (event: PeakShavingEvent) => {
        const { id: eventId, frequency } = event
        return (
          <p
            onClick={() => {
              onClickId(eventId, frequency)
            }}
            style={{ cursor: 'pointer' }}
            className="paragraph-02-regular id"
          >
            {eventId}
          </p>
        )
      },
    },
    {
      title: allowOptOutText,
      width: '9%',
      dataIndex: 'allowOptOut',
      render: (allowOptOut: boolean) => {
        return allowOptOut ? (
          <p className="paragraph-02-regular">{yesText}</p>
        ) : (
          <p className="paragraph-02-regular">{noText}</p>
        )
      },
    },
    {
      title: participantText,
      width: '9%',
      dataIndex: 'participant',
      render: (participant: PeakShavingParticipant) => {
        return <p className="paragraph-02-regular">{participant.participantRef}</p>
      },
    },
    {
      title: updatedByText,
      width: '16%',
      render: (event: PeakShavingEvent) => {
        const { adminPerson, modificationDateTime } = event
        return (
          <div className="updatedby flex-and-gap-below">
            <p className={`paragraph-02-regular ${adminPerson ? '' : 'adminUndefined'}`}>
              {adminPerson ? adminPerson.name : '...'}
            </p>
            <p className="paragraph-04-light" style={{ opacity: 0.6, color: theme.colors.softBlack }}>
              {moment(modificationDateTime).format('MMMM DD, Y')}
            </p>
          </div>
        )
      },
    },
    {
      title: (
        <div className="eventDate">
          {eventDateText}
          <SorterContainer>
            <SortUpIcon
              className="upArrow"
              onClick={() => {
                document.querySelector('.eventDate')?.classList.remove('desc')
                document.querySelector('.eventDate')?.classList.add('asc')
                setFilter({ ...filter, eventDateOrder: 'asc' })
              }}
            />
            <SortDownIcon
              className="downArrow"
              onClick={() => {
                document.querySelector('.eventDate')?.classList.remove('asc')
                document.querySelector('.eventDate')?.classList.add('desc')
                setFilter({ ...filter, eventDateOrder: 'desc' })
              }}
              style={{ marginTop: '4px', marginLeft: '0.7px' }}
            />
          </SorterContainer>
        </div>
      ),
      width: '14%',
      render: (obj: PeakShavingEvent) => {
        return <p className="paragraph-02-regular date">{obj.eventDate}</p>
      },
    },
    {
      title: notestText,
      dataIndex: 'notes',
      width: '26%',
      render: (notes: string) => {
        return <p className="paragraph-02-regular notes">{notes}</p>
      },
    },
    {
      title: 'System Generated',
      width: '11%',
      dataIndex: 'frequency',
      render: (source: PeakShavingScheduleFrequency) => {
        return <p className="source paragraph-02-regular">{source === 'manual' ? 'Manual' : 'Daily'}</p>
      },
    },
    {
      title: eventStatusText,
      width: '9%',
      dataIndex: 'state',
      render: (eventState: PeakShavingEventState) => {
        return <EventStatusBadge state={eventState.toLowerCase()} />
      },
    },
    {
      title: '',
      render: (event: PeakShavingEvent) => {
        const { id: eventId, frequency } = event

        return (
          <>
            {program.state !== 'archived' && (
              <Dropdown
                overlay={
                  <Menu className="edit-event-dropdown">
                    <Menu.Item
                      key="duplicateEvent"
                      icon={duplicateSVG}
                      onClick={() => onClickDuplicateEvent(eventId, frequency)}
                    >
                      <p className="paragraph-02-regular">{duplicateEventText}</p>
                    </Menu.Item>
                    <Menu.Item
                      key="cancelEvent"
                      icon={cancelEventSVG}
                      onClick={() => onClickCancelEvent(eventId)}
                      disabled={event.state === 'cancelled'}
                    >
                      <p
                        className="paragraph-02-regular"
                        style={{ color: event.state === 'cancelled' ? theme.colors.darkGray : theme.colors.softBlack }}
                      >
                        {cancelEventText}
                      </p>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <span onClick={(e) => e.preventDefault()}>{<DotsSVG className="edit-event-icon" />}</span>
              </Dropdown>
            )}
          </>
        )
      },
    },
  ]

  useEffect(() => {
    setLoading(true)
    GetAllPeakShavingEventLogs(program.id, filter)
      .then(({ data, pagination }) => {
        setEvents(data)
        setPagination(pagination)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, [program, filter, term])

  const onPaginationChange = (page: number) => {
    setFilter({ ...filter, page })
  }

  return (
    <EventDetailsContainer isEmpty={events.length === 0}>
      <AlertError error={error} />
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={peakShavingInfoSVG}
            description={
              <p className="paragraph-04-regular" style={{ color: theme.colors.darkGray }}>
                {eventsTableinfoText}
              </p>
            }
          />
        )}
      >
        {program && (
          <Table
            dataSource={events}
            loading={loading}
            columns={eventsColumns}
            rowKey="id"
            pagination={{
              position: ['bottomCenter'],
              total: pagination?.totalEntries,
              current: pagination?.currentPage,
              pageSize: pagination?.perPage,
              showSizeChanger: false,
              onChange: onPaginationChange,
            }}
          />
        )}
      </ConfigProvider>
    </EventDetailsContainer>
  )
}
