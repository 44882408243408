import { Tooltip } from 'antd'

import { networkSVG } from '../../../assets/svg/network'
import ServiceStatusBadge from '../../../components/connectors/ServiceStatusBadge'
import { renderFormatMessage } from '../../../helpers/intl'
import { NewListing } from '../../../models/listing'

import ListingTitle from '../functions/listing-title'
import HandlePrice from '../functions/price'
import ListingTenant from '../functions/tenant'
import AccessPermission from '../functions/access-permission'
import ListingLoiteringStatus from '../functions/loitering'
import ListingChargerAndConnector from '../functions/charger'
import renderSmartCharging from '../../../helpers/smart-charging'
import { getConnectivityStatus, handleConnectivity } from '../functions/connectivity'

export const ListingColumns = [
  {
    title: <Tooltip title={renderFormatMessage('dashboard.text.connectivity', 'Connectivity')}>{networkSVG}</Tooltip>,
    key: 'connectivity',
    align: 'right' as 'right',
    render: (listing: NewListing) => (
      <Tooltip title={getConnectivityStatus(listing.connectivity)}>{handleConnectivity(listing.connectivity)}</Tooltip>
    ),
  },
  {
    title: renderFormatMessage('dashboard.createNewListing.listingStep.heading', 'Listing Title'),
    dataInex: 'title',
    render: (listing: NewListing) => <ListingTitle listing={listing} />,
  },
  {
    title: renderFormatMessage('dashboard.text.charger', 'Charger'),
    key: 'charger',
    render: (listing: NewListing) =>
      listing.charger || listing.connector ? (
        <ListingChargerAndConnector charger={listing.charger} connector={listing.connector} />
      ) : null,
  },
  {
    title: renderFormatMessage('dashboard.text.tenant', 'Site'),
    key: 'tenant',
    render: (listing: NewListing) => (listing.tenant ? <ListingTenant tenant={listing.tenant} /> : null),
  },
  {
    title: renderFormatMessage('dashboard.text.generalPrice', 'General Price'),
    key: 'pricePerUnitCents',
    render: (listing: NewListing) => <HandlePrice listing={listing} />,
  },
  {
    title: renderFormatMessage('dashboard.text.access', 'Access'),
    key: 'permission',
    render: (listing: NewListing) => <AccessPermission permission={listing.permission} />,
  },
  {
    title: renderFormatMessage('dashboard.text.loitering', 'Loitering'),
    key: 'loitering',
    render: (listing: NewListing) => <ListingLoiteringStatus listing={listing} />,
  },
  {
    title: renderFormatMessage('dashboard.text.smartCharging', 'Smart Charging'),
    key: 'smartCharging',
    render: (listing: NewListing) => renderSmartCharging(listing.smartCharging),
  },
  {
    title: renderFormatMessage('dashboard.selector.serviceStatus', 'Service Status'),
    key: 'serviceStatus',
    render: (listing: NewListing) =>
      listing.connector && listing.connector.serviceStatus ? (
        <ServiceStatusBadge state={listing.connector.serviceStatus} />
      ) : null,
  },
]
