import { useEffect, useState } from 'react'
import { Collapse, List, Pagination, Space } from 'antd'
import { Link } from 'react-router-dom'
import { Links } from '../../../components/routes/paths'
import { Connector } from '../../../models/connector'
import { useAppState } from '../../../state'
import { useChargerTranslation } from '../../../hooks/translation/useChargerTranslation'
import { useGeneralTranslation } from '../../../hooks/translation/useGeneralTranslation'
import { ChargerConnectorStateBadgeRedesign } from '../../../components/chargers/charger-connector-state-badge-redesign'
import ServiceStatusBadge from '../../../components/connectors/ServiceStatusBadge'
import { ChargerCollapseWrapper } from '../../../atom/collapse'
import { PaginationMeta } from '../../../models/pagination'

const { Panel } = Collapse

export interface props {
  connectors: Connector[]
  loading: boolean
  currentPage: number
  pagination: PaginationMeta | undefined
  handlePaginationChange: (page: number) => void
}

export const ChargersCollapseViewRedesign: React.FC<props> = ({
  connectors,
  pagination,
  currentPage,
  handlePaginationChange,
}) => {
  document.querySelector('body')?.classList.add('redesignActive')
  const [pageSize, setPageSize] = useState(40)
  const { currentUser } = useAppState()
  const { tenantText, statusText } = useGeneralTranslation()
  const {
    connectorIdText,
    vendorText,
    firmwareVersionText,
    chargerServiceStatusText,
    viewChargerText,
  } = useChargerTranslation()

  useEffect(() => {
    if (pagination) {
      setPageSize(pagination.perPage)
    }
  }, [pagination])

  return (
    <ChargerCollapseWrapper>
      <Collapse accordion>
        {connectors.map((connector) => (
          <Panel
            extra={
              <Link to={Links.charger({ chargerId: connector.charger?.id, connectorId: connector.id })}>
                {viewChargerText}
              </Link>
            }
            key={connector.id}
            header={
              <Space>
                {connector.listing?.title || 'No Listing'} -{connector.charger?.chargePointSerialNumber}
              </Space>
            }
          >
            <List
              dataSource={[connector]}
              renderItem={(connector) => (
                <>
                  <List.Item actions={[connector.connectorId]}>
                    <List.Item.Meta title={connectorIdText} />
                  </List.Item>
                  <List.Item actions={[connector.charger?.chargePointVendor]}>
                    <List.Item.Meta title={vendorText} />
                  </List.Item>
                  {currentUser?.role === 'admin' && (
                    <List.Item actions={[connector.charger?.firmwareVersion]}>
                      <List.Item.Meta title={firmwareVersionText} />
                    </List.Item>
                  )}
                  <List.Item actions={[connector.charger?.tenant?.displayName || connector.charger?.tenant?.name]}>
                    <List.Item.Meta title={tenantText} />
                  </List.Item>
                  <List.Item
                    actions={[connector.charger && <ServiceStatusBadge state={connector.charger.serviceStatus} />]}
                  >
                    <List.Item.Meta title={chargerServiceStatusText} />
                  </List.Item>
                  <List.Item actions={[<ChargerConnectorStateBadgeRedesign state={connector.state} />]}>
                    <List.Item.Meta title={statusText} />
                  </List.Item>
                </>
              )}
            ></List>
          </Panel>
        ))}
      </Collapse>
      <Pagination
        current={currentPage}
        onChange={handlePaginationChange}
        total={pagination?.totalEntries}
        pageSize={pageSize}
        showQuickJumper={false}
        showSizeChanger={false}
        showTitle={true}
        style={{ textAlign: 'center' }}
      />
    </ChargerCollapseWrapper>
  )
}
