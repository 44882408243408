import { Typography } from 'antd'

import { theme } from 'theme'

interface props {
  title: string
  subtitle: string
  ellipisLength?: number
}

const ellipsisValue = (value: string, length: number): JSX.Element | string => {
  if (value.length < length) {
    return value
  }
  return (
    <Typography.Text style={{ color: theme.colors.softBlack, width: 70 }} ellipsis={{ tooltip: value }}>
      {value}
    </Typography.Text>
  )
}

const TitleAndSubsection: React.FC<props> = ({ title, subtitle, ellipisLength }) => {
  return (
    <div className={title !== '' ? 'flex-and-gap-below' : ''}>
      <span>{title && ellipisLength ? ellipsisValue(title, ellipisLength) : null}</span>
      <span className="opacity-06 paragraph-04-light">{subtitle}</span>
    </div>
  )
}

export default TitleAndSubsection
