import { Space, Table, Typography } from 'antd'
import moment from 'moment'

import CoreBadge from 'components/badge/Badge'
import { Links } from 'components/routes/paths'
import { UnderlinedLink } from 'components/link/Link'
import ServiceStatusBadge from 'components/connectors/ServiceStatusBadge'

import { Tenant, TenantRef } from 'models/tenant'

import { useTenantTranslation } from 'hooks/translation/useTenantTranslation'
import { renderFormatMessage } from 'helpers/intl'
import { useAppState } from 'state'
import { buildRailsListingUrlWithId } from 'helpers/url'

export const TenantsColumns = () => {
  const { tenantsTableNameHeading, tenantsTableChargerCountHeading } = useTenantTranslation()
  const { language, currentUser } = useAppState()
  const columns = [
    {
      title: tenantsTableNameHeading,
      key: 'tenantName',
      render: (t: TenantRef) => (
        <UnderlinedLink to={Links.tenant({ tenantId: `${t.id}` })}>
          <Typography.Text className="hover-underline" ellipsis={{ tooltip: t.displayName || t.name || '-' }}>
            {t.displayName || t.name || '-'}
          </Typography.Text>
        </UnderlinedLink>
      ),
      width: '20%',
    },
    {
      title: renderFormatMessage('dashboard.tenantPage.tenantTabOverview.accountName', 'Account Name'),
      key: 'masterAccount',
      render: (t: Tenant) => t.masterAccount?.name,
      width: '15%',
    },
    {
      title: renderFormatMessage('dashboard.tenantPage.tenantTabOverview.tenantStatus', 'Site Status'),
      key: 'tenantStatus',
      render: (t: TenantRef) => (t.status ? <ServiceStatusBadge state={t.status} /> : null),
      width: language === 'en' ? '160px' : '200px', //Width that fits Direct Deposit Information status (longest status name)
    },
    {
      title: 'Last Updated',
      key: 'updatedAt',
      render: (t: Tenant) => {
        const originalDate = moment.tz(t.updatedAt, 'America/Toronto')

        // Format the date in the desired format
        const formattedDate = originalDate.format('YYYY/MM/DD HH:mm:ss')

        // Get the timezone offset in hours
        const timezoneOffsetHours = originalDate.utcOffset() / 60

        // Format the timezone offset
        const offset =
          timezoneOffsetHours < 0
            ? '-' + ('0' + Math.abs(timezoneOffsetHours)).slice(-2) + ':00'
            : '+' + ('0' + timezoneOffsetHours).slice(-2) + ':00'

        // Combine date, time, and offset into one string
        const finalDate = `${formattedDate} (${offset})`

        const [date, time] = finalDate.split(' ')

        return (
          <>
            <div>{date}</div>
            <div style={{ fontSize: '11px' }}>
              {time} ({offset})
            </div>
          </>
        )
      },
      width: '160px',
    },
    {
      title: tenantsTableChargerCountHeading,
      dataIndex: 'chargerCount',
      sorter: (a: TenantRef, b: TenantRef) => {
        const av = a.chargerCount ? a.chargerCount : 0
        const bv = b.chargerCount ? b.chargerCount : 0
        return av - bv
      },
      width: '160px',
    },
    {
      title: renderFormatMessage('dashboard.text.listingTitles', 'Listing Title (s)'),
      key: 'listingTitles',
      render: (tenant: Tenant) => (
        <Space wrap>
          {tenant.listingTitles?.map((listing) => (
            <CoreBadge
              title={listing.title}
              href={
                currentUser?.role !== 'admin'
                  ? buildRailsListingUrlWithId(listing.id, listing.title)
                  : Links.listing({ listingId: listing.id })
              }
            />
          ))}
        </Space>
      ),
    },
    Table.EXPAND_COLUMN,
  ]
  return columns
}
