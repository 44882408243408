import { theme } from '../../theme'

export const transactionSVG = (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.9 0.5H17.1C17.3387 0.5 17.5676 0.59365 17.7364 0.760349C17.9052 0.927048 18 1.15314 18 1.38889V15.6111C18 15.8469 17.9052 16.073 17.7364 16.2397C17.5676 16.4064 17.3387 16.5 17.1 16.5H0.9C0.661305 16.5 0.432387 16.4064 0.263604 16.2397C0.0948211 16.073 0 15.8469 0 15.6111V1.38889C0 1.15314 0.0948211 0.927048 0.263604 0.760349C0.432387 0.59365 0.661305 0.5 0.9 0.5ZM11.7 4.05556V5.83333H8.1V7.61111H11.7V9.38889L14.85 6.72222L11.7 4.05556ZM6.3 12.9444V11.1667H9.9V9.38889H6.3V7.61111L3.15 10.2778L6.3 12.9444Z"
      fill={theme.colors.primary}
    />
  </svg>
)

export const transactionWhiteSVG = (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.50033 0.5H16.5003C16.7213 0.5 16.9333 0.587797 17.0896 0.744078C17.2459 0.900358 17.3337 1.11232 17.3337 1.33333V14.6667C17.3337 14.8877 17.2459 15.0996 17.0896 15.2559C16.9333 15.4122 16.7213 15.5 16.5003 15.5H1.50033C1.27931 15.5 1.06735 15.4122 0.91107 15.2559C0.75479 15.0996 0.666992 14.8877 0.666992 14.6667V1.33333C0.666992 1.11232 0.75479 0.900358 0.91107 0.744078C1.06735 0.587797 1.27931 0.5 1.50033 0.5ZM11.5003 3.83333V5.5H8.16699V7.16667H11.5003V8.83333L14.417 6.33333L11.5003 3.83333ZM6.50032 12.1667V10.5H9.83366V8.83333H6.50032V7.16667L3.58366 9.66667L6.50032 12.1667Z"
      fill={theme.colors.white}
    />
  </svg>
)

export const transaction24x24SVG = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4082_35368)">
      <path
        d="M1.2 1.2002H22.8C23.1183 1.2002 23.4235 1.32662 23.6485 1.55167C23.8736 1.77671 24 2.08194 24 2.4002V21.6002C24 21.9185 23.8736 22.2237 23.6485 22.4487C23.4235 22.6738 23.1183 22.8002 22.8 22.8002H1.2C0.88174 22.8002 0.576515 22.6738 0.351472 22.4487C0.126428 22.2237 0 21.9185 0 21.6002V2.4002C0 2.08194 0.126428 1.77671 0.351472 1.55167C0.576515 1.32662 0.88174 1.2002 1.2 1.2002ZM15.6 6.0002V8.4002H10.8V10.8002H15.6V13.2002L19.8 9.6002L15.6 6.0002ZM8.4 18.0002V15.6002H13.2V13.2002H8.4V10.8002L4.2 14.4002L8.4 18.0002Z"
        fill={theme.colors.primary}
      />
    </g>
    <defs>
      <clipPath id="clip0_4082_35368">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
