import { theme } from '../../theme'

export const editSVG = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.02459 1.25404C8.36294 0.915522 8.91162 0.915292 9.25025 1.25352L10.7458 2.7473C11.0845 3.08567 11.0848 3.63462 10.7463 3.97327L4.59556 10.1276C4.47483 10.2484 4.3211 10.3308 4.15368 10.3646L1 11L1.63659 7.85014C1.67036 7.68308 1.75263 7.52967 1.87313 7.40911L8.02459 1.25404Z"
      stroke={theme.colors.primary}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const editGreySVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.25 10.875L7.4375 12.75M4.9375 10.875L11.0997 4.49762C11.7632 3.83413 12.8389 3.83413 13.5024 4.49762C14.1659 5.16111 14.1659 6.23684 13.5024 6.90033L7.125 13.0625L4 14L4.9375 10.875Z"
      stroke={theme.colors.darkGray}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const editIconSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.5 11.4805L7.125 13.7305M4.125 11.4805L11.5196 3.82761C12.3158 3.03142 13.6067 3.03142 14.4029 3.82761C15.199 4.6238 15.199 5.91467 14.4029 6.71086L6.75 14.1055L3 15.2305L4.125 11.4805Z"
      stroke={theme.colors.darkGray}
      stroke-width="2"
    />
  </svg>
)
export const editBlackSVG = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.8344 5.60565C15.3081 5.13173 16.0763 5.13141 16.5503 5.60493L18.6441 7.69622C19.1184 8.16994 19.1187 8.93846 18.6448 9.41258L10.0338 18.0287C9.86476 18.1978 9.64954 18.3132 9.41516 18.3604L5 19.25L5.89123 14.8402C5.9385 14.6063 6.05368 14.3915 6.22238 14.2228L14.8344 5.60565Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
