export const downloadSVG = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.96875 15.2197V18.9256C3.96875 19.4872 4.19 20.0259 4.58383 20.423C4.97765 20.8201 5.5118 21.0433 6.06875 21.0433H18.6687C19.2257 21.0433 19.7598 20.8201 20.1537 20.423C20.5475 20.0259 20.7687 19.4872 20.7687 18.9256V15.2197"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4109 2.95654L12.4109 14.9565M12.4109 14.9565L17.2109 10.3714M12.4109 14.9565L7.61099 10.3714"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
