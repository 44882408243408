import { Space, Table, Typography } from 'antd'
import moment from 'moment'

import { theme } from 'theme'
import { ActivityTracking, LabelValuePair } from 'models/activity-tracking'
import TitleAndSubsection from 'components/title/TitleAndSubsection'

export const ActivityTrackingColumns = () => {
  const renderValues = (values: LabelValuePair[]) => {
    if (values.length === 0) return '-'

    return (
      <Space wrap>
        {values.map((data) => (
          <Typography.Text
            key={data.label}
            style={{ color: theme.colors.softBlack, width: 275 }}
            ellipsis={{ tooltip: data.value }}
          >
            {data.label}: {data.value}
          </Typography.Text>
        ))}
      </Space>
    )
  }

  const columns = [
    { title: 'Activity', dataIndex: 'activity', width: '8%' },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      width: '10%',
      render: (timestamp: string) => {
        let modifiedTimestamp = moment(timestamp)
        const formatDate = 'YYYY/MM/DD'
        const formatTime = 'HH:mm:ss (Z)'

        return (
          <div className="flex-and-gap-below">
            <span>{modifiedTimestamp.format(formatDate)}</span>
            <span className="paragraph-04-light opacity-06" style={{ color: theme.colors.softBlack }}>
              {modifiedTimestamp.format(formatTime)}
            </span>
          </div>
        )
      },
    },
    { title: 'System Area', dataIndex: 'systemArea', width: '10%' },
    { title: 'Item Changed', dataIndex: 'itemChanged' },
    {
      title: 'Previous Value',
      key: 'previousValue',
      width: '21%',
      render: (activityTracking: ActivityTracking) => renderValues(activityTracking.previousValue),
    },
    {
      title: 'New Value',
      key: 'newValue',
      width: '21%',
      render: (activityTracking: ActivityTracking) => renderValues(activityTracking.newValue),
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Modified By',
      key: 'modifiedBy',
      render: (activityTracking: ActivityTracking) => (
        <TitleAndSubsection
          title={activityTracking.modifiedBy}
          subtitle={activityTracking.modifiedByEmail}
          ellipisLength={20}
        />
      ),
    },
  ]
  return columns
}
