import { useState, useEffect } from 'react'
import { List, Pagination, Skeleton, Space } from 'antd'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { GetChargersAndItsListings } from '../../services/data-provider/charger'
import { ChargerListings } from '../../models/charger'
import { SwtchError } from '../../models/error'
import { Links } from '../routes/paths'
import { buildRailsListingUrl } from '../../helpers/url'
import { useFormatMessage } from '../../helpers/intl'
import { AlertError } from '../error'
import { PaginationMeta } from '../../models/pagination'
import { theme } from '../../theme'
import { Box } from '../../atom/box'

const initialPagination = { currentPage: 1, perPage: 1, totalEntries: 2 }

export const TenantTabChargers: React.FC = () => {
  // @ts-ignore
  const { tenantId } = useParams()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<PaginationMeta>(initialPagination)
  const [error, setError] = useState<SwtchError>()
  const [chargerListings, setChargerListings] = useState<ChargerListings[]>([])

  const listingsHeading = useFormatMessage('dashboard.tenantPage.chargersTab.listings', 'Listings')

  useEffect(() => {
    setLoading(true)
    GetChargersAndItsListings(tenantId, pagination.currentPage)
      .then((resp) => {
        setChargerListings(resp.data)
        setPagination(resp.pagination)
      })
      .catch((err: SwtchError) => setError(err))
      .finally(() => setLoading(false))
  }, [pagination.currentPage])

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination({ ...pagination, currentPage: page })
    return
  }

  const hasConnectors = (item: ChargerListings) => !item.connectors || item.connectors.length === 0

  const checkChargerAndConnector = (item: ChargerListings) => {
    if (item.connectors && item.connectors.length > 0) {
      return (
        <>
          {renderSerialNumber(item)}
          {renderListingTitle(item)}
        </>
      )
    }
    if (item.chargePointSerialNumber && hasConnectors(item)) {
      return (
        <>
          {renderSerialNumber(item)}
          {renderListingTitle(item)}
        </>
      )
    }
  }

  const renderSerialNumber = (item: ChargerListings) => {
    return (
      <div>
        <Link
          style={{ color: theme.colors.blackOpacity85 }}
          to={Links.charger({
            chargerId: item?.id,
            connectorId: item.connectors && item.connectors.length > 0 ? item.connectors[0].id : 1,
          })}
        >
          {item.chargePointSerialNumber}
        </Link>
      </div>
    )
  }

  const renderListingTitle = ({ listings }: ChargerListings) => {
    return (
      <span>
        {listingsHeading}:{' '}
        <Space>
          {listings?.map((l) => (
            <a key={l.id} href={buildRailsListingUrl(l)}>
              {l.title}
            </a>
          ))}
        </Space>
      </span>
    )
  }

  return (
    <Box padding>
      <AlertError error={error} />
      <Skeleton loading={loading}>
        {chargerListings.map((chargerListing) => (
          <>
            <List
              loading={loading}
              dataSource={[chargerListing]}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta description={checkChargerAndConnector(item)} />
                </List.Item>
              )}
            />
          </>
        ))}
        <Pagination
          hideOnSinglePage={true}
          current={pagination.currentPage}
          onChange={handlePaginationChange}
          total={pagination?.totalEntries}
          showQuickJumper={false}
          showSizeChanger={false}
          pageSize={pagination?.perPage}
          style={{ textAlign: 'center' }}
        />
      </Skeleton>
    </Box>
  )
}
