import { useRef, useState } from 'react'
import { Form } from 'antd'

import { SwtchError } from '../models/error'
import { DropdownOption } from '../models/option'
import { ListingStatusUpdate, NewListing } from '../models/listing'
import { UpdateListingsStatus } from '../services/data-provider/listing'
import { DropdownServiceStatusOptions } from '../models/connector'
import { useNotifications } from './useNotification'
import { useServiceStatusReasonsTranslation } from './translation/useServiceStatusReasonsTranslation'

export const useListingServiceStatus = (listings: NewListing[], onOk: () => void) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [showOtherReasonField, setShowOtherReasonField] = useState(false)
  const selectRef = useRef<any>(null)

  const [secondDropdownOptions, setSecondDropdownOptions] = useState<DropdownOption[]>([])
  const [dependentSelectorValue, setDependentSelectorValue] = useState(form.getFieldValue('reason'))
  const [openDecommisionedModal, setOpenDecommisionedModal] = useState(false)

  const filterListings = listings.filter((v, i) => {
    return listings.map((val) => val.id).indexOf(v.id) === i
  })

  const { openSuccessNotification, openErrorNotification } = useNotifications()
  const {
    breakerResetText,
    cableReplacementText,
    initialActivationText,
    chargerIsOfflineText,
    chargerIsDamagedText,
    chargerIsFaultedText,
    chargerIsVandalisedText,
    hardwarePartsReplacementDCFCText,
    lackOfFeatureText,
    networkResetText,
    networkHardwareReplacementText,
    otherPleaseSpecifyText,
    poorCustomerServiceText,
    poorHardwareQualityText,
    productBugText,
    priceIsNotCompetitiveText,
    stationIsMalfunctioningText,
    unitReplacementText,
  } = useServiceStatusReasonsTranslation()

  // Options for the second dropdown based on the selection of the first dropdown
  const optionsForSecondDropdown: DropdownServiceStatusOptions = {
    under_repair: [
      { key: 'charger_is_offline', label: chargerIsOfflineText },
      { key: 'charger_is_faulted', label: chargerIsFaultedText },
      { key: 'charger_is_damaged', label: chargerIsDamagedText },
      { key: 'charger_is_vandalised', label: chargerIsVandalisedText },
      { key: 'station_is_malfunctioning', label: stationIsMalfunctioningText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
    decommissioned: [
      { key: 'price_is_not_competitive', label: priceIsNotCompetitiveText },
      { key: 'lack_of_feature', label: lackOfFeatureText },
      { key: 'product_bug', label: productBugText },
      { key: 'poor_customer_service', label: poorCustomerServiceText },
      { key: 'poor_hardware_quality', label: poorHardwareQualityText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
    active: [
      { key: 'initial_activation', label: initialActivationText },
      { key: 'unit_replacement', label: unitReplacementText },
      { key: 'cable_replacement', label: cableReplacementText },
      { key: 'hardware_parts_replacement', label: hardwarePartsReplacementDCFCText },
      { key: 'breaker_reset', label: breakerResetText },
      { key: 'network_reset', label: networkResetText },
      { key: 'network_hardware_replacement', label: networkHardwareReplacementText },
      { key: 'other', label: otherPleaseSpecifyText },
    ],
  }

  const closeDecommisionedModal = () => setOpenDecommisionedModal(false)

  const handleFocus = () => selectRef.current.focus()

  const onServiceStatusChange = (event: string) => {
    // Set options for the second dropdown based on the selection of the first dropdown
    switch (event) {
      case 'under_repair':
        setSecondDropdownOptions(optionsForSecondDropdown['under_repair'])
        handleFocus()
        break
      case 'decommissioned':
        setSecondDropdownOptions(optionsForSecondDropdown['decommissioned'])
        handleFocus()
        break
      case 'active':
        setSecondDropdownOptions(optionsForSecondDropdown['active'])
        handleFocus()
        break
      default:
        setSecondDropdownOptions([])
        break
    }
    setDependentSelectorValue(undefined)
    form.setFieldsValue({ reason: undefined })
    form.setFields([
      {
        name: 'reason',
        value: null,
      },
    ])
  }

  const handleSelect = (value: string) => {
    if (value === 'other') {
      // Close the dropdown when 'other' is selected
      if (selectRef) {
        selectRef.current.blur()
      }
    }
  }

  const onReasonChange = (event: string) => {
    // Set options for the second dropdown based on the selection of the first dropdown
    setDependentSelectorValue(event)

    if (event.includes('other')) {
      setShowOtherReasonField(true)
    } else {
      setShowOtherReasonField(false)
    }
  }

  const showAlertIfDecommissioned = (values: ListingStatusUpdate) => {
    if (values['new_status'] === 'decommissioned') {
      setOpenDecommisionedModal(true)
    } else {
      onFinish(values)
    }
  }

  const decommisionedModalOnFinish = () => {
    closeDecommisionedModal()
    onFinish(form.getFieldsValue())
  }

  const onFinish = (values: ListingStatusUpdate) => {
    let reasons: any = []
    if (dependentSelectorValue) {
      reasons = [...dependentSelectorValue]
    }
    if (values['other_reason']) {
      reasons = [...reasons, values['other_reason']]
    }
    setError(undefined)
    setLoading(true)
    UpdateListingsStatus({
      listing_ids: filterListings.map((f) => f.id),
      new_status: values['new_status'],
      reason: reasons,
    })
      .then((resp) => {
        if (resp && resp.success === true) {
          openSuccessNotification(resp.msg)
        } else {
          openErrorNotification(resp.msg)
        }
        onOk()
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }
  return {
    error,
    loading,
    secondDropdownOptions,
    dependentSelectorValue,
    showOtherReasonField,
    selectRef,
    handleSelect,
    onReasonChange,
    onServiceStatusChange,
    onFinish,
    showAlertIfDecommissioned,
    openDecommisionedModal,
    closeDecommisionedModal,
    form,
    decommisionedModalOnFinish,
  }
}
