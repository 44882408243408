import { Modal } from 'antd'
import { useReportTranslation } from '../../../hooks/translation/useReportTranslation'
import { InfoModalContainer } from '../../../atom/reports'

interface props {
  onCancel: () => void
}

export const ReportInfoModal: React.FC<props> = ({ onCancel }) => {
  const {
    financialSummaryReportTitle,
    financialSummaryReportBody,
    utilityChargeSessionReportTitle,
    utilityChargeSessionReportBody,
    utilityIntervalReportTitle,
    utilityIntervalReportBody,
  } = useReportTranslation()

  return (
    <Modal width={540} closable={true} visible={true} onCancel={onCancel} footer={null}>
      <InfoModalContainer>
        <div className="title heading-05-regular">{financialSummaryReportTitle}</div>
        <div className="body paragraph-03-regular">{financialSummaryReportBody}</div>
        <div className="title heading-05-regular">{utilityChargeSessionReportTitle}</div>
        <div className="body paragraph-03-regular">{utilityChargeSessionReportBody}</div>
        <div className="title heading-05-regular">{utilityIntervalReportTitle}</div>
        <div className="body paragraph-03-regular last-row">{utilityIntervalReportBody}</div>
      </InfoModalContainer>
    </Modal>
  )
}
