import { theme } from '../../theme'

export const emptyBoxSVG = (
  <svg width="174" height="72" viewBox="0 0 174 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8538 66.0936H109.76C110.173 66.0936 110.575 66.0491 110.962 65.9645C111.349 66.0491 111.751 66.0936 112.164 66.0936H153.825C156.922 66.0936 159.433 63.5827 159.433 60.4854C159.433 57.3881 156.922 54.8772 153.825 54.8772H149.018C145.92 54.8772 143.409 52.3663 143.409 49.269C143.409 46.1717 145.92 43.6608 149.018 43.6608H164.24C167.337 43.6608 169.848 41.15 169.848 38.0527C169.848 34.9553 167.337 32.4445 164.24 32.4445H146.614C149.711 32.4445 152.222 29.9336 152.222 26.8363C152.222 23.739 149.711 21.2281 146.614 21.2281H95.3392C98.4365 21.2281 100.947 18.7172 100.947 15.6199C100.947 12.5226 98.4365 10.0117 95.3392 10.0117H49.6725C46.5752 10.0117 44.0643 12.5226 44.0643 15.6199C44.0643 18.7172 46.5752 21.2281 49.6725 21.2281H17.6257C14.5284 21.2281 12.0175 23.739 12.0175 26.8363C12.0175 29.9336 14.5284 32.4445 17.6257 32.4445H37.655C40.7523 32.4445 43.2632 34.9553 43.2632 38.0527C43.2632 41.15 40.7523 43.6608 37.655 43.6608H5.60819C2.51087 43.6608 0 46.1717 0 49.269C0 52.3663 2.51087 54.8772 5.60819 54.8772H36.8538C33.7565 54.8772 31.2456 57.3881 31.2456 60.4854C31.2456 63.5827 33.7565 66.0936 36.8538 66.0936ZM167.444 66.0936C170.542 66.0936 173.053 63.5827 173.053 60.4854C173.053 57.3881 170.542 54.8772 167.444 54.8772C164.347 54.8772 161.836 57.3881 161.836 60.4854C161.836 63.5827 164.347 66.0936 167.444 66.0936Z"
      fill={theme.colors.cultered}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.4805 47.8767V69.0978C54.4805 69.8722 55.1082 70.4999 55.8825 70.4999H116.771C117.546 70.4999 118.173 69.8722 118.173 69.0978V47.8767L110.567 25.3855C110.374 24.8159 109.84 24.4326 109.239 24.4326H63.4154C62.8141 24.4326 62.2798 24.8159 62.0872 25.3855L54.4805 47.8767Z"
      fill="white"
      stroke={theme.colors.frenchGray}
      stroke-width="2.5"
    />
    <path
      d="M106.635 5.44722L97.7425 15.4243M85.8047 2V15.4243V2ZM64.8945 5.44722L73.7872 15.4243L64.8945 5.44722Z"
      stroke={theme.colors.frenchGray}
      stroke-width="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.216 49.0321C95.216 53.4769 91.2353 57.8814 86.3249 57.8814C81.4145 57.8814 77.4339 53.4769 77.4339 49.0321C77.4339 48.8881 77.4381 47.9438 77.4463 47.8017H60.6875L67.2261 31.9038C67.508 31.1494 68.2899 30.6416 69.1697 30.6416H103.48C104.36 30.6416 105.142 31.1494 105.424 31.9038L111.962 47.8017H95.2035C95.2118 47.9438 95.216 48.8881 95.216 49.0321Z"
      fill={theme.colors.lightGray}
    />
    <path
      d="M64.895 47.667C68.0489 47.667 71.4525 47.667 75.1058 47.667C76.6091 47.667 76.6091 48.7234 76.6091 49.2693C76.6091 54.579 81.0124 58.8834 86.4443 58.8834C91.8761 58.8834 96.2795 54.579 96.2795 49.2693C96.2795 48.7234 96.2795 47.667 97.7827 47.667H117.772M58.9453 47.667H60.8892H58.9453Z"
      stroke={theme.colors.frenchGray}
      stroke-width="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
