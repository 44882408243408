import { Button, Space } from 'antd'
import pluralize from 'pluralize'

import { editBlackSVG } from 'assets/svg/edit'
import { SelectionInfoContainer } from 'atom/box'
import { ButtonModal } from 'atom/button'

import { NewListing } from 'models/listing'

interface props {
  selectedListings: NewListing[]
  onShowModal: (modalType: 'listing' | 'charger', value: boolean) => void
  handleListingsChargersCancelButton: () => void
}

const ChargerEditOption: React.FC<props> = ({ selectedListings, onShowModal, handleListingsChargersCancelButton }) => {
  // Check if there is at least one charger in the selected listings
  const hasChargers = selectedListings.some((listing) => listing.charger !== undefined)

  const hasListings = selectedListings.some((listing) => listing.id !== undefined)
  return (
    <SelectionInfoContainer align="middle" justify="space-between">
      <Space>
        <span className="paragraph-01-regular">
          {selectedListings.length} {pluralize('charger', selectedListings.length)} selected
        </span>
      </Space>
      <Space>
        <ButtonModal
          icon={editBlackSVG}
          type="primary"
          text="edit listing"
          click={() => onShowModal('listing', true)}
          disabled={!hasListings}
        />
        <ButtonModal
          icon={editBlackSVG}
          type="primary"
          text="edit charger"
          click={() => onShowModal('charger', true)}
          disabled={!hasChargers}
        />
        <Button type="ghost" onClick={handleListingsChargersCancelButton}>
          Cancel
        </Button>
      </Space>
    </SelectionInfoContainer>
  )
}

export default ChargerEditOption
