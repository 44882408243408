import { useFormatMessage } from '../../helpers/intl'

export const useListingPriceTranslation = () => {
  const priceCentsText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceCentsText',
    'Price ($)',
  )
  const selectPricingOptionText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.selectPricingOptionText',
    'Select Pricing Option',
  )

  const priceTypeHourText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceTypeHourText',
    'Hour',
  )
  const hourlyPriceBasedOnkWh = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText',
    'Price',
  )
  const enableLoadManagementPricingText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.kwOutputBasedPricingText',
    'Hourly pricing based on kWh',
  )

  const enablePricingForTenantMembers = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.enablePricingForTenantMembers',
    'Enable pricing for site members',
  )

  const enableTimeOfDayPricingText = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.heading',
    'Enable Time of Day Pricing',
  )

  const startTxt = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.start', 'Start')
  const endTxt = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.end', 'End')
  const priceTxt = useFormatMessage('dashboard.tenantPage.uniformPricingTab.uniformPricing.priceText', 'Price')
  const weekdaysTxt = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekdays',
    'Weekdays',
  )
  const weekendsTxt = useFormatMessage(
    'dashboard.tenantPage.uniformPricingTab.uniformPricing.todPricing.weekends',
    'Weekends',
  )

  const preferredPricingTxt = useFormatMessage(
    'dashboard.createNewListing.priceStep.preferredPricing.txt',
    'Preferred pricing for site members',
  )

  const schemaText = useFormatMessage('dashboard.text.schema', 'Schema')
  const pricingSchemaText = useFormatMessage('dashboard.text.pricingSchema', 'Pricing Schema')

  return {
    enableLoadManagementPricingText,
    enablePricingForTenantMembers,
    enableTimeOfDayPricingText,
    endTxt,
    hourlyPriceBasedOnkWh,
    priceTxt,
    priceCentsText,
    priceTypeHourText,
    pricingSchemaText,
    preferredPricingTxt,
    schemaText,
    startTxt,
    selectPricingOptionText,
    weekdaysTxt,
    weekendsTxt,
  }
}
