import { Table } from 'antd'

import { NewListing } from '../../../models/listing'
import { PaginationMeta } from '../../../models/pagination'

import ListingCollapse from '../mobile/ListingCollapse'
import { useAppState } from '../../../state'

import AccessPermission from '../functions/access-permission'
import ListingLoiteringStatus from '../functions/loitering'
import { handleConnectivity } from '../functions/connectivity'

import { ListingColumns } from './ListingColumns'

interface props {
  loading: boolean
  listings: NewListing[]
  pagination: PaginationMeta
  onPageChange: (page: number) => void
  rowSelection: any
}

const ListingsTable: React.FC<props> = ({ loading, listings, pagination, onPageChange, rowSelection }) => {
  const { IsDesktop, IsTablet, IsLaptop, IsMobile, currentUser } = useAppState()

  const isMobile = IsMobile()
  const isDesktop = IsDesktop()
  const isTablet = IsTablet()
  const isLaptop = IsLaptop()

  return (
    <>
      {(isDesktop || isLaptop) && (
        <Table
          loading={loading}
          rowKey="id"
          columns={ListingColumns}
          dataSource={listings}
          pagination={{
            hideOnSinglePage: true,
            position: ['bottomCenter'],
            total: pagination?.totalEntries,
            current: pagination?.currentPage,
            pageSize: pagination?.perPage,
            showSizeChanger: false,
            onChange: onPageChange,
          }}
          rowSelection={currentUser?.role === 'admin' ? { ...rowSelection } : undefined}
        />
      )}

      {(isTablet || isMobile) && (
        <ListingCollapse
          loading={loading}
          listings={listings}
          onPermission={(listing: NewListing) => <AccessPermission permission={listing.permission} />}
          onLoitering={(listing: NewListing) => <ListingLoiteringStatus listing={listing} />}
          onConnectivity={(listing: NewListing) => handleConnectivity(listing.connectivity)}
          pagination={pagination}
          onPaginationChange={onPageChange}
        />
      )}
    </>
  )
}

export default ListingsTable
