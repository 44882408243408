import { theme } from '../../theme'

export const cancelEventSVG = (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 5.25781L5 13.2578M13 13.2578L5 5.25781"
      stroke={theme.colors.darkGray}
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
)
