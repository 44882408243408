import React from 'react'
import { Badge } from 'antd'

import { connectorServiceStatus, connectorStatus } from '../../models/connector'
import { theme } from '../../theme'
import { useFormatMessage } from '../../helpers/intl'
import { serviceStatus } from '../../models/charger'
import { renderConnectionMessage } from '../../helpers/status'
import { tenantStatuses } from 'models/tenant'

interface BadgeTextProps {
  text: string
  maxLineLength: number
}

export const allStatus = [
  ...connectorStatus,
  ...serviceStatus,
  ...connectorServiceStatus,
  ...tenantStatuses,
  'Commissioning',
  'Online',
] as const
export type AllStatus = typeof allStatus[number]

interface props {
  state: AllStatus
}

const ServiceStatusBadge: React.FC<props> = ({ state }) => {
  const [status, backgroundColor, fontColor] = getColorStatus(state)
  const translatedStatus = useFormatMessage(renderConnectionMessage(state), status)

  return (
    <Badge
      count={
        <div className="custom-badge-text">
          <BadgeText text={translatedStatus} maxLineLength={15} />
        </div>
      }
      style={{ backgroundColor, color: fontColor, boxShadow: `${backgroundColor} 0px 0px 0px 0.5px` }}
    />
  )
}

const BadgeText: React.FC<BadgeTextProps> = ({ text, maxLineLength }) => {
  const lines = []
  let currentLine = ''

  text.split(' ').forEach((word) => {
    if (currentLine.length + word.length + 1 > maxLineLength) {
      lines.push(currentLine)
      currentLine = word
    } else {
      currentLine += (currentLine ? ' ' : '') + word
    }
  })

  if (currentLine) {
    lines.push(currentLine)
  }

  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  )
}

// Returns the a tupple, where the first argument in the Humanize state and the second is the css class
export const getColorStatus = (status: AllStatus): string[] => {
  switch (status) {
    case 'Unavailable':
    case 'unavailable':
      return [status, theme.colors.goldenHoneycomb, theme.colors.goldenOlive]
    case 'Faulted':
    case 'faulted':
    case 'under_repair':
    case 'Under Repair':
    case 'Troubleshooting':
    case 'troubleshooting':
    case 'Inactive':
    case 'inactive':
      return [status, theme.colors.underRepair, theme.colors.dangerPrimary]
    case 'Terminated':
    case 'terminated':
    case 'Other':
    case 'other':
    case 'Decommissioned':
    case 'decommissioned':
    case 'Testing':
    case 'testing':
    case 'on_hold':
    case 'On Hold':
    case 'in_maintenance':
      return [status, theme.colors.black05, theme.colors.black]
    case 'Offline':
    case 'offline':
      return [status, theme.colors.grey14, theme.colors.black]
    case 'Available':
    case 'Online':
    case 'Active':
    case 'active':
    case 'service_mode':
      return [status, theme.colors.magicMint, theme.colors.viridian]
    case 'Commissioned':
    case 'commissioned':
      return [status, theme.colors.orangePeel15, theme.colors.warningPrimary]
    case 'SuspendedEV':
    case 'SuspendedEVSE':
      return [status, theme.colors.whisperingLavender, theme.colors.electricIndigo]
    case 'Preparing':
    case 'Finishing':
    case 'activating':
    case 'Activating':
    case 'draft':
    case 'pending_sa':
      return [status, theme.colors.orangePeel15, theme.colors.warningPrimary]
    case 'Charging':
    case 'charging':
    case 'Commissioning':
    case 'Awaiting Commissioning':
    case 'awaiting_commissioning':
      return [status, theme.colors.skyBlue15, theme.colors.carribeanCurrent]
    case 'Activating - Pending Property':
    case 'Activating - Pending Driver Subscription':
    case 'property_activating':
    case 'driver_activating':
      return [status, theme.colors.lavenderBlush, theme.colors.deepPurple]
    default:
      return ['Unknown', theme.colors.gray]
  }
}

export default ServiceStatusBadge
