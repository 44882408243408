import { theme } from '../../theme'

export const BinSVG = (props: any) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.5 3.34559H15.5M6.75 11.5809V6.63971M10.25 11.5809V6.63971M12 14.875H5C4.0335 14.875 3.25 14.1376 3.25 13.2279V4.16912C3.25 3.71429 3.64175 3.34559 4.125 3.34559H12.875C13.3582 3.34559 13.75 3.71429 13.75 4.16912V13.2279C13.75 14.1376 12.9665 14.875 12 14.875ZM6.75 3.34559H10.25C10.7332 3.34559 11.125 2.97688 11.125 2.52206V1.69853C11.125 1.24371 10.7332 0.875 10.25 0.875H6.75C6.26675 0.875 5.875 1.24371 5.875 1.69853V2.52206C5.875 2.97688 6.26675 3.34559 6.75 3.34559Z"
      stroke={theme.colors.darkGray}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
