import { message } from 'antd'
import { ReusableAutoComplete } from 'components/reusableSelector/autoComplete'
import React, { useState } from 'react'
import { SwtchError } from '../../models/error'
import { FindAggregator } from '../../services/data-provider/peak-shaving'

interface props {
  onSelect?: (value: string) => void
  onClear?: () => void
  onEnter?: () => void
  placeholder?: string
  style?: React.CSSProperties
}

interface OptionProps {
  id: number
  value: string
  label: string
}

export const PeakShavingAggregatorNameSelector: React.FC<props> = ({
  onSelect,
  onClear,
  onEnter,
  placeholder,
  style,
  ...defaultProps
}) => {
  const [options, setOptions] = useState<OptionProps[]>([])

  const [loading, setLoading] = useState(false)

  const searchAggregatorName = (value: string) => {
    setLoading(true)
    FindAggregator(value)
      .then((aggregatorNames) => {
        //Changed to use program data so filtering out the programs that doesn't have aggregatorName
        let uniqueAggregators = new Set()

        setOptions(
          aggregatorNames?.data
            .map((aggregator) => ({
              id: aggregator.id,
              value: aggregator.aggregatorName,
              label: aggregator.aggregatorName,
            }))
            .filter((val) => {
              if (val.value && !uniqueAggregators.has(val.value)) {
                uniqueAggregators.add(val.value)
                return true
              }
              return false
            }),
        )
      })
      .catch((err: SwtchError) => {
        setOptions([])
        message.error(err.description)
      })
      .finally(() => setLoading(false))
  }

  return (
    <ReusableAutoComplete
      {...defaultProps}
      onSelect={onSelect}
      onClear={onClear}
      onEnter={onEnter}
      loading={loading}
      onSearch={searchAggregatorName}
      allowClear
      showMagnifySVG
      options={options}
      placeholder={placeholder}
      style={style}
      id={'aggregatorName'}
    />
  )
}
