import { styled, theme } from '../theme'

interface props {
  responsive?: boolean
}

interface mobileProps {
  tablet?: boolean
}

export const InputsContainer = styled.div`
  display: flex;

  .tenant-selector {
    margin-left: 10.5px;
    margin-right: -8.5px;

    .ant-select-selector {
      width: 321px;
    }
  }

  .multi-listing-title-selector {
    margin-left: -7.5px;
    margin-right: -8.5px;
  }

  .ant-picker {
    margin-right: -8.5px;
  }

  .location-selector {
    margin-left: -7.5px;

    .ant-select-selector {
      width: 203px;
    }

    .ant-select-clear {
      right: 47px;
    }
  }

  .master-account-selector {
    margin-left: -7.5px;
    margin-right: -8.5px;
  }
`

export const MobileInputsContainer = styled.span<mobileProps>`
  display: flex;
  .ant-row > div > .tenant-selector {
    min-width: 200px;
  }

  .multi-listing-title-selector {
    .ant-select-selector {
      border-left-color: ${theme.colors.lightGray} !important;
      border-right-color: ${theme.colors.lightGray} !important;
    }
  }

  .location-selector {
    .ant-select-clear {
      right: 60px;
    }
  }

  button {
    width: 98%;
    margin-left: 5px;
  }

  .button-row {
    width: ${(props) => (props.tablet ? '94%' : '82%')};
  }
`

export const InfoModalContainer = styled.div`
  .title {
    margin-bottom: 8px;
  }

  .body {
    margin-bottom: 20px;
    color: ${theme.colors.darkGray};
  }

  .last-row {
    margin-bottom: 0px !important;
  }
`

export const QuestionButtonWrapper = styled.div<props>`
  position: ${(props) => (props.responsive ? 'absolute' : 'relative')};
  right: ${(props) => (props.responsive ? '8px' : 'unset')};
  bottom: ${(props) => (props.responsive ? '1px' : 'unset')};

  button {
    background-color: transparent;
    margin-top: 7px;
  }

  button:hover {
    background-color: transparent;
  }

  svg {
    margin-left: -8px;
    margin-top: -3px;
  }

  path {
    fill: ${theme.colors.lightGray} !important;
  }
`
