import React, { useEffect, useState } from 'react'

import { Form, Input, Modal, Radio, Select, Space } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { CustomDateRangePicker } from 'components/redesign/datepicker'
import { PeakShavingAggregatorNameSelector } from 'components/selector/peak-shaving-aggregator-name-selector'
import { useCalendarTransactions } from 'hooks/translation/useCalendarTranslation'
import { PeakShavingProgram } from 'models/peak-shaving'
import moment from 'moment'
import styled from 'styled-components'
import { closeSVG } from '../../../assets/svg/close'
import { PeakShavingSelectArrowDown } from '../../../assets/svg/select'
import { ButtonModal } from '../../../atom/button'
import { layout } from '../../../atom/form/page-layout'
import { AlertError } from '../../../components/error'
import { CustomSuccessMessage } from '../../../components/peakShaving/message'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { SwtchApiError } from '../../../models/error'
import { CreatePeakShavingProgram, UpdatePeakShavingProgram } from '../../../services/data-provider/peak-shaving'

interface props {
  visible: boolean
  onCancel(): void
  onSubmit: (updatedProgram?: PeakShavingProgram) => void
  initialValues?: Store //This indicates Edit modal
}

const CreateNewProgramModalContainer = styled.div`
  .ant-modal-footer {
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-footer .ant-btn {
    min-width: 240px;
  }
`

export const CreateNewProgramModal: React.FC<props> = ({ visible, onCancel, onSubmit, initialValues }) => {
  const [loading, setLoading] = useState(false)
  const [CreateNewProgramForm] = Form.useForm()
  const [error, setError] = useState<SwtchApiError>()
  const messageKey = initialValues ? 'programDetailsMessageKey' : 'createNewProgramMessageKey'

  const { calendarRanges } = useCalendarTransactions()

  const [startDate, setStartDate] = useState(moment().subtract(6, 'd'))
  const [endDate, setEndDate] = useState(moment(moment()))

  const {
    createNewProgramText,
    updateProgramText,
    cancelText,
    submitText,
    isRquiredText,
    typeText,
    dateText,
    nameText,
    aggregatorNameText,
    descriptionText,
    programCreatedSuccessText,
    programUpdatedSuccessText,
    participationDetailsText,
    participationDetailsPlaceholderText,
    eventNotificationDetailsText,
    eventNotificationDetailsPlaceholderText,
    voluntaryText,
    nonVoluntaryText,
    peakShavingText,
    demandResponseText,
    cannotExceedText,
  } = usePeakShavingTranslation()

  const onChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) {
      setStartDate(dates[0])
    }
    if (dates[1] !== endDate) {
      setEndDate(dates[1])
    }
  }

  const handleFormSubmit = () => {
    CreateNewProgramForm.validateFields().then((values) => {
      setLoading(true)
      const body = {
        ...values,
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
        id: initialValues?.id,
      }
      ;(initialValues ? UpdatePeakShavingProgram(body) : CreatePeakShavingProgram(body))
        .then((resp) => {
          const responseMessage = initialValues
            ? programUpdatedSuccessText
            : programCreatedSuccessText.replace('-1', resp.name)
          CustomSuccessMessage({ key: messageKey, response: responseMessage })
          onSubmit(resp)
          CreateNewProgramForm.resetFields()
        })
        .catch((err: SwtchApiError) => {
          setError(err)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  }

  useEffect(() => {
    if (initialValues) {
      setStartDate(moment(initialValues?.startDate))
      setEndDate(moment(initialValues?.endDate))
    }
  }, [initialValues])

  return (
    <CreateNewProgramModalContainer>
      <Modal
        title={<h5 className="heading-05-regular">{initialValues ? updateProgramText : createNewProgramText}</h5>}
        visible={visible}
        confirmLoading={loading}
        footer={
          <Space>
            <ButtonModal type="ghost" icon={<></>} click={onCancel} text={cancelText} />
            <ButtonModal type="primary" icon={<></>} click={handleFormSubmit} text={submitText} />
          </Space>
        }
        onCancel={onCancel}
        width={540}
        className={`createNewProgramModal ${error ? 'ant-modal-error' : ''}`}
        closeIcon={closeSVG}
      >
        <AlertError error={error} />
        <Form
          {...layout}
          form={CreateNewProgramForm}
          style={{ marginTop: '2rem' }}
          initialValues={initialValues ?? { category: 'peak shaving', voluntary: false }}
          layout="vertical"
        >
          <Form.Item
            label={<p className="paragraph-02-regular">{nameText}</p>}
            name="name"
            rules={[{ required: true, message: isRquiredText.replace('-1', nameText) }]}
            {...formItemLayout}
          >
            <Input disabled={!!initialValues} />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{typeText}</p>}
            name="category"
            rules={[{ required: true, message: isRquiredText.replace('-1', typeText) }]}
            {...formItemLayout}
          >
            <Select
              options={[
                { value: 'peak shaving', label: peakShavingText },
                { value: 'demand response', label: demandResponseText },
              ]}
              suffixIcon={<PeakShavingSelectArrowDown />}
            />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{aggregatorNameText}</p>}
            name="aggregatorName"
            {...formItemLayout}
          >
            <PeakShavingAggregatorNameSelector />
          </Form.Item>
          <Form.Item label={<p className="paragraph-02-regular">{dateText}</p>} {...formItemLayout}>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChangeDate={onChangeDate}
              calendarRanges={calendarRanges}
              insideModal
              enableFutureDate
            />
          </Form.Item>

          <Form.Item
            label={<p className="paragraph-02-regular">{participationDetailsText}</p>}
            name="participationWindow"
            rules={[{ required: false, max: 255, message: cannotExceedText.replace('-1', participationDetailsText) }]}
            {...formItemLayout}
          >
            <Input.TextArea rows={3} placeholder={participationDetailsPlaceholderText} />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{eventNotificationDetailsText}</p>}
            name="eventNotification"
            rules={[
              { required: false, max: 255, message: cannotExceedText.replace('-1', eventNotificationDetailsText) },
            ]}
            {...formItemLayout}
          >
            <Input.TextArea rows={3} placeholder={eventNotificationDetailsPlaceholderText} />
          </Form.Item>
          <Form.Item
            label={<p className="paragraph-02-regular">{descriptionText}</p>}
            name="initiative"
            rules={[{ required: false, max: 255, message: cannotExceedText.replace('-1', descriptionText) }]}
            {...formItemLayout}
          >
            <Input.TextArea rows={5} placeholder={descriptionText} />
          </Form.Item>
          <Form.Item name="voluntary" rules={[{ required: true }]} {...formItemLayout}>
            <Radio.Group>
              <Space wrap>
                <Radio value={true}>{voluntaryText}</Radio>
                <Radio value={false}>{nonVoluntaryText}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </CreateNewProgramModalContainer>
  )
}
