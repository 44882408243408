import { Col, Row, Space, Table } from 'antd'
import ProgramStatusBadge from 'components/peakShaving/peak-shaving-status'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { peakShavingInfoSVG } from '../../../assets/svg/peak-shaving-info-icon'
import { usePeakShavingTranslation } from '../../../hooks/translation/usePeakShavingTranslation'
import { PeakShavingProgram } from '../../../models/peak-shaving'
import { useAppState } from '../../../state'
import { styled, theme } from '../../../theme'
import { CreateNewProgramModal } from '../modal/create-new-program-modal'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

interface props {
  setProgram: (updatedProgram: PeakShavingProgram) => void
  program: PeakShavingProgram
}

const FormContainer = styled.div`
  width: 100%;
`

export const ProgramDetailsForm: React.FC<props> = ({ program, setProgram }) => {
  const { language } = useAppState()
  const [showEditModal, setShowEditModal] = useState(false)

  const {
    idText,
    programNameText,
    aggregatorNameText,
    categoryText,
    descriptionText,
    programDetailsText,
    startDateText,
    endDateText,
    statusText,
    voluntaryText,
    participationWindowText,
    eventNotificationText,
    publishProgramInfoText,
  } = usePeakShavingTranslation()

  const { omOptinYesText, omOptinNoText } = useGeneralTranslation()

  const handleFormSubmit = (updatedProgram?: PeakShavingProgram) => {
    if (updatedProgram) {
      setProgram(updatedProgram)
    }
    handleProgramModal()
  }

  const handleProgramModal = () => setShowEditModal(!showEditModal)

  useEffect(() => {
    const body = document.querySelector('body')
    if (body != null) {
      body.className = `redesignActive peak-shaving ${language}`
    }
  }, [language])

  const programDetailsColumns = [
    {
      title: idText,
      dataIndex: 'id',
      render: (id: number) => {
        return (
          <span
            onClick={handleProgramModal}
            className="paragraph-02-regular"
            style={{ cursor: 'pointer', color: theme.colors.primary }}
          >
            {id}
          </span>
        )
      },
    },
    {
      title: programNameText,
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: aggregatorNameText,
      dataIndex: 'aggregatorName',
      width: '10%',
    },
    {
      title: startDateText,
      dataIndex: 'startDate',
      width: '15%',
      render: (date: string) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: endDateText,
      dataIndex: 'endDate',
      width: '15%',
      render: (date: string) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: descriptionText,
      dataIndex: 'initiative',
      width: '25%',
    },
    {
      title: participationWindowText,
      dataIndex: 'participationWindow',
    },
    {
      title: eventNotificationText,
      dataIndex: 'eventNotification',
    },
    {
      title: voluntaryText,
      dataIndex: 'voluntary',
      render: (voluntary: boolean) => (voluntary ? omOptinYesText : omOptinNoText),
    },
    {
      title: categoryText,
      dataIndex: 'category',
      width: '33%',
    },
    {
      title: statusText,
      dataIndex: 'state',
      render: (state: string) => <ProgramStatusBadge state={state} />,
      width: '15%',
    },
  ]

  return (
    <Row>
      <CreateNewProgramModal
        initialValues={{ ...program }}
        visible={showEditModal}
        onCancel={handleProgramModal}
        onSubmit={handleFormSubmit}
      />
      <Col span={20}>
        <span className="heading-05-regular">{programDetailsText}</span>
      </Col>
      {program && program.state === 'draft' && (
        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
          <Space style={{ marginRight: '25px' }}>
            {peakShavingInfoSVG}
            <p className="paragraph-04-regular" style={{ marginBottom: '0.5em', color: '#7D838A' }}>
              {publishProgramInfoText}
            </p>
          </Space>
        </Col>
      )}
      {program && (
        <FormContainer>
          <Table
            rowKey={(record) => record.id}
            columns={programDetailsColumns}
            dataSource={[program]}
            pagination={false}
          />
        </FormContainer>
      )}
    </Row>
  )
}
