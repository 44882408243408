import { useEffect, useState } from 'react'
import moment from 'moment'

import { TenantRef } from '../models/tenant'
import { SwtchError } from '../models/error'

import { GetMetabaseReport } from '../services/data-provider/reports'
import { MasterAccountRef } from 'models/master-account'

export const useReports = () => {
  const [startDate, setStartDate] = useState<moment.Moment>(moment().subtract(6, 'days'))
  const [endDate, setEndDate] = useState<moment.Moment>(moment())
  const [loading, setLoading] = useState(false)
  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [listingTitles, setListingTitles] = useState<string[]>([])
  const [error, setError] = useState<SwtchError>()
  const [url, setUrl] = useState('')
  const [selectedCountries, setSelectedCountries] = useState<string[]>([])
  const [selectedProvinces, setSelectedProvinces] = useState<string[]>([])
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef | undefined>()

  useEffect(() => {
    getReports()
  }, [])

  const getReports = () => {
    setLoading(true)
    const tenantIdArray: string[] = []
    tenants.map((tenant) => tenantIdArray.push(tenant.id))
    GetMetabaseReport({
      startDate: startDate,
      endDate: endDate,
      tenant_id: tenantIdArray,
      listing_title: listingTitles,
      metabase_dashboard_id: '',
      selectedCountries: selectedCountries,
      selectedProvinces: selectedProvinces,
      masterAccount: masterAccount,
    })
      .then((resp) => setUrl(resp.data[0].url))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const handleChangeDate = (dates: moment.Moment[]) => {
    if (dates[0] !== startDate) setStartDate(dates[0])
    if (dates[1] !== endDate) setEndDate(dates[1])
  }

  const handleLocation = (chosenCountries: string[], chosenProvinces: string[]) => {
    setSelectedCountries(chosenCountries)
    setSelectedProvinces(chosenProvinces)
  }

  return {
    tenants,
    error,
    loading,
    url,
    startDate,
    endDate,
    getReports,
    handleChangeDate,
    setTenants,
    setListingTitles,
    handleLocation,
    setMasterAccount,
  }
}
