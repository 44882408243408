import { Row } from 'antd'
import { renderFormatMessage } from 'helpers/intl'
import { capitalizeFirstLetter } from 'helpers/users'

interface InfoMessageProps {
  loading: boolean
  totalEntries: number
  text: string
  showSearchQuery: boolean
  selectAllData?: () => void
  selectedData?: any[]
}

const InfoMessage = ({
  text,
  loading,
  totalEntries,
  showSearchQuery,
  selectAllData,
  selectedData,
}: InfoMessageProps) => {
  const coreDataLowercase = (text: string) => {
    return renderFormatMessage(`dashboard.text.${text}Lower`, text)
  }

  const singularOrPluralFoundText = (totalEntries: number) => {
    return totalEntries >= 2 || totalEntries === 0
      ? renderFormatMessage('dashboard.text.are', 'are')
      : renderFormatMessage('dashboard.text.is', 'is')
  }

  const foundText = renderFormatMessage('dashboard.text.found', 'found')
  const isCapitalizedAndPlural = (text: string) => {
    return capitalizeFirstLetter(text) + (totalEntries >= 2 ? 's' : '')
  }

  const selectAllText = renderFormatMessage(
    `dashboard.text.selectAll${isCapitalizedAndPlural(text)}`,
    `Select all ${totalEntries} ${text}`,
    { totalEntries },
  )

  const entireSentence = (totalEntries: number, text: string) => (
    <>
      {totalEntries} {coreDataLowercase(text)}
      {totalEntries >= 2 ? 's ' : ' '}
      {singularOrPluralFoundText(totalEntries)} {foundText}.{' '}
    </>
  )

  return (
    <>
      {/* sample of expected outcome: 7 listings are found */}
      {showSearchQuery && !loading && totalEntries ? (
        <Row align="middle">
          <div className="paragraph-01-regular-soft-black">{entireSentence(totalEntries, text)}</div>
          {selectedData && selectAllData && selectedData.length !== totalEntries && (
            <button className="paragraph-01-regular link" onClick={selectAllData}>
              {selectAllText}
            </button>
          )}
        </Row>
      ) : null}
    </>
  )
}

export default InfoMessage
