import { theme } from '../../theme'

export const noteSVG = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.16758 16.168H3.56757C2.57346 16.168 1.76757 15.3621 1.76758 14.368L1.76765 3.56806C1.76765 2.57395 2.57354 1.76807 3.56765 1.76807H11.6678C12.662 1.76807 13.4679 2.57395 13.4679 3.56807V7.16807M4.91786 5.36807H10.3179M4.91786 8.06807H10.3179M4.91786 10.7681H7.61786M9.86772 13.6863L13.6861 9.86796L16.2317 12.4135L12.4133 16.2319H9.86772V13.6863Z"
      stroke={theme.colors.primary}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
