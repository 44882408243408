import { theme } from '../../theme'

export const PeakShavingAddIconSVG = (props: any) => (
  <svg
    className="peak-shaving-plus-icon"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12.5264" cy="12.5264" r="12.5264" fill={theme.colors.primary} />
    <path
      d="M12.5264 8.05078L12.5264 16.9982M17.0001 12.5245L8.05273 12.5245"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
)
