import { ReactElement } from 'react'
import { Button, Checkbox, Col, Row, Select, Space } from 'antd'

import { useAppState } from 'state'
import { ButtonIcon } from 'atom/button'
import { LineBreak } from 'atom/line-break'
import { ButtonIconCircle } from 'atom/button'
import { ModifyButtonWrapper } from 'atom/chargers'
import { theme } from 'theme'

import { editGreySVG } from 'assets/svg/edit'
import { magnifyBlackSVG } from 'assets/svg/magnify'

import { ConnectorServiceStatus, connectorServiceStatus } from 'models/connector'
import { TenantRef } from 'models/tenant'
import { ListingFilter } from 'models/filter'
import { MasterAccountRef } from 'models/master-account'
import { ListingRef, NewListing } from 'models/listing'

import { ReusableSelector } from 'components/reusableSelector/selector'

import { useSelector } from 'hooks/useSelector'
import { useSelectorTranslation } from 'hooks/translation/useSelectorTranslation'
import { useMasterAccountSelector } from 'hooks/useMasterAccountSelector'
import { useListingBasicTrans } from 'hooks/translation/useListingBasicTrans'
import { useGeneralTranslation } from 'hooks/translation/useGeneralTranslation'

import { renderFormatMessage } from 'helpers/intl'
import { renderConnectionMessage } from 'helpers/status'

interface props {
  loading: boolean
  tenants: TenantRef[]
  selectedListings: NewListing[]
  setShowListingModal: (showListingModal: boolean) => void
  defaultTenant?: TenantRef
  filter: ListingFilter
  onTenants: (tenants: TenantRef[]) => void
  onFilter: (filters: ListingFilter) => void
  onServiceStatus: (serviceStatuses: ConnectorServiceStatus[]) => void
  onListingTitle: (listingTitles: string[]) => void
  onListingIds: (listingIds: number[]) => void
  onTenantSelection: (tenants: TenantRef[]) => void
  onFetchListings: () => void
  dropdownClassName?: string
  setMasterAccount: (masterAccount: MasterAccountRef | undefined) => void
}

const ListingAllSelectors: React.FC<props> = ({
  onFetchListings,
  tenants,
  selectedListings,
  defaultTenant,
  filter,
  onTenants,
  onFilter,
  onServiceStatus,
  onListingTitle,
  onListingIds,
  onTenantSelection,
  setShowListingModal,
  dropdownClassName,
  setMasterAccount,
  ...props
}) => {
  const { IsDesktop, IsLaptop } = useAppState()
  const { searchText } = useGeneralTranslation()
  const { currentUser } = useAppState()
  const { accountNameText } = useSelectorTranslation()
  const { options, handleMasterAccountSelectChange, renderMasterAccountOption } = useMasterAccountSelector(
    setMasterAccount,
  )
  const isAdmin = currentUser?.role === 'admin'
  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const { locationTextPlaceholder } = useSelectorTranslation()
  const { listingTitlesText } = useListingBasicTrans()
  const {
    multiTenantDebounceFetcher,
    multiListingTitleDebounceFetcher,
    multiListingIdDebounceFetcher,
    multiTenantHandleChange,
    multiListingTitleHandleChange,
    multiListingTitleHandlePlaceholder,
    multiTenantHandlePlaceholder,
    multiTenantHandleValue,
    multiListingTitleHandleValue,
    multiListingIdHandlePlaceholder,
    multiListingIdHandleChange,
    multiServiceStatusPlaceholder,
    multiServiceStatusHandleChange,
  } = useSelector()

  const renderMultiTenantOption = (
    { id, displayName, name: ocppName, combineName }: any,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    return (
      <Select.Option key={id} value={combineName} label={displayName ? displayName : ocppName}>
        <Checkbox
          onClick={handleCheckboxClick}
          checked={selectedOptions.findIndex((selectedTenant) => selectedTenant.combineName === combineName) > -1}
        >
          {displayName && (displayName !== '' || displayName !== null) ? displayName : ocppName}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultiListingTitleOption = (
    { id, title }: ListingRef,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={id} value={title} label={title}>
      <Checkbox
        onClick={handleCheckboxClick}
        checked={selectedOptions.findIndex((option) => option.title === title) > -1}
      >
        {title}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiListingIdOption = (
    id: number,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => (
    <Select.Option key={id} value={id} label={id}>
      <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.findIndex((option) => option === id) > -1}>
        {id}
      </Checkbox>
    </Select.Option>
  )

  const renderMultiOption = (
    option: string,
    selectedOptions: any[],
    handleCheckboxClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  ): ReactElement => {
    const translateOpt = renderFormatMessage(renderConnectionMessage(option), option)
    return (
      <Select.Option key={option} value={option} label={option}>
        <Checkbox onClick={handleCheckboxClick} checked={selectedOptions.includes(option)}>
          {translateOpt}
        </Checkbox>
      </Select.Option>
    )
  }

  const renderMultTenantSelector = (
    <ReusableSelector
      showSearch
      onOptionsChange={(tenants) => onTenantSelection(tenants)}
      onClear={() => onTenants([])}
      combineType="right"
      customWidth="321px"
      isSingle={false}
      placeholder={locationTextPlaceholder}
      dropDownList={tenants}
      showMagnifySVG={true}
      maxTagCount={0}
      isDebounceFetcher={true}
      debounceFetcher={multiTenantDebounceFetcher}
      dropdownClassName={dropdownClassName}
      showArrow={false}
      handlePlaceholder={multiTenantHandlePlaceholder}
      handleOptionChange={multiTenantHandleChange}
      handleValue={multiTenantHandleValue}
      renderOption={renderMultiTenantOption}
    />
  )

  const renderMasterAccountSelector = () => (
    <ReusableSelector
      showSearch={true}
      onClear={() => setMasterAccount(undefined)}
      isSingle={true}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={false}
      handlePlaceholder={undefined}
      debounceFetcher={undefined}
      dropdownClassName={dropdownClassName}
      placeholder={accountNameText}
      dropDownList={options}
      selectAllOptions={true}
      combineType="both"
      showArrow={false}
      handleOptionChange={handleMasterAccountSelectChange}
      renderOption={renderMasterAccountOption}
    />
  )

  const renderMultiListingTitleSelector = (
    <ReusableSelector
      showSearch
      onOptionsChange={(listingTitle: string[]) => {
        onFilter({ ...filter, listingTitle: listingTitle })
        onListingTitle(listingTitle.map((listing) => listing))
      }}
      onClear={() => onFilter(filter)}
      combineType="both"
      customWidth="185px"
      isSingle={false}
      placeholder={listingTitlesText}
      dropDownList={tenants}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      filter={filter}
      debounceFetcher={multiListingTitleDebounceFetcher}
      showArrow={false}
      handlePlaceholder={multiListingTitleHandlePlaceholder}
      handleOptionChange={multiListingTitleHandleChange}
      handleValue={multiListingTitleHandleValue}
      renderOption={renderMultiListingTitleOption}
    />
  )

  const renderMultiOptionSelector = (
    <ReusableSelector
      showSearch
      nativeSearch
      onOptionsChange={(opts) => onServiceStatus(opts)}
      onClear={() => onFilter({ ...filter, serviceStatuses: [] })}
      combineType="left"
      customWidth="200px"
      isSingle={false}
      placeholder={'Service Status'}
      dropdownClassName={dropdownClassName}
      defaultValues={filter?.serviceStatuses}
      renderOption={renderMultiOption}
      dropDownList={connectorServiceStatus.map((v) => v)}
      showMagnifySVG={false}
      maxTagCount={0}
      filter={filter}
      isDebounceFetcher={false}
      debounceFetcher={undefined}
      showArrow={false}
      handlePlaceholder={multiServiceStatusPlaceholder}
      handleOptionChange={multiServiceStatusHandleChange}
      searchData={onFetchListings}
    />
  )

  const renderMultiListingIdSelector = (
    <ReusableSelector
      showSearch
      onOptionsChange={(listingId: number[]) => onListingIds(listingId)}
      onClear={() => onFilter(filter)}
      combineType="both"
      customWidth="185px"
      isSingle={false}
      placeholder={'Listing Id (s)'}
      dropDownList={tenants}
      showMagnifySVG={false}
      maxTagCount={0}
      isDebounceFetcher={true}
      filter={filter}
      debounceFetcher={multiListingIdDebounceFetcher}
      showArrow={false}
      handlePlaceholder={multiListingIdHandlePlaceholder}
      handleOptionChange={multiListingIdHandleChange}
      renderOption={renderMultiListingIdOption}
    />
  )
  return (
    <>
      {isDesktop || isLaptop ? (
        <Space wrap size={[0, 6]} style={{ display: 'flex' }}>
          {renderMultTenantSelector}
          {isAdmin && (
            <>
              <LineBreak />
              {renderMasterAccountSelector()}
            </>
          )}
          <LineBreak />
          {renderMultiListingTitleSelector}
          <LineBreak />
          {renderMultiListingIdSelector}
          <LineBreak />
          {renderMultiOptionSelector}
          <ButtonIcon
            icon={magnifyBlackSVG}
            loading={props.loading}
            disabled={props.loading}
            fetchData={onFetchListings}
            withinSearchBar
          />
          {selectedListings && selectedListings.length >= 1 ? (
            <ModifyButtonWrapper>
              <ButtonIconCircle
                fetchData={() => setShowListingModal(true)}
                loading={false}
                disabled={false}
                icon={editGreySVG}
                primaryColor={theme.colors.white}
                secondaryColor={theme.colors.white}
                borderColor={theme.colors.lightGray}
              />
            </ModifyButtonWrapper>
          ) : null}
        </Space>
      ) : (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12}>
            {renderMultTenantSelector}
          </Col>
          {isAdmin && (
            <Col xs={24} md={12}>
              {renderMasterAccountSelector()}
            </Col>
          )}
          <Col xs={24} md={12}>
            {renderMultiListingTitleSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiListingIdSelector}
          </Col>
          <Col xs={24} md={12}>
            {renderMultiOptionSelector}
          </Col>
          <Col span={24}>
            <Button block type="default" disabled={props.loading} onClick={onFetchListings}>
              {searchText}
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ListingAllSelectors
