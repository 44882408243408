import { useState } from 'react'
import { PageHeader } from 'antd'

import { Box } from '../../../atom/box'
import { AlertError } from '../../../components/error'
import { useAppState } from '../../../state'
import { BackgroundContainer } from '../../../atom/layout/backgroundContainer'
import { useReports } from '../../../hooks/useReports'
import ReportsSelectors from '../../../features/reports/components/search'
import { withAuthenticatedLayout } from '../../../components/layouts/layout'
import { ButtonIconCircle } from '../../../atom/button'
import { questionMarkSVG } from '../../../assets/svg/questionMark'
import { ReportInfoModal } from '../view/report-info-modal'
import { QuestionButtonWrapper } from '../../../atom/reports'
import Iframe from '../../../components/iframe'

const ReportsRedesignBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const [showInfoModal, setShowInfoModal] = useState(false)

  const { IsLaptop, IsDesktop, IsTablet, IsMobile } = useAppState()

  const isDesktop = IsDesktop()
  const isLaptop = IsLaptop()
  const isTablet = IsTablet()
  const isMobile = IsMobile()
  const {
    error,
    url,
    tenants,
    loading,
    startDate,
    endDate,
    getReports,
    handleChangeDate,
    setTenants,
    setListingTitles,
    handleLocation,
    setMasterAccount,
  } = useReports()

  const handleInfoModal = () => {
    setShowInfoModal(!showInfoModal)
  }

  const renderTitles = () => (
    <ReportsSelectors
      tenants={tenants}
      loading={loading}
      startDate={startDate}
      endDate={endDate}
      getReports={getReports}
      handleChangeDate={handleChangeDate}
      setTenants={setTenants}
      setListingTitles={setListingTitles}
      handleLocation={handleLocation}
      dropdownClassName={'not-in-modal-selector'}
      setMasterAccount={setMasterAccount}
    />
  )

  const renderButton = () => (
    <QuestionButtonWrapper responsive={isTablet || isMobile}>
      <ButtonIconCircle fetchData={handleInfoModal} loading={false} disabled={false} icon={questionMarkSVG} />
    </QuestionButtonWrapper>
  )

  const renderIFrame = (height: number) => {
    return (
      <Box>
        <Iframe title="metabase-reports" loading={loading ? 'lazy' : 'eager'} url={url} height={height} />
      </Box>
    )
  }

  const renderReportsView = () => {
    if (isDesktop || isLaptop) {
      return renderIFrame(650)
    } else {
      return renderIFrame(500)
    }
  }

  return (
    <BackgroundContainer>
      <AlertError error={error} />
      <PageHeader
        style={{ paddingTop: '13px' }}
        title={renderTitles()}
        extra={isDesktop || isLaptop ? renderButton() : null}
      />
      {renderReportsView()}
      {showInfoModal && <ReportInfoModal onCancel={handleInfoModal} />}
    </BackgroundContainer>
  )
}

export const ReportsRedesignPage = withAuthenticatedLayout(ReportsRedesignBasePage)
