import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Form, message } from 'antd'
import { SwtchError } from '../models/error'
import { ListingFull } from '../models/listing'
import { GetListing, UpdateListing, UpdateListingsStatus } from '../services/data-provider/listing'
import { useFormatMessage } from '../helpers/intl'
import { addQueryString } from '../helpers/url'
import { Links } from '../components/routes/paths'
import { useTagging } from './useTagging'

export const useListing = () => {
  // @ts-ignore
  const { listingId } = useParams()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<SwtchError>()
  const [listing, setListing] = useState<ListingFull>()
  const [form] = Form.useForm()

  const successEditListingText = useFormatMessage('dashboard.updated.listing', 'Listing has been updated successfully')

  const { applyTagToObject, getTaggingsWithoutPagination, taggings, setTaggings, dropdownRender } = useTagging()

  useEffect(() => {
    fetchListing()
    getTaggingsWithoutPagination('Listing')
  }, [])

  const initialValues = initializingValues(listing)

  const fetchListing = () => {
    setError(undefined)
    setLoading(true)
    GetListing(listingId)
      .then((resp) => {
        setListing(resp)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const onFinish = () => {
    setError(undefined)
    setLoading(true)
    UpdateListing(listingId, {
      id: listingId,
      authorId: form.getFieldValue('authorId'),
      title: form.getFieldValue('title'),
      additionalServiceFee: form.getFieldValue('additionalServiceFee'),
      trailerAccessible: form.getFieldValue('trailerAccessible'),
      fundingSources: form.getFieldValue('fundingSources'),
      commisionPercent:
        form.getFieldValue('commisionPercent') === '2.9% + 30c'
          ? 0.0
          : form.getFieldValue('customComissionFee') ?? form.getFieldValue('commisionPercent'),
      partnerCommissionPercent: form.getFieldValue('partnerCommissionPercent'),
      accessType: form.getFieldValue('accessType'),
      carDetailRequired: form.getFieldValue('carDetailRequired'),
      customNotification: form.getFieldValue('customNotification'),
      output: form.getFieldValue('output'),
      tenantId: `${form.getFieldValue('tenantId')}`,
      kind: form.getFieldValue('kind'),
      paymentType: form.getFieldValue('paymentType'),
      hidden: form.getFieldValue('hidden'),
      stripeAccountId: form.getFieldValue('stripeAccountId'),
      description: form.getFieldValue('description'),
      enableLoadManagementAutoStop: form.getFieldValue('enableLoadManagementAutoStop'),
      addCloudLoadManagement: form.getFieldValue('addCloudLoadManagement'),
      shavingEnabled: form.getFieldValue('shavingEnabled'),
      shavingPrimaryZoneStrategy: form.getFieldValue('shavingPrimaryZoneStrategy'),
      shavingSecondaryZoneStrategy: form.getFieldValue('shavingSecondaryZoneStrategy'),
      shavingEventSource: form.getFieldValue('shavingEventSource'),
    })
      .then(() => {
        UpdateListingsStatus({
          listing_ids: [listingId],
          new_status: form.getFieldValue('serviceStatus'),
          reason: form.getFieldValue('reason'),
        })
          .then((resp) => {
            applyTagToObject([{ id: parseFloat(listingId) }], 'listings')
              .then(() => {
                setTimeout(() => {
                  setLoading(false)
                  history.push(addQueryString(Links.listings(), {}))
                }, 2000)
              })
              .catch((err) => setError(err))
          })
          .catch((err) => setError(err))
          .finally(() => setLoading(false))
        message.success(successEditListingText)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  return {
    loading,
    error,
    listing,
    initialValues,
    form,
    onFinish,
    fetchListing,
    taggings,
    setTaggings,
    listingId,
    dropdownRender,
  }
}

const initializingValues = (listing: ListingFull | undefined) => {
  return {
    title: listing?.title,
    description: listing?.description,
    kind: listing?.kind,
    paymentType: listing?.paymentType,
    fundingSources: listing?.fundingSources,
    trailerAccessible: listing?.trailerAccessible,
    serviceStatus: listing?.connector?.serviceStatus,
    reason: listing?.reason,

    // author
    authorId: listing?.authorId,
    listingAuthor: listing?.person?.name,
    stripeAccountId: listing?.stripeAccountId,
    // stripeAccount: listing?.stripeAccount,
    stripeAccountName: listing?.stripeAccount?.name,
    // author: listing?.author,

    // map & access
    accessType: listing?.accessType === null ? 'public' : listing?.accessType,
    hidden: listing?.hidden,
    carDetailRequired: listing?.carDetailRequired,

    // charger
    tenantId: listing?.tenantId,
    // tenant: listing?.tenant,
    tenantName: listing?.tenant?.displayName || listing?.tenant?.name,
    output: listing?.output,
    customNotification: listing?.customNotification,

    // commmission & fee
    commisionPercent:
      listing?.additionalServiceFee === 'on' || listing?.additionalServiceFee === '1'
        ? '2.9% + 30c'
        : listing?.partnerCommissionPercent
        ? 'defineNew'
        : listing?.commisionPercent,
    additionalServiceFee: listing?.additionalServiceFee,
    customComissionFee: listing?.commisionPercent,
    partnerCommissionPercent: listing?.partnerCommissionPercent,
    addCloudLoadManagement: listing?.addCloudLoadManagement,
    enableLoadManagementAutoStop: listing?.enableLoadManagementAutoStop,

    // peak shaving
    shavingEnabled: listing?.shavingEnabled,
    shavingPrimaryZoneStrategy: listing ? listing.shavingPrimaryZoneStrategy : 0,
    shavingSecondaryZoneStrategy: listing ? listing.shavingSecondaryZoneStrategy : 0,
    shavingEventSource: listing ? listing.shavingEventSource : 'manual',
  }
}
