import { useFormatMessage } from '../../helpers/intl'

export const usePeakShavingTranslation = () => {
  const peakShavingText = useFormatMessage('dashboard.peakShaving', 'Peak Shaving')
  const demandResponseText = useFormatMessage('dashboard.chargerPage.DREventTab.heading', 'Demand Response')
  const searchProgramsText = useFormatMessage('dashboard.peakShaving.programsPage.searchPrograms', 'Search Programs')
  const programStatusText = useFormatMessage('dashboard.peakShaving.programsPage.programStatus', 'Program Status')
  const newProgramText = useFormatMessage('dashboard.peakShaving.programsPage.newProgram', 'New Program')
  const publishedText = useFormatMessage('dashboard.peakShaving.programsPage.status.published', 'Published')
  const draftText = useFormatMessage('dashboard.peakShaving.programsPage.status.draft', 'Draft')
  const idText = useFormatMessage('dashboard.usersPage.table.heading.id', 'ID')
  const nameText = useFormatMessage('dashboard.usersPage.table.heading.name', 'Name')
  const categoryText = useFormatMessage('dashboard.listing.ListingsTable.categoryText', 'Category')
  const typeText = useFormatMessage('dashboard.peakShaving.programPage.program.type', 'Type')
  const dateText = useFormatMessage('dashboard.peakShaving.programPage.program.date', 'Date')
  const participationWindowText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.participationWindow',
    'Participation Window',
  )
  const eventNotificationText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventNotification',
    'Event Notification',
  )
  const descriptionText = useFormatMessage('dashboard.text.description', 'Description')
  const programNameText = useFormatMessage('dashboard.peakShaving.programPage.programName', 'Program Name')
  const aggregatorNameText = useFormatMessage('dashboard.peakShaving.programPage.aggregatorName', 'Aggregator Name')
  const statusText = useFormatMessage('dashboard.peakShaving.programPage.status', 'Status')

  const voluntaryText = useFormatMessage('dashboard.peakShaving.programPage.program.voluntary', 'Voluntary')
  const nonVoluntaryText = useFormatMessage('dashboard.peakShaving.programPage.program.nonVoluntary', 'Non-Voluntary')

  const cannotExceedText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.cannotExceed',
    '-1 cannot exceed 255 characters!',
  )
  const participationDetailsText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.participationDetails',
    'Participation Window Details',
  )
  const participationDetailsPlaceholderText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.participationDetails.placeholder',
    'Participation Window Details (4-hour Window)',
  )
  const eventNotificationDetailsText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventNotificationDetails',
    'Event Notification Window Details',
  )
  const eventNotificationDetailsPlaceholderText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventNotificationDetails.placeholder',
    'Event Notification Window Details (ie. 2 Hour Notice)',
  )

  const programText = useFormatMessage('dashboard.peakShaving.programPage.program', 'Program')
  const newEventText = useFormatMessage('dashboard.peakShaving.programPage.program.newEventBtn', 'New Event')
  const archiveText = useFormatMessage('dashboard.peakShaving.programPage.program.archiveBtn', 'Archive Program')
  const arhiveProgramWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.archiveWarning',
    'All Published events will be Cancelled and you can no longer add listings or events to the program.',
  )
  const programDetailsText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.programsDetailTab',
    'Program Details',
  )
  const eventsText = useFormatMessage('dashboard.peakShaving.programPage.program.eventsTab', 'Events')
  const publishProgramText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.publishProgram',
    'Publish Program',
  )
  const modifyText = useFormatMessage('dashboard.text.modify', 'Modify')
  const cancelText = useFormatMessage('dashboard.text.cancel', 'Cancel')
  const saveChangesText = useFormatMessage('dashboard.text.saveChanges', 'Save Changes')
  const publishProgramInfoText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.publishInfoText',
    'Publish program to add events',
  )
  const scheduleText = useFormatMessage('dashboard.peakShaving.programPage.program.schedule', 'Schedule')
  const updatedByText = useFormatMessage('dashboard.peakShaving.programPage.program.schedule.updatedBy', 'Updated by')
  const allowOptOutText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.schedule.allowOptOut',
    'Allow Opt Out',
  )

  const startDateText = useFormatMessage('dashboard.mobileCalendar.startDate', 'Start Date')
  const endDateText = useFormatMessage('dashboard.mobileCalendar.endDate', 'End Date')
  const intervalText = useFormatMessage('dashbaord.text.interval', 'Interval')
  const notestText = useFormatMessage('dashboard.tenantPage.deployment.notes.heading', 'Notes')
  const editScheduleText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.schedule.editSchedule',
    'Edit Schedule',
  )
  const removeScheduleText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.schedule.removeSchedule',
    'Remove Schedule',
  )

  const eventDateText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventsTable.eventDate',
    'Event Date',
  )
  const eventStatusText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventsTable.eventStatus',
    'Event Status',
  )
  const duplicateEventText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventsTable.duplicateEvent',
    'Duplicate Event',
  )
  const cancelEventText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventsTable.cancelEvent',
    'Cancel Event',
  )

  const removeListingText = useFormatMessage('dashboard.peakShaving.programPage.removeListing', 'Remove Listing')
  const removeListingWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.removeListing.info',
    'Are you sure you want to remove the listing from the program?',
  )

  const removeTenantText = useFormatMessage('dashboard.peakShaving.programPage.removeTenant', 'Remove Participant')

  const removeTenantWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.removeTenant.info',
    'Are you sure you want to remove the tenant from the program?',
  )

  const removeParticipantText = useFormatMessage(
    'dashboard.peakShaving.programPage.removeParticipant',
    'Remove Participant',
  )
  const removeParticipantgWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.removeParticipant.info',
    'Are you sure you want to remove the participant from the program?',
  )

  const publishText = useFormatMessage('dashboard.peakShaving.programPage.modal.publishText', 'Publish')
  const zoneText = useFormatMessage('dashboard.peakShaving.programPage.modal.zoneText', 'Zone')
  const startTimeText = useFormatMessage('dashboard.peakShaving.programPage.modal.startTimeText', 'Start Time')
  const endTimeText = useFormatMessage('dashboard.peakShaving.programPage.modal.endTimeText', 'End Time')
  const frequencyText = useFormatMessage('dashboard.peakShaving.programPage.modal.frequencyText', 'Frequency')
  const eventScheduleText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.eventScheduleText',
    'Event Schedule',
  )

  const createEventText = useFormatMessage('dashboard.peakShaving.programPage.modal.createEventText', 'Create Event')
  const noneZoneText = useFormatMessage('dashboard.peakShaving.programPage.modal.zone.none', 'None')
  const primaryZoneText = useFormatMessage('dashboard.peakShaving.programPage.modal.zone.primary', 'Primary Peak Zone')
  const secondaryZoneText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.zone.secondary',
    'Secondary Peak Zone',
  )

  const weekdayText = useFormatMessage('dashboard.text.weekday', 'Weekday')
  const weekendText = useFormatMessage('dashboard.text.weekend', 'Weekend')
  const createScheduleText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.createScheduleText',
    'Create Schedule',
  )
  const zoneWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.zoneWarning',
    'Please select a zone!',
  )

  const startTimeZoneWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.startTimeWarning',
    'Start Time cannot overlap!',
  )

  const createNewProgramText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.createNewProgramText',
    'Create New Program',
  )

  const updateProgramText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.updateProgramText',
    'Update Program',
  )

  const submitText = useFormatMessage('dashboard.createNewListing.submitBtn', 'Submit')

  const isRquiredText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.nameRequiredWarningText',
    '-1 is required!',
  )

  const programCreatedSuccessText = useFormatMessage(
    'dashboard.peakShaving.programsPage.programCreatedSuccessMessage',
    'Program -1 has been created successfully',
  )

  const programUpdatedSuccessText = useFormatMessage(
    'dashboard.peakShaving.programsPage.programUpdatedSuccessMessage',
    'Program -1 has been created successfully',
  )
  const listingsText = useFormatMessage('dashboard.listing.ListingsText', 'Listings')

  const listingText = useFormatMessage('dashboard.createNewListing.listingStep.heading', 'Listing Title')
  const tenantText = useFormatMessage('dashboard.text.tenant', 'Site')
  const serialNumberText = useFormatMessage('dashboard.charger.serialNumber', 'Serial Number')
  const outputText = useFormatMessage('dashboard.peakShaving.programPage.listing.outputText', 'Output')
  const removeScheduleWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.removeSchedule.warning',
    'Are you sure you want to remove the schedule?',
  )
  const cancelEventWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.cancelEvent.warning',
    'Are you sure you want to cancel the event?',
  )

  const programInfoText = useFormatMessage(
    'dashboard.peakShaving.programPage.listingsTable.info',
    'Add Listings to publish program',
  )

  const participantInfoText = useFormatMessage(
    'dashboard.peakShaving.programPage.participantTable.info',
    'Add Participant to publish program',
  )

  const addListingsText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.listings.addListings',
    'Add Listings',
  )

  const addListingsWarningText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.listings.addListings.warning',
    'Please enter a listing in the same time zone -1 as "-2"',
  )

  const updateText = useFormatMessage('dashboard.text.update', 'Update')

  const eventsTableinfoText = useFormatMessage(
    'dashboard.peakShaving.programPage.program.eventsTable.info',
    'Once an event is generated, it will appear here',
  )

  const listingsAddedSuccessMessage = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.listings.addListings.success',
    '-1 Listings have been added to the program successfully!',
  )

  const dailyScheduleInfoText = useFormatMessage(
    'dashboard.peakShaving.programPage.dailySchedule.info',
    'If a schedule is created, the system will publish and generate events based on the schedule defined.',
  )

  const dailyText = useFormatMessage('dashboard.text.daily', 'Daily')
  const yesText = useFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.yes', 'Yes')
  const noText = useFormatMessage('dashboard.tenantPage.overviewTab.o_m_opt_in.no', 'No')

  const eventUpdateSuccessText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.updateEvent.success',
    'Event and schedule successfully updated!',
  )
  const eventCreationSuccessText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.createEvent.success',
    'Event has been created successfully!',
  )

  const dailyEventUpdateSuccessText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.dailyEvent.update.success',
    'Daily Event and schedule successfully updated!',
  )

  const dailyEventCreateSuccessText = useFormatMessage(
    'dashboard.peakShaving.programPage.modal.dailyEvent.create.success',
    'Daily Schedule has been created successfully!',
  )

  const participantText = useFormatMessage('dashboard.peakShaving.programPage.participant', 'Participant')
  const participantsText = useFormatMessage('dashboard.peakShaving.programPage.participants', 'Participants')
  const participantIDText = useFormatMessage('dashboard.peakShaving.programPage.participantID', 'Participant ID')

  const allListingsText = useFormatMessage('dashboard.peakShaving.programPage.allListings', 'All Listings')
  const selectListingsText = useFormatMessage('dashboard.peakShaving.programPage.selectListings', 'Select Listings')
  const selectedListingsText = useFormatMessage(
    'dashboard.peakShaving.programPage.selectedListings',
    'Selected Listings',
  )
  const mixedText = useFormatMessage('dashboard.peakShaving.programPage.mixed', 'Mixed')

  const addParticipantsText = useFormatMessage('dashboard.peakShaving.programPage.addParticipants', 'Add Participants')
  const editParticipantsText = useFormatMessage(
    'dashboard.peakShaving.programPage.editParticipants',
    'Edit Participants',
  )

  const addParticipantSuccessMessage = useFormatMessage(
    'dashboard.peakShaving.programPage.addParticipants.success',
    'Participant added successfully',
  )
  const updateParticipantSuccessMessage = useFormatMessage(
    'dashboard.peakShaving.programPage.updateParticipants.success',
    'Participant updated successfully',
  )

  const listingsHelperText = useFormatMessage(
    'dashboard.peakShaving.programPage.listing.helper',
    'Only listings from this tenant will be added',
  )

  return {
    peakShavingText,
    searchProgramsText,
    idText,
    nameText,
    programNameText,
    aggregatorNameText,
    statusText,
    categoryText,
    typeText,
    descriptionText,
    programStatusText,
    newProgramText,
    publishedText,
    draftText,
    programText,
    newEventText,
    archiveText,
    programDetailsText,
    eventsText,
    publishProgramText,
    modifyText,
    cancelText,
    saveChangesText,
    publishProgramInfoText,
    scheduleText,
    updatedByText,
    allowOptOutText,
    startDateText,
    endDateText,
    intervalText,
    notestText,
    editScheduleText,
    removeScheduleText,
    eventDateText,
    eventStatusText,
    duplicateEventText,
    cancelEventText,
    publishText,
    zoneText,
    startTimeText,
    endTimeText,
    frequencyText,
    eventScheduleText,
    createEventText,
    noneZoneText,
    primaryZoneText,
    secondaryZoneText,
    weekdayText,
    weekendText,
    createScheduleText,
    zoneWarningText,
    startTimeZoneWarningText,
    arhiveProgramWarningText,
    createNewProgramText,
    updateProgramText,
    submitText,
    isRquiredText,
    programCreatedSuccessText,
    programUpdatedSuccessText,
    listingText,
    tenantText,
    serialNumberText,
    outputText,
    removeScheduleWarningText,
    cancelEventWarningText,
    programInfoText,
    addListingsText,
    addListingsWarningText,
    updateText,
    eventsTableinfoText,
    listingsAddedSuccessMessage,
    dailyScheduleInfoText,
    dailyText,
    yesText,
    noText,
    removeListingText,
    removeListingWarningText,
    eventCreationSuccessText,
    dailyEventUpdateSuccessText,
    dailyEventCreateSuccessText,
    eventUpdateSuccessText,
    participationDetailsText,
    participationDetailsPlaceholderText,
    eventNotificationDetailsText,
    eventNotificationDetailsPlaceholderText,
    voluntaryText,
    nonVoluntaryText,
    dateText,
    participationWindowText,
    eventNotificationText,
    participantsText,
    participantIDText,
    allListingsText,
    selectedListingsText,
    mixedText,
    addParticipantsText,
    editParticipantsText,
    removeParticipantText,
    removeParticipantgWarningText,
    removeTenantText,
    removeTenantWarningText,
    addParticipantSuccessMessage,
    updateParticipantSuccessMessage,
    participantInfoText,
    selectListingsText,
    listingsText,
    demandResponseText,
    cannotExceedText,
    listingsHelperText,
    participantText,
  }
}
