import { useFormatMessage } from '../../helpers/intl'

export const useReportTranslation = () => {
  const buttonTitle = useFormatMessage('dashboard.reports.modal.title.generateReport', 'Generate Report')
  const erroredTrxsBtnTitle = useFormatMessage(
    'dashboard.reports.modal.title.generateErroredReport',
    'Generate Errored Transactions Report',
  )
  const trxText = useFormatMessage('dashboard.reports.table.row.type.transaction', 'Transactions')
  const erroredTrxText = useFormatMessage('dashboard.reports.table.row.type.erroredTransaction', 'Errored Transactions')
  const todayText = useFormatMessage('dashboard.calendar.today', 'Today')
  const weekText = useFormatMessage('dashboard.calendar.thisWeek', 'This Week')
  const monthText = useFormatMessage('dashboard.calendar.thisMonth', 'This Month')
  const yearText = useFormatMessage('dashboard.calendar.thisYear', 'This Year')
  const previousQuarterText = useFormatMessage('dashboard.calendar.previousQuarter', 'Previous Quarter')
  const reportsText = useFormatMessage('dashboard.report.heading', 'Reports')
  const searchText = useFormatMessage('dashboard.text.search', 'Search')
  const financialSummaryReportTitle = useFormatMessage(
    'dashboard.reports.modal.title.financialSummaryReportTitle',
    'Financial Summary Report',
  )
  const financialSummaryReportBody = useFormatMessage(
    'dashboard.reports.modal.body.financialSummaryReportBody',
    'Highlights all charging transactions and provides a breakdown of the total amount paid by drivers, fees collected by SWTCH, and remittance amounts.',
  )
  const utilityChargeSessionReportTitle = useFormatMessage(
    'dashboard.reports.modal.title.utilityChargeSessionReportTitle',
    'Utility Charge Session Report',
  )
  const utilityChargeSessionReportBody = useFormatMessage(
    'dashboard.reports.modal.body.utilityChargeSessionReportBody',
    'Provides insight into charging session specifics, including energy consumption, session details, and more.',
  )
  const utilityIntervalReportTitle = useFormatMessage(
    'dashboard.reports.modal.title.utilityIntervalReportTitle',
    'Utility Interval Report',
  )
  const utilityIntervalReportBody = useFormatMessage(
    'dashboard.reports.modal.body.utilityIntervalReportBody',
    'A frequently requested report by utility programs, offering transaction breakdowns at 15-minute intervals. Includes data on energy consumption and peak/average power for comprehensive analysis of charging sessions.',
  )

  return {
    buttonTitle,
    erroredTrxsBtnTitle,
    trxText,
    erroredTrxText,
    todayText,
    weekText,
    monthText,
    yearText,
    previousQuarterText,
    reportsText,
    searchText,
    financialSummaryReportTitle,
    financialSummaryReportBody,
    utilityChargeSessionReportTitle,
    utilityChargeSessionReportBody,
    utilityIntervalReportTitle,
    utilityIntervalReportBody,
  }
}
