import { useEffect, useState } from 'react'

import { useAppState } from '../state'

import { SwtchError } from '../models/error'
import { TenantRef } from '../models/tenant'
import { NewListing } from '../models/listing'
import { ListingFilter } from '../models/filter'
import { PaginationMeta } from '../models/pagination'
import { ConnectorServiceStatus, listingServiceStatusesDefault } from '../models/connector'

import { GetListings } from '../services/data-provider/listing'
import { MasterAccountRef } from 'models/master-account'

const initialPagination = { currentPage: 1, perPage: 1, totalEntries: 2 }

export const useListings = () => {
  const { selectedTenant } = useAppState()

  const [tenants, setTenants] = useState<TenantRef[]>([])
  const [pagination, setPagination] = useState<PaginationMeta>(initialPagination)
  const [listings, setListings] = useState<NewListing[]>([])
  const [selectedListings, setSelectedListings] = useState<NewListing[]>([])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<SwtchError>()
  const [showSearchQuery, setShowSearchQuery] = useState(false)
  const [masterAccount, setMasterAccount] = useState<MasterAccountRef | undefined>()

  const [filter, setFilter] = useState<ListingFilter>({
    page: 1,
    defaultTenant: selectedTenant,
    serviceStatuses: listingServiceStatusesDefault,
  })

  useEffect(() => {
    fetchListings() //initial fetch
  }, [])

  const fetchListings = (page = 1) => {
    setLoading(true)
    setError(undefined)
    GetListings({ ...filter, page, masterAccount: masterAccount })
      .then((resp) => {
        if ('data' in resp) {
          setListings(resp.data)
          setPagination(resp.pagination)
          setFilter({ ...filter, page })
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }

  const fetchListingsWithQuery = () => {
    setShowSearchQuery(true)
    fetchListings()
    setSelectedListings([])
  }

  const handlePaginationChange = (page: number, pageSize?: number) => {
    fetchListings(page)
    return
  }

  const handleTenantSelection = (tenants: TenantRef[]) => setFilter({ ...filter, tenants })

  const handleListingTitle = (listingTitle: string[]) => setFilter({ ...filter, listingTitle })

  const handleListingIds = (listingId: number[]) => setFilter({ ...filter, listingId })

  const handleServiceStatuses = (serviceStatuses: ConnectorServiceStatus[]) => setFilter({ ...filter, serviceStatuses })

  const selectAllListings = async () => {
    setLoading(true)
    setError(undefined)
    GetListings({ ...filter, allListings: true })
      .then((listings) => {
        if (Array.isArray(listings)) setSelectedListings(listings)
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedListings.map((listing) => parseFloat(`${listing.id}`)),
    onSelect: (selectedRow: any, selected: boolean) => {
      if (selected) {
        setSelectedListings([...selectedListings, selectedRow])
      } else {
        const filteredSelectedListing = selectedListings.filter((listing) => `${listing.id}` !== `${selectedRow.id}`)
        setSelectedListings(filteredSelectedListing)
      }
    },
    onSelectAll: (selected: boolean, selectedRows: NewListing, remainingListing: any) => {
      //TODO: type for remainingListing
      if (!selected) {
        setSelectedListings([])
        setSelectedListings([])
      } else {
        setSelectedListings([...selectedListings, ...remainingListing])
      }
    },
  }

  return {
    handlePaginationChange,
    handleTenantSelection,
    handleListingTitle,
    handleListingIds,
    handleServiceStatuses,
    fetchListings,
    fetchListingsWithQuery,
    setTenants,
    setFilter,
    selectAllListings,
    setMasterAccount,
    showSearchQuery,
    rowSelection,
    selectedListings,
    filter,
    tenants,
    listings,
    pagination,
    loading,
    error,
  }
}
