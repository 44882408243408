import queryString from 'query-string'
import { RouteComponentProps } from 'react-router'

import { withAuthenticatedLayout } from '../../components/layouts/layout'

import { TenantRef } from '../../models/tenant'
import {
  ConnectorServiceStatus,
  ConnectorServiceStatusAlias,
  ConnectorStatus,
  ConnectorStatusAlias,
} from '../../models/connector'
import { ChargersBasePageRedesign } from './chargers-base-page-redesign'
import { ChargersBasePage } from './chargers-base-page'
import { useAppState } from '../../state'

const ChargersPageWrapperRedesign: React.FC<RouteComponentProps> = (props) => {
  const { isClassicView } = useAppState()
  const { tenant_id, tenant_name, tenant_displayName, ocppStatus, serviceStatus, combineName } = queryString.parse(
    props.location.search,
  )
  let tenantRef: TenantRef | undefined = undefined
  let connOCPPStatus: ConnectorStatus[] | undefined = undefined
  let chargerServiceStatus: ConnectorServiceStatus[] | undefined = undefined

  if (tenant_displayName) {
    if (
      typeof tenant_id === 'string' &&
      typeof tenant_name === 'string' &&
      typeof tenant_displayName === 'string' &&
      typeof combineName === 'string'
    ) {
      tenantRef = { id: tenant_id, name: tenant_name, displayName: tenant_displayName, combineName: combineName }
    }
  } else {
    if (typeof tenant_id === 'string' && typeof tenant_name === 'string' && typeof combineName === 'string') {
      tenantRef = { id: tenant_id, name: tenant_name, combineName }
    }
  }
  if (typeof ocppStatus === 'string') {
    connOCPPStatus = ConnectorStatusAlias[ocppStatus] || undefined
  }
  if (typeof serviceStatus === 'string') {
    chargerServiceStatus = ConnectorServiceStatusAlias[serviceStatus] || undefined
  }
  return isClassicView ? (
    <ChargersBasePage
      defaultTenantRef={tenantRef}
      defaultOCPPStatus={connOCPPStatus}
      defaultServiceStatus={chargerServiceStatus}
    />
  ) : (
    <ChargersBasePageRedesign
      defaultTenantRef={tenantRef}
      defaultOCPPStatus={connOCPPStatus}
      defaultServiceStatus={chargerServiceStatus}
    />
  )
}

export const ChargersPageRedesign = withAuthenticatedLayout(ChargersPageWrapperRedesign)
