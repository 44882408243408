import { Link } from 'react-router-dom'

import { Button } from 'antd'
import { styled, theme } from '../theme'

type ButtonSize = 'large' | 'middle' | 'small'
type ButtonType = 'link' | 'text' | 'default' | 'ghost' | 'primary' | 'dashed'

interface props {
  loading: boolean
  icon: JSX.Element
  fetchData?: () => void
  text?: JSX.Element | string
  size?: ButtonSize
  type?: ButtonType
  withinSearchBar?: boolean
  withInSuffix?: boolean
  reusableSelectorSuffix?: boolean
  disabled: boolean
  primaryColor?: string
  secondaryColor?: string
  borderColor?: string
}

interface linkProps extends props {
  url: string
}

interface selectedProps {
  icon?: JSX.Element
  text?: JSX.Element | string
  click: () => void
  type: ButtonType
  size?: ButtonSize
  block?: boolean
  backgroundColor?: string
  disabled?: boolean
}

const StyledButton = styled.button<{
  loading: boolean
  disabled: boolean
  withinSearchBar: boolean
  withInSuffix: boolean
  reusableSelectorSuffix?: boolean
}>`
  height: ${(props) => (props.withinSearchBar || props.withInSuffix ? '37px' : '35px')};
  width: 44px;
  border: 0;
  border-radius: 40px;
  z-index: 1;

  svg {
    margin-top: ${(props) => (props.reusableSelectorSuffix ? '0px' : '4px')};
  }

  margin-left: ${(props) => (props.withinSearchBar ? '-45px' : 0)};
  position: relative;

  cursor: ${({ disabled, loading }) => (disabled || loading ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease-in-out;

  background-color: ${({ disabled, loading, theme }) =>
    disabled || loading ? theme.colors.lightGray : theme.colors.primary};
  &:hover {
    background-color: ${({ disabled, loading, theme }) =>
      disabled || loading ? theme.colors.lightGray : theme.colors.buttonHovering};
  }
`

const StyledCircleButton = styled.button<{
  loading: boolean
  disabled: boolean
  primaryColor?: string
  secondaryColor?: string
  borderColor?: string
}>`
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 40px;
  z-index: 1;

  svg {
    margin-left: 1px;
    margin-bottom: -1.5px;

    path {
      fill: ${({ disabled, theme }) => (disabled ? '#cdcdcd' : theme.colors.black)};
    }
  }

  margin-left: -35px;
  position: relative;

  border: 1px solid ${({ borderColor }) => borderColor};

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease-in-out;

  background-color: ${({ disabled, theme, primaryColor }) => (disabled ? theme.colors.cultered : primaryColor)};
  &:hover {
    background-color: ${({ disabled, theme, secondaryColor }) => (disabled ? theme.colors.cultered : secondaryColor)};
  }
`

export const ButtonIcon: React.FC<props> = ({
  loading,
  disabled,
  icon,
  fetchData,
  withinSearchBar = false,
  withInSuffix = false,
  reusableSelectorSuffix,
}) => {
  return (
    <StyledButton
      disabled={disabled}
      loading={loading}
      onClick={fetchData}
      withinSearchBar={withinSearchBar}
      withInSuffix={withInSuffix}
      reusableSelectorSuffix={reusableSelectorSuffix}
    >
      {icon}
    </StyledButton>
  )
}

export const ButtonIconCircle: React.FC<props> = ({
  loading,
  disabled,
  icon,
  fetchData,
  primaryColor = theme.colors.primary,
  secondaryColor = theme.colors.buttonHovering,
  borderColor = theme.colors.primary,
}) => {
  return (
    <StyledCircleButton
      disabled={disabled}
      loading={loading}
      onClick={fetchData}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      borderColor={borderColor}
    >
      {icon}
    </StyledCircleButton>
  )
}

export const ButtonLink: React.FC<linkProps> = ({ loading, icon, text, url }) => (
  <Link to={url}>
    <Button size="large" type="primary" disabled={loading} icon={icon}>
      <span className="regular-cap">{text}</span>
    </Button>
  </Link>
)

export const ButtonModal: React.FC<selectedProps> = ({
  icon,
  text,
  click,
  type,
  size = 'middle',
  block = false,
  backgroundColor,
  disabled = false,
}) => (
  <Button
    size={size}
    icon={icon}
    type={type}
    onClick={click}
    block={block}
    disabled={disabled}
    style={{ backgroundColor, transition: 'all 0.2s ease-in-out' }}
  >
    <span className="regular-cap">{text ? text : ''}</span>
  </Button>
)
