export const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
    md: {
      span: 12,
    },
    lg: {
      span: 12,
    },
  },
}

export const oneButtonLayout = {
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
    md: {
      offset: 7,
      span: 11,
    },
    lg: {
      offset: 7,
      span: 10,
    },
    xl: {
      offset: 6,
      span: 12,
    },
    xxl: {
      offset: 8,
      span: 8,
    },
  },
}

export const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 6,
      span: 24,
    },
    sm: {
      offset: 9,
      span: 16,
    },
    md: {
      offset: 9,
      span: 16,
    },
    lg: {
      offset: 10,
      span: 16,
    },
    xl: {
      offset: 10,
      span: 16,
    },
    xxl: {
      offset: 10,
      span: 16,
    },
  },
}

export const formLayout = {
  labelCol: {
    md: {
      span: 7,
    },
    lg: {
      span: 7,
    },
    xl: {
      span: 8,
    },
    xxl: {
      span: 8,
    },
  },
  wrapperCol: {
    md: {
      span: 11,
    },
    lg: {
      span: 10,
    },
    xl: {
      span: 8,
    },
    xxl: {
      span: 8,
    },
  },
}

export const labelOnTheLeftField = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 12 },
    md: { span: 12 },
    lg: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 10 },
    md: { span: 10 },
    lg: { span: 10 },
  },
}
export const labelOnTheRightField = {
  labelCol: {
    xs: { span: 2 },
    sm: { span: 3 },
    md: { span: 3 },
    lg: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 9 },
    md: { span: 9 },
    lg: { span: 10 },
  },
}
