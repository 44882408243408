import { theme } from '../../theme'

export const tenantSVG = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="tenantSelectorIcon"
  >
    <path
      d="M16.3636 15.9H18V17.5H0V15.9H1.63636V1.3C1.63636 1.08783 1.72256 0.884344 1.876 0.734315C2.02944 0.584285 2.23755 0.5 2.45455 0.5H15.5455C15.7624 0.5 15.9706 0.584285 16.124 0.734315C16.2774 0.884344 16.3636 1.08783 16.3636 1.3V15.9ZM5.72727 8.7V10.3H8.18182V8.7H5.72727ZM5.72727 5.5V7.1H8.18182V5.5H5.72727ZM5.72727 11.9V13.5H8.18182V11.9H5.72727ZM9.81818 11.9V13.5H12.2727V11.9H9.81818ZM9.81818 8.7V10.3H12.2727V8.7H9.81818ZM9.81818 5.5V7.1H12.2727V5.5H9.81818Z"
      fill={theme.colors.primary}
    />
  </svg>
)

export const tenantWhiteSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M16.3636 15.4H18V17H0V15.4H1.63636V1.8C1.63636 1.58783 1.72256 1.38434 1.876 1.23431C2.02944 1.08429 2.23755 1 2.45455 1H15.5455C15.7625 1 15.9706 1.08429 16.124 1.23431C16.2774 1.38434 16.3636 1.58783 16.3636 1.8V15.4ZM5.72727 8.2V9.8H8.18182V8.2H5.72727ZM5.72727 5V6.6H8.18182V5H5.72727ZM5.72727 11.4V13H8.18182V11.4H5.72727ZM9.81818 11.4V13H12.2727V11.4H9.81818ZM9.81818 8.2V9.8H12.2727V8.2H9.81818ZM9.81818 5V6.6H12.2727V5H9.81818Z"
      fill={theme.colors.white}
    />
  </svg>
)

export const tenantGreySVG = (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2234_9669)">
      <path
        d="M18.1818 17.6111H20V19.3889H0V17.6111H1.81818V1.38889C1.81818 1.15314 1.91396 0.927049 2.08445 0.760349C2.25494 0.59365 2.48617 0.5 2.72727 0.5H17.2727C17.5138 0.5 17.7451 0.59365 17.9156 0.760349C18.086 0.927049 18.1818 1.15314 18.1818 1.38889V17.6111ZM6.36364 9.61111V11.3889H9.09091V9.61111H6.36364ZM6.36364 6.05556V7.83333H9.09091V6.05556H6.36364ZM6.36364 13.1667V14.9444H9.09091V13.1667H6.36364ZM10.9091 13.1667V14.9444H13.6364V13.1667H10.9091ZM10.9091 9.61111V11.3889H13.6364V9.61111H10.9091ZM10.9091 6.05556V7.83333H13.6364V6.05556H10.9091Z"
        fill={theme.colors.darkGray}
      />
    </g>
    <defs>
      <clipPath id="clip0_2234_9669">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)
