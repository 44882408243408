import { Badge } from 'antd'

import { useFormatMessage } from '../../helpers/intl'
import { renderPeakShavingStatus } from '../../helpers/status'
import { styled, theme } from '../../theme'

export const allStatus = ['draft', 'published', 'archived', 'cancelled', 'completed', 'overridden', 'created'] as const
export type AllStatus = typeof allStatus[number]

interface props {
  state: any
}

const PeakShavingBadgeContainer = styled.div`
  .ant-scroll-number.ant-badge-count.ant-badge-multiple-words {
    text-transform: capitalize;
    font-size: 11px;
  }
  .draft .ant-scroll-number.ant-badge-count.ant-badge-multiple-words {
    color: ${(props) => props.theme.colors.orangePeel} !important;
  }
`

const PeakShavingStatusBadge: React.FC<props> = ({ state }) => {
  const [status, backgroundColor, fontColor] = getColorStatus(state)
  const translatedStatus = useFormatMessage(renderPeakShavingStatus(state), status)
  return (
    <PeakShavingBadgeContainer>
      <Badge
        className={state}
        count={translatedStatus}
        style={{ backgroundColor, color: fontColor, boxShadow: `${backgroundColor} 0px 0px 0px 0.5px` }}
      />
    </PeakShavingBadgeContainer>
  )
}

// Returns the a tupple, where the first argument in the Humanize state and the second is the css class
export const getColorStatus = (status: AllStatus): string[] => {
  switch (status.toLocaleLowerCase()) {
    case 'draft':
      return [status, theme.colors.orangePeel15, theme.colors.warningPrimary]
    case 'cancelled':
    case 'overridden':
      return [status, theme.colors.underRepair, theme.colors.dangerPrimary]
    case 'published':
    case 'finished':
    case 'created':
      return [status, theme.colors.active, theme.colors.neonGreen]
    case 'archived':
      return [status, theme.colors.black, theme.colors.darkGray]
    default:
      return ['Unknown', theme.colors.gray]
  }
}

export default PeakShavingStatusBadge
