import { Report, ReportType } from '../models/reports'
import { mockTenant } from './tenant-mock'
import { TenantRef } from '../models/tenant'
import { ReportsResponse } from '../models/http'

export const mockReports = [
  {
    id: 1,
    context: {
      id: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].id,
      name: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].displayName || '',
      type: 'tenant',
    },
    type: 'transactions',
    timeframe: 'quarterly',
    startDate: '2021-01-01T00:00:00.000',
    endDate: '2021-03-01T23:59:59.000',
    generator: 'system',
    generatedAt: '2021-04-02T00:00:00.000-04:00',
    fileUrl: 'http://swtch.com/file',
  },
  {
    id: 2,
    context: {
      id: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].id,
      name: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].displayName || '',
      type: 'tenant',
    },
    type: 'transactions',
    timeframe: 'quarterly',
    startDate: '2021-04-01T00:00:00.000-04:00',
    endDate: '2021-06-30T23:59:59.000-04:00',
    generator: 'system',
    generatedAt: '2021-07-02T00:00:00.000-04:00',
    fileUrl: 'http://swtch.com/file',
  },
  {
    id: 3,
    context: {
      id: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].id,
      name: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].displayName || '',
      type: 'tenant',
    },
    type: 'erroredTransactions',
    timeframe: 'custom',
    startDate: '2022-01-10T00:00:00.000-05:00',
    endDate: '2022-06-10T23:59:59.000-04:00',
    generator: 'system',
    generatedAt: '2022-06-10T15:26:57.000-04:00',
    fileUrl: 'http://swtch.com/file',
  },
  {
    id: 4,
    context: {
      id: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].id,
      name: mockTenant['10-DeanPark-Rd-Scarborough-ON-M1B3G8'].displayName || '',
      type: 'tenant',
    },
    type: 'erroredTransactions',
    timeframe: 'custom',
    startDate: '2022-01-10T00:00:00.000-05:00',
    endDate: '2022-06-10T23:59:59.000-04:00',
    generator: 'system',
    generatedAt: '2022-06-10T15:26:55.000-04:00',
    fileUrl: 'http://swtch.com/file',
  },
] as Report[]

export const mockReportsResp = (reportType: ReportType, tenant?: TenantRef, page?: number) => {
  return {
    data: mockReports.filter((r) => r.type === reportType),
    pagination: {
      currentPage: page || 0,
      perPage: 20,
      totalEntries: mockReports.length,
    },
  } as ReportsResponse
}
