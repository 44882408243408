import { theme } from '../../theme'

export const checkboxSVG = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="5" fill="black" />
    <path
      d="M10.4999 15.3242L16.9995 8.90039L18 9.88846L10.4999 17.3004L6 12.8534L6.99982 11.8653L10.4999 15.3242Z"
      fill={theme.colors.primary}
      stroke={theme.colors.primary}
      stroke-width="0.5"
    />
  </svg>
)

export const closeSVG = (props: any) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13 5.5L5 13.5M13 13.5L5 5.5" stroke="black" stroke-width="2" stroke-linecap="round" />
    </svg>
  )
}
