import { theme } from '../../theme'

export const DotsSVG = (props: any) => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="37" height="37" rx="18.5" fill="white" />
    <circle cx="12" cy="19" r="2" fill={theme.colors.softBlack} />
    <circle cx="19" cy="19" r="2" fill={theme.colors.softBlack} />
    <circle cx="26" cy="19" r="2" fill={theme.colors.softBlack} />
    <rect x="0.5" y="0.5" width="37" height="37" rx="18.5" stroke={theme.colors.lightGray} />
  </svg>
)
