import { useEffect, useState } from 'react'
import { Button, Form, Skeleton } from 'antd'
import { Box } from '../../atom/box'
import { layout, oneButtonLayout } from '../../atom/form/page-layout'
import { AlertError } from '../../components/error'
import { withAuthenticatedLayout } from '../../components/layouts/layout'
import { ListingAccessVisibility } from '../../components/listings/listing-access-visibility'
import { ListingAuthorV2 } from '../../components/listings/listing-author-v2'
import { ListingBasic } from '../../components/listings/listing-basic'
import { ListingChargerV2 } from '../../components/listings/listing-charger-v2'
import { ListingCommissionFee } from '../../components/listings/listing-commission-fee'
import { renderFormatMessage } from '../../helpers/intl'
import { useListing } from '../../hooks/useListing'
import { useAppState } from '../../state'
import { ListingPeakShaving } from '../../components/listings/listing-peak-shaving'
import ListingStatusModal from './modal/ListingStatusModal'
import { FormContainer } from 'atom/form/form-layout'
import { ListingServiceStatus } from 'models/connector'
import { CreateListingFormWrapper } from 'atom/listings'
import { ListingLoadManagement } from 'components/listings/listing-load-management'
import { ObjectTagging } from 'components/tagging/object-taggings'

const ListingBasePage: React.FC = () => {
  document.querySelector('body')?.classList.add('redesignActive')
  const {
    loading,
    error,
    listing,
    initialValues,
    form,
    onFinish,
    taggings,
    setTaggings,
    listingId,
    dropdownRender,
  } = useListing()
  const [currentServiceStatus, setCurrentServiceStatus] = useState<ListingServiceStatus>('awaiting_commissioning')
  const [currentReason, setCurrentReason] = useState<string[]>()
  const [editListingStatusModal, setEditListingStatusModal] = useState(false)
  const { currentUser } = useAppState()

  const peakShavingPageAccessEmails = [
    'suleman.rai@swtchenergy.com',
    'jing.guo@swtchenergy.com',
    'alton.yeung@swtchenergy.com',
    'nicole.politis@swtchenergy.com',
    'michael.vu@swtchenergy.com',
    'sangit@peakpowerenergy.com',
    'aryan.sharma@peakpowerenergy.com',
    'sogand.okhovatian@peakpowerenergy.com',
    'ben.delevi@peakpowerenergy.com',
  ]

  useEffect(() => {
    setCurrentServiceStatus(
      listing?.connector?.serviceStatus ? listing?.connector?.serviceStatus : 'awaiting_commissioning',
    )
  }, [listing])

  return (
    <>
      <AlertError error={error} />

      <Box padding>
        <Skeleton loading={loading}>
          <FormContainer>
            <Form
              {...layout}
              form={form}
              initialValues={initialValues}
              onFinish={onFinish}
              scrollToFirstError={true}
              size="large"
            >
              <CreateListingFormWrapper>
                <ListingBasic
                  form={form}
                  serviceStatus={currentServiceStatus}
                  onEditListingStatusModal={() => setEditListingStatusModal(!editListingStatusModal)}
                />
                <ListingAccessVisibility form={form} />
                <ListingAuthorV2
                  form={form}
                  listingAuthor={listing?.person}
                  tenant={listing?.tenant}
                  stripeAccount={listing?.stripeAccount}
                />
                <ListingChargerV2 form={form} />
                <ListingCommissionFee form={form} />
                <ListingLoadManagement form={form} />
                {currentUser && peakShavingPageAccessEmails.includes(currentUser.email) && (
                  <ListingPeakShaving form={form} />
                )}
                <ObjectTagging
                  taggings={taggings}
                  setTaggings={setTaggings}
                  objectIds={[{ id: parseFloat(listingId) }]}
                  tableName="Listing"
                  dropdownRender={dropdownRender}
                />
                <Form.Item {...oneButtonLayout}>
                  <Button type="primary" block htmlType="submit">
                    {renderFormatMessage('dashboard.createNewListing.submitBtn', 'Submit')}
                  </Button>
                </Form.Item>
              </CreateListingFormWrapper>
            </Form>
            {typeof listing === 'object' &&
              listing !== null &&
              Object.keys(listing)?.length > 0 &&
              editListingStatusModal && (
                <ListingStatusModal
                  reason={currentReason}
                  onServiceStatus={(newServiceStatus, reason) => {
                    if (listing && listing.connector) {
                      listing.connector.serviceStatus = newServiceStatus
                    }
                    initialValues.serviceStatus = newServiceStatus
                    setCurrentServiceStatus(newServiceStatus)
                    setCurrentReason(reason)
                    form.setFieldsValue({
                      serviceStatus: newServiceStatus,
                      reason: reason,
                    })
                    setEditListingStatusModal(false)
                  }}
                  serviceStatus={currentServiceStatus}
                  onCancel={() => setEditListingStatusModal(false)}
                />
              )}
          </FormContainer>
        </Skeleton>
      </Box>
    </>
  )
}

export const ListingPageV2 = withAuthenticatedLayout(ListingBasePage)
