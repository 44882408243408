import { Badge } from 'antd'

import { ConnectivityState } from '../../../models/listing'
import { renderFormatMessage } from 'helpers/intl'

export const handleConnectivity = (connectivity: ConnectivityState) => {
  switch (connectivity) {
    case false:
      return <Badge status="error" />
    case true:
      return <Badge status="success" />
    case 'connecting':
      return <Badge status="warning" />
    default:
      return <Badge status="default" /> // CONNECTIVITY.NO_CONNECTION
  }
}

export const getConnectivityStatus = (connectivity: ConnectivityState) => {
  switch (connectivity) {
    case false:
      return renderFormatMessage('dashboard.text.noConnection', 'No Connection')
    case true:
      return renderFormatMessage('dashboard.text.online', 'Online')
    case 'connecting':
      return renderFormatMessage('dashboard.text.warning', 'Warning')
    default:
      return renderFormatMessage('dashboard.text.offline', 'Offline')
  }
}
