import { theme } from '../../theme'

export const successCheckbox = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="5" fill="black" />
    <path
      d="M10.4999 15.3242L16.9995 8.90039L18 9.88846L10.4999 17.3004L6 12.8534L6.99982 11.8653L10.4999 15.3242Z"
      fill={theme.colors.primary}
      stroke={theme.colors.primary}
      stroke-width="0.5"
    />
  </svg>
)

export const warningCheckbox = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="5" fill="black" />
    <path
      d="M10.4999 15.3242L16.9995 8.90039L18 9.88846L10.4999 17.3004L6 12.8534L6.99982 11.8653L10.4999 15.3242Z"
      fill={theme.colors.warningPrimary}
      stroke={theme.colors.warningPrimary}
      stroke-width="0.5"
    />
  </svg>
)

export const errorCheckbox = (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="24" height="24" rx="5" fill="black" />
    <path
      d="M10.4999 15.3242L16.9995 8.90039L18 9.88846L10.4999 17.3004L6 12.8534L6.99982 11.8653L10.4999 15.3242Z"
      fill={theme.colors.dangerPrimary}
      stroke={theme.colors.dangerPrimary}
      stroke-width="0.5"
    />
  </svg>
)
